(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('haccpIcon', {
            controller: 'HaccpIconController',
            templateUrl: '/components/haccp-icon/haccp-icon.html',
            bindings: {
                'min': '<',
                'max': '<',
                'quantity': '<',
                'onChange': '&'
            }
        });
})();