(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('bugReport', {
            controller: 'TabletBugReportController',
            templateUrl:  '/components/bug-report/bug-report.html',
            bindings: {
                resolve: '<',
                close: '&'
            }
        });
}());