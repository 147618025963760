(function() {
    "use strict";

    var app = angular.module("laekkerAI.tablet");

    /**
     * @ngdoc service
     * @name TabletAuthService
     * @description #todo
    **/
    app.factory("TabletAuthService", [
        "TabletApiService",
        "TabletLocalStorageService",
        "TabletHubService",
        "TabletConfigService",
        "TabletBaseService",
        "KitchenRecipeService",
        function(
            apiService,
            localStorageService,
            hubService,
            configService,
            baseService,
            recipeService
        ) {
            var service = this;
            service.isAuthorized = function(callback) {
                if (!callback) {
                    return;
                }

                var authToken = configService.getAuthToken();

                if (!authToken) {
                    return callback(false);
                }

                if ($("#unauthorized")) {
                    $("#unauthorized").hide();
                }

                apiService.get(
                    "productionstations/validate/" + authToken,
                    function(data) {
                        if (data.Result === true) {
                            configService.storeAuthData(
                                data.StationInformations.Location,
                                data.StationInformations.ActivityUnit,
                                authToken
                            );
                            hubService.initHub(authToken);
                        } else {
                            configService.resetStation();
                        }
                        callback(data.Result);
                    },
                    function() {
                        callback(true);
                    }
                );
            };

            /**
             * @ngdoc function
             * @name _hasPermission
             * @methodOf TabletAuthService
             * @description todo
            **/
            service.hasPermission = function(permission, callback) {
                if (!callback) {
                    return;
                }

                var token = configService.getAuthToken();
                var result = {
                    permission: permission,
                    granted: false
                };

                if (!token) {
                    callback(result);
                }

                return apiService.get(
                    "productionstations/" +
                        token +
                        "/haspermission/" +
                        permission,
                    function(apiResult) {
                        result.granted = apiResult.Success;
                        return callback(result);
                    },
                    null,
                    apiService.options.DAY
                );
            };

            /**
             * @ngdoc function
             * @name _logout
             * @methodOf TabletAuthService
             * @description todo
            **/
            service.logout = function() {
                configService.resetStation();
            };

            service.lockStation = function() {};

            /**
             * @ngdoc function
             * @name _getLocations
             * @methodOf TabletAuthService
             * @description todo
            **/
            service.getLocations = function(callback) {
                return apiService.get(
                    "locations",
                    function(locations) {
                        callback(
                            null,
                            locations.filter(function(location) {
                                return location.ID !== 10;
                            })
                        );
                    },
                    function(err) {
                        callback(err);
                    }
                );
            };

            service.switchLocation = function(location, callback) {
                return apiService.put('productionstations/switch-location', {
                    ActivityUnitID: configService.getUser().ID,
                    NewLocationID: location.ID
                }, function () {
                    configService.setLocation(location);
                    callback(null);
                }, function (err) {
                    callback(err);
                });
            };

            /**
             * @ngdoc function
             * @name _getUsers
             * @methodOf TabletAuthService
             * @description todo
            **/
            service.getUsers = function(locationID, callback) {
                return apiService.post(
                    "activityunits/" + locationID,
                    {},
                    callback
                );
            };

            /**
             * @ngdoc function
             * @name _authenticate
             * @methodOf TabletAuthService
             * @description todo
            **/
            service.authenticate = function(
                location,
                user,
                password,
                callback
            ) {
                return apiService.post(
                    "productionstations/register",
                    {
                        locationID: location.ID,
                        activityUnitID: user.ID,
                        pin: password
                    },
                    function(data) {
                        if (data.APIKey && data.APIKey.length > 0) {
                            configService.storeAuthData(
                                location,
                                user,
                                data.APIKey
                            );
                            hubService.initHub(data.APIKey);
                        }
                        recipeService.preloadRecipes();
                        callback();
                    },
                    function(data) {
                        callback(data);
                    }
                );
            };

            return service;
        }
    ]);
})();
