(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("GoodsReceivingListController", [
            "GoodsReceivingService",
            GoodsReceivingListController
        ]);

    function GoodsReceivingListController(goodsService) {
        var $ctrl = this;

        $ctrl.$onInit = function() {
            goodsService.getSupplierOrders(function(err, supplierOrders) {
                $ctrl.supplierOrders = supplierOrders;
            });
        };

        $ctrl.$onChanges = function(changes) {};

        $ctrl.$onDestroy = function() {};
    }
})();
