(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('TakeJobQuestionController', [
            'KitchenJobService',
            function (jobService) {

            var $ctrl = this;

            $ctrl.$onInit = function () {
                if($ctrl.resolve !== undefined) {
                    angular.extend($ctrl, $ctrl.resolve);
                }
            };

            $ctrl.takeIt = function () {
                jobService.takeJob($ctrl.job, function () {
                    $ctrl.close();
                });
            };

        }]);
})();