(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("productionPlanning", {
        templateUrl: "/components/production-planning/production-planning.html",
        controller: "ProductionPlanningController",
        bindings: {
            
        }
    });
})();
