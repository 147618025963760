(function() {
    "use strict";
    angular
        .module("laekkerAI.tablet")
        .factory("GoodsReceivingService", [
            "TabletBaseService",
            "TabletApiService",
            "TabletConfigService",
            GoodsReceivingService
        ]);

    var plan = null;

    function GoodsReceivingService(baseService, apiService, configService) {
        var service = {};

        service.getSupplierOrders = function(callback) {
            var locationId = configService.getLocation().ID;
            apiService.get(
                "location/" + locationId + "/supplier-orders",
                function(orders) {
                    callback(null, orders);
                },
                function(response) {
                    callback(response.message);
                }
            );
        };

        service.getSupplierOrder = function(orderId, callback) {
            var locationId = configService.getLocation().ID;
            apiService.get(
                "location/" + locationId + "/supplier-orders/" + orderId,
                function(order) {
                    callback(null, order);
                },
                function(response) {
                    callback(response.message);
                }
            );
        };

        service.completeGoodsReceiving = function(
            supplierOrder,
            receivedGoods,
            callback
        ) {
            callback();
        };

        return service;
    }
})();
