(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('production', {
            controller: 'KitchenProductionController',
            templateUrl: '/components/production/production.html',
            bindings: {
                settings: '<',
                categoryId: '<'
            }
        });
})();