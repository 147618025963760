(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("toolbar", {
        templateUrl: "components/toolbar/toolbar.html",
        controller: "ToolbarController",
        bindings: {},
        transclude: true
    });
})();
