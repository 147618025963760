(function () {
    "use strict";
    angular
        .module('laekkerAI.tablet')
        .factory('CommodityService', [
            'TabletApiService',
            'TabletConfigService',
            function (apiService, configService) {
                var service = this;

                service.getCommodities = function (searchTerm, callback) {
                    apiService.get('commodities?itemsPerPage=10&searchTerm=' + (searchTerm || ''), function (data) {
                        return callback(null, data.Items);
                    }, function (response) {
                        return callback(response);
                    });
                };

                service.getCommodity = function (commodityId, callback) {
                    apiService.get('commodities/' + commodityId + '/withdetails/' + configService.getLocation().ID, function (data) {
                        callback(null, data);
                    }, function (response) {
                        callback(response);
                    });
                };

                return service;
            }
        ]);
}());