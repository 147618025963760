(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("KitchenRecipeController", [
        "KitchenRecipeService",
        "TabletEventService",
        "TabletConfigService",
        "$timeout",
        function(recipeService, eventService, configService, $timeout) {
            var $ctrl = this;

            $ctrl.recipeLoadFailed = false;
            $ctrl.currentPortions = 0;
            $ctrl.initialQuantity = 0;
            $ctrl.showRecipe = true;

            $ctrl.$onInit = function() {
                $ctrl.settings = configService.getSettings();
                $ctrl.currentPortions = $ctrl.quantity;  
                $ctrl.initialQuantity = $ctrl.quantity;  
                $ctrl.loadRecipe();
            };

            $ctrl.loadRecipe = function() {
                if ($ctrl.showRecipe === true) {
                    _loadRecipe();
                    $timeout(_ShowRecipe, 5000);
                }
            };

            function _loadRecipe() {
                $ctrl.recipeLoadFailed = false;
                console.info("loading recipe with id " + $ctrl.recipeKey.ID);
                recipeService.getRecipeByKey(
                    $ctrl.recipeKey,
                    function(err, recipe, groups, stats) {
                        
                        if (err) {
                            $ctrl.recipeLoadFailed = true;
                            return; 
                        }

                        $ctrl.recipeLoadFailed = false;
                        $ctrl.recipe = recipe;

                        $ctrl.productionDuration = stats;

                        if ($ctrl.recipe === undefined || $ctrl.recipe === null) {
                            $ctrl.recipeLoadFailed = true;
                            return; 
                        }
                        
                        if($ctrl.recipe.ProductionDuration > 0) {
                            $ctrl.productionDuration = $ctrl.recipe.ProductionDuration;
                        }
                        if($ctrl.recipe.FreshnessMinutes > 0) {
                            $ctrl.FreshnessMinutes = $ctrl.recipe.FreshnessMinutes;
                        }

                        $ctrl.isNew = moment(
                            $ctrl.recipe.ModifiedUtc
                        ).isAfter(moment().add(-30, "days"));

                        $ctrl.hasVideo =
                            $ctrl.recipe.VideoFileName &&
                            $ctrl.recipe.VideoFileName.length > 0;
                        if ($ctrl.hasVideo) {
                            $ctrl.videoUrl =
                                __appConfig.api +
                                "products/video/" +
                                $ctrl.recipe.ID;
                            
                                eventService.broadcast(
                                    eventService.events.recipeVideoLoaded,
                                    { 
                                        videoUrl: $ctrl.videoUrl,
                                        description: $ctrl.recipe.Description
                                    }
                                );
                        }                        
                        _ShowRecipe();
                    }
                );
            }
            
            function _ShowRecipe() {       
                if ($ctrl.recipe === undefined || $ctrl.recipe === null) {
                    $ctrl.recipeLoadFailed = true;
                }          
                updateStockInfo();
                refreshInfo();
            }

            $ctrl.$onChanges = function(changes) {
                if (changes.quantity) {
                    $ctrl.currentPortions = changes.quantity.currentValue;
                    updateStockInfo();
                }
            };

            $ctrl.portionsChanged = function() {
                if (isNaN($ctrl.currentPortions)) {
                    $ctrl.currentPortions = $ctrl.quantity;
                }
                $ctrl.changeQuantity({
                    quantity: Math.max($ctrl.currentPortions, 0)
                });
            };

            $ctrl.decreaseQuantity = function() {
                $ctrl.changeQuantity({
                    quantity: Math.max($ctrl.quantity - 1, 0)
                });
            };

            $ctrl.increaseQuantity = function() {
                $ctrl.changeQuantity({ quantity: $ctrl.quantity + 1 });
            };

            $ctrl.focusAll = function () {
                jQuery('#recipePortions' + $ctrl.recipe.ID).select();
            };

            function updateStockInfo() {
                if (!$ctrl.recipe || $ctrl.recipe.Supplier) {
                    return;
                }

                recipeService.checkRecipeStocks(
                    $ctrl.recipe.ID,
                    $ctrl.currentPortions,
                    function(err, data) {
                        if (!data) {
                            return;
                        }

                        var dataIDs = data.map(function(d) {
                            return d.ID;
                        });

                        $ctrl.recipe.Ingredients.forEach(function(element) {
                            element.isOutOfStock =
                                dataIDs.indexOf(element.ID) !== -1;
                        });
                    }
                );
            }

            function refreshInfo() {
                if ($ctrl.recipe)
                {
                    var freshnessDays = $ctrl.recipe.FreshnessMinutes/1440;
                    var freshnessHours = $ctrl.recipe.FreshnessMinutes/60;

                    $ctrl.Freshness = freshnessHours + ' Stunde(n)';
                    if (freshnessDays >= 1) {
                        freshnessHours = ($ctrl.recipe.FreshnessMinutes - (freshnessDays*1440))/60;                
                        $ctrl.Freshness = freshnessDays + ' Tag'; 
                        if (freshnessDays >= 2) { 
                            $ctrl.Freshness = freshnessDays + ' Tage';
                        }
                        if (freshnessHours > 0) {                            
                            $ctrl.Freshness += ' und ' + freshnessHours + ' Stunde(n)';
                        }
                    }
                }
            }

            return $ctrl;
        }
    ]);
})();
