(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('productStockGroup', {
            controller: 'ProductStockGroupController',
            templateUrl: '/components/product-stock-group/product-stock-group.html',
            bindings: {
                jobGroup: '<',
                toggleSelection: '&',
                selectedJob: '<',
                listSettings: '<',
                productionType: "<"
            }
        });
})();