(function() {
    "use strict";

    /**
     * @ngdoc service
     * @name KitchenInformationService
     * @description #todo
    **/
    var app = angular.module("laekkerAI.tablet");

    app.factory("KitchenInformationService", [
        "TabletApiService",
        "TabletLocalStorageService",
        "TabletBaseService",
        "TabletConfigService",
        function(apiService, localStorageService, baseService, configService) {
            var informations = localStorageService.get("informations");
            if (informations === null) {
                informations = [];
            }
            var statistics = localStorageService.get("statistics");
            if (statistics === null) {
                statistics = [];
            }

            /* public API */
            return {
                getInformationByKey: _getInformationByKey,
                getProductUsage: _getProductUsage,
                removeInformation: _removeInformation
            };

            function _removeInformation(key, locationID) {
                apiService.resetPostCache("informations", {
                    LocationID: locationID,
                    Key: key
                });
            }

            /**
         * @ngdoc function
         * @name _getProductUsage
         * @methodOf KitchenInformationService
         * @description todo
        **/
            function _getProductUsage(key, locationID, date, callback) {
                return apiService.get(
                    "productionstations/jobusages/" +
                        key.Type +
                        "/" +
                        key.ID +
                        "/" +
                        date,
                    callback
                );
            }

            /**
         * @ngdoc function
         * @name _getInformationByKey
         * @methodOf KitchenInformationService
         * @description todo
        **/
            function _getInformationByKey(key, callback) {
                apiService.post(
                    "informations",
                    {
                        LocationID: configService.getLocation().ID,
                        Key: key
                    },
                    function(data) {
                        fixIngredients(data.Information);
                        if(data.Information.ProductionDuration == 0 && data.Statistic > 0) {
                            data.Information.ProductionDuration = data.Statistic;
                        }
                        callback(
                            null,
                            data.Information,  
                            data.Groups,
                            data.Statistic
                        );
                    },
                    function(response) {
                        callback(response);
                    },
                    apiService.options.TWODAYS
                );
            }

            function fixIngredients(recipe) {
                if (!recipe || !recipe.Ingredients) {
                    return;
                }

                for (var i = 0; i < recipe.Ingredients.length; i++) {
                    fixIngredient(
                        recipe.Ingredients[i],
                        recipe.PortionAmount || 1
                    );
                }
            }

            function fixIngredient(ingredient, portionModifier) {
                // if (
                //     !ingredient.FreeText ||
                //     ingredient.FreeText.trim().length === 0
                // ) {
                //     ingredient.Amount /= portionModifier;
                //     return;
                // }
                if (ingredient.FreeText && ingredient.FreeText.trim().length === 0) {
                    ingredient.FreeText = null;
                } else {
                    ingredient.Amount /= portionModifier;
                }
            }

          
        }
    ]);
})();
