(function() {
    "use strict";

    var app = angular.module("laekkerAI.tablet");

    /**
     * @ngdoc service
     * @name TabletConfigService
     * @description #todo
     * @requires LocalStorageService
     * @requires TabletBaseService
     * @requires TabletLogService
     * @requires $window
     * @requires $rootScope
    **/
    app.factory("TabletConfigService", [
        "TabletLocalStorageService",
        "TabletBaseService",
        "TabletLogService",
        "$window",
        "PRODUCTION_TYPE",
        "$rootScope",
        "TabletEventService",
        '$state',
        function(
            LocalStorageService,
            BaseService,
            logger,
            $window,
            PRODUCTION_TYPE,
            $rootScope,
            eventService,
            $state
        ) {
            var DAY = 86400;
            var MINUTE = 60;
            var HOUR = 3600;
            var HOST = 0;

            var connectionStatus = "disconnected";

            var settings;
            _getSettings();

            function _tryResolveApiKey() {
                var lastPathPart = document.location.href.substring(
                    document.location.href.lastIndexOf("/") + 1
                );
                var modeDetectorPattern = /[a-f0-9]{8}\-[a-f0-9]{4}\-[a-f0-9]{4}\-[a-f0-9]{4}\-[a-f0-9]{12}/i;
                // activate or disable tablet mode -> in tablet mode the logout button should not be visible
                settings.tabletMode = modeDetectorPattern.test(lastPathPart);
                if (settings.tabletMode) {
                    settings.authToken = modeDetectorPattern.exec(
                        lastPathPart
                    )[0];
                    _storeSettings();
                }
            }

            _tryResolveApiKey();

            /* public API */
            return {
                setLastSelectedJob: function(key) { settings.lastSelectedJob = key; _storeSettings(); },
                getLastSelectedJob: function() { return settings.lastSelectedJob; },
                resetStation: _resetStation,
                getSettings: _getSettings,
                getLocation: _getLocation,
                setLocation: _setLocation,
                setProductionDate: setProductionDate,
                getUser: _getUser,
                setUser: _setUser,
                getProductionType: _getProductionType,
                setProductionType: _setProductionType,
                getWatchedUpdateInterval: function() {
                    return MINUTE * 5.0;
                },
                getWorkerName: _getWorkerName,
                getGroupInfoAge: function() {
                    return settings.groupInfoAge;
                },
                setGroupInfoAge: function(age) {
                    settings.groupInfoAge = age;
                    _storeSettings();
                },
                setListSettings: _setListSettings,
                getListSettings: _getListSettings,
                setSelectedCategories: function(selectedCategories) {
                    settings.selectedCategories = selectedCategories || [];
                    _storeSettings();
                },
                getSelectedCategories: function() {
                    return settings.selectedCategories;
                },
                // old stuff

                storeAuthData: function(location, user, authToken) {
                    // dont lose existing location choice!
                    if (!settings.location) {
                        settings.location = location; 
                    }
                    settings.user = user;
                    settings.authToken = authToken;
                    _storeSettings();
                },

                getAuthToken: function() {
                    return settings.authToken;
                },
                logout: function() {
                    settings.authToken = null;
                    settings.user = null;
                    settings.location = null;
                    _storeSettings();
                }
            };

            /**
         * @ngdoc function
         * @name _setListSettings
         * @methodOf TabletConfigService
         * @description todo
        **/
            function _setListSettings(listSettings) {
                settings.listSettings = listSettings;
                settings.listSettings.activeFilters = 0;                
                if(listSettings.onlyMyJobs) {
                    settings.listSettings.activeFilters++;
                }
                if(listSettings.showAll) {
                    settings.listSettings.activeFilters++;
                }
                if(listSettings.showProduced) {
                    settings.listSettings.activeFilters++;
                }
                _storeSettings();
            }

            /**
         * @ngdoc function
         * @name _getListSettings
         * @methodOf TabletConfigService
         * @description todo
        **/
            function _getListSettings() {
                if (settings.listSettings === undefined) {
                    settings.listSettings = {
                        sorting: ["RemainingTime", "Name"], // or 'alpha'
                        onlyMyJobs: false, // or 'mine'
                        enableGrouping: true // or true
                    };
                    _storeSettings();
                }

                return settings.listSettings;
            }

            /**
         * @ngdoc function
         * @name _resetStation
         * @methodOf TabletConfigService
         * @description todo
        **/
            function _resetStation() {
                LocalStorageService.clear();
            }

            /**
         * @ngdoc function
         * @name _getProductionType
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _getProductionType() {
            return settings.production.productionType;
        }

        /**
         * @ngdoc function
         * @name _setProductionType
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _setProductionType(mode) {
            settings.production.productionType = mode;
            settings.production.currentDate = BaseService.getToday(0);
            _storeSettings();
        }

        function setProductionDate(date) {
            var selected = date.getTime();
            var today = BaseService.getToday().getTime();

            if (selected < today) {
                date = BaseService.getToday();
            }
            settings.production.currentDate = date;
            _storeSettings();
        }

        /**
         * @ngdoc function
         * @name _getUser
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _getUser() {
            return settings.user;
        }

        /**
         * @ngdoc function
         * @name _getLocation
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _getLocation() {
            return settings.location;
        }

        /**
         * @ngdoc function
         * @name _setLocation
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _setLocation(newLocation) {
            settings.location = newLocation;
            _storeSettings();
            eventService.broadcast(eventService.events.locationChanged);
            $state.reload();
        }

        /**
         * @ngdoc function
         * @name _setUser
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _setUser(newUser) {
            settings.user = newUser;
            _storeSettings();
        }

        /**
         * @ngdoc function
         * @name _getSettings
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _getSettings() {
            if (settings === undefined) {
                settings = LocalStorageService.get("settings");
            }

            _trySetDefaultSettings();
            return settings;
        }

        /**
         * @ngdoc function
         * @name _storeSettings
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _storeSettings() {
            LocalStorageService.set("settings", settings);
        }

        /**
         * @ngdoc function
         * @name _getWorkerName
         * @methodOf TabletConfigService
         * @description todo
        **/
        function _getWorkerName() {
            if (settings.user === undefined || settings.user === null) {
                return "unbekannt";
            }

            return settings.user.Name;
        }

        /**
         * @ngdoc function
         * @name _trySetDefaultSettings
         * @methodOf TabletConfigService
         * @description todo
        **/
            function _trySetDefaultSettings() {
                if (settings) {
                    return;
                }

                settings = {
                    production: {
                        productionType: PRODUCTION_TYPE.DAILY,
                        currentDate: BaseService.getToday()
                    },
                    apiKey: null,

                    selectedCategories: [],

                    listSettings: {
                        sorting: ["RemainingTime", "Name"],
                        onlyMyJobs: false,
                        enableGrouping: true
                    }
                };

                _storeSettings();
            }
        }
    ]);
})();
