(function() {
    "use strict";
    angular.module("laekkerAI.tablet").factory("Utility", [
        function() {
            var service = {};

            service.count = function(list, filter) {
                var result = 0;

                if (!list || !filter) {
                    return result;
                }

                for (var i = 0; i < list.length; i++) {
                    if (filter(list[i])) {
                        result++;
                    }
                }

                return result;
            };

            service.contains = function(list, filter) {
                if (!list || !filter) {
                    return false;
                }

                if (typeof filter === "function") {
                    return list.filter(filter).length > 0;
                }

                return list.indexOf(filter) !== -1;
            };

            return service;
        }
    ]);
})();
