(function() {
    "use strict";

    /**
     * @ngdoc function
     * @name Tabletcontroller:authController
     * @description
    **/
    var app = angular.module("laekkerAI.tablet");
    app.controller("KitchenAppController", ['KitchenJobService', function(jobService) {
        jobService.updateJobs(null, function () { });
    }]);
})();
