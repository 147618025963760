(function () {
    "use strict";
    angular
        .module("laekkerAI.tablet")
        .factory("PlanningService", [
            "TabletBaseService",
            "TabletApiService",
            "TabletConfigService",
            PlanningService
        ]);

    var plan = null;

    function PlanningService(baseService, apiService, configService) {
        var service = {};


        service.formatDateForGetPlanJobs = function (date) {
            return window.encodeURIComponent(moment(date).format("YYYY-MM-DDTHH:mm:ssZZ"));
        };
        
        service.formatDateOnly = function (date) {
            return moment(date).format('YYYY-MM-DD');
        };

        service.getProductionPlan = function (minDate, maxDate, callback) {
            if (plan) {
                callback(null, plan);
            }

            plan = {
                suppliers: [],
                jobGroups: []
            };

            function createPlanJobs(jobs) {
                for (var i = 0; i < jobs.length; i++) {
                    var job = jobs[i];
                    var groupId = job.productionGroupId;
                    var group = getOrCreateGroup(plan.jobGroups, job);
                    job.timeline = convertToTimeline(job.productionDays, job.plannableFor);
                    group.jobs.push(job);
                    if (job.ProductionDuration === 0) {
                        job.ProductionDuration = 120;
                    }
                }
                callback(null, plan);
            }

            function convertToTimeline(productionDays, plannableFor) {
                return productionDays
                .filter(function (item) {
                    return moment(item.date).isBetween(moment(plannableFor).add(1, 'days'), moment(plannableFor).add(7, 'days'), 'days', '[]');
                })
                .map(function (itm) {
                    return {
                        dateString: itm.date,
                        date: itm.date,
                        value: itm.demand
                    };
                });
            }

            function addSuppliers() { }

            var options = apiService.getOptions();

            apiService.get(
                //"location/" + configService.getLocation().ID + "/recipes/production-informations",
                {
                    "base": __appConfig.microServiceApi,
                    "url": "productionplans?locationId=" + configService.getLocation().ID + "&from=" + service.formatDateOnly(minDate) + "&to=" + service.formatDateOnly(maxDate) + ""
                },
                createPlanJobs,
                function (response) {
                    callback(response || "Der Planungsdienst ist zur Zeit nicht erreichbar.");
                },
                undefined,
                options
            );
        };

        function getOrCreateGroup(groups, job) {
            var group;
            for (var i = 0; i < groups.length; i++) {
                group = groups[i];
                if (group.groupId === job.productionGroupId) {
                    return group;
                }
            }

            group = {
                groupId: job.productionGroupId,
                jobs: []
            };

            groups.push(group);

            return group;
        }

        function formatProductionPlanForUpdate(plannedProductions){
            return {
                "locationId": configService.getLocation().ID,
                "date": new Date().toISOString(),
                "plannedProductions": plannedProductions
            };
        }
        function sendUpdateProductionPlanToApi(data, callback){

            var options = apiService.getOptions();
            apiService.put(
                { "base": __appConfig.microServiceApi, "url": "ProductionPlans/update" },
                data,
                function (response) {
                    callback(response.message);
                    console.log("saved", response.message);
                },
                undefined,
                options
            );
        }

        service.saveCompleteProductionPlan = function (jobs, callback) {

            var options = apiService.getOptions();

            var plannedProductions = [];
            jobs.jobGroups.forEach(function (jobGroup) {
                jobGroup.jobs.forEach(function (job) {
                    if(job.isPlanned){
                        plannedProductions.push({
                            "plannedAmount": job.plannedQuantity,
                            "productId": job.productId,
                            "productionType": job.ProductionJobType
                        });
                    }
                    //job.isPlanned = true;
                    
                });
            });

            var data = formatProductionPlanForUpdate(plannedProductions);
            sendUpdateProductionPlanToApi(data, callback);
            //console.log("->",data);
        };

        service.updateProductionPlanFor = function (job, quantity, callback) {

            var locationId = configService.getLocation().ID;
        
            apiService.get(
                "location/" +
                locationId +
                "/recipes/" +
                job.productId +
                "/supplier-infos",
                function (supplierInfos) {
                    updateSuppliers(supplierInfos, quantity);

                    job.status = "planned";
                    callback();
                },
                function (resp) {
                    console.log(resp);
                    callback(resp.message);
                }
            );
        };

        service.getEstimatedProductionLocation = function (minDate, maxDate, callback) {
            var locationId = configService.getLocation().ID;

            apiService.get(
                {
                    "base": __appConfig.microServiceApi,
                    "url": "PlannedProducts/estimated-production-duration?locationId=" + configService.getLocation().ID + "&from=" + service.formatDateForGetPlanJobs(minDate) + "&to=" + service.formatDateForGetPlanJobs(maxDate) + ""
                },
                function (estimatedTimes) {
                    callback(estimatedTimes);
                },
                function (resp) {
                    console.log(resp);
                    callback(null, resp.message);
                }
            );
        };

        function updateSuppliers(supplierInfos, quantity) {
            for (var i = 0; i < supplierInfos.length; i++) {
                var supplierInfo = supplierInfos[i];
                var supplier = getOrCreateSupplier(
                    plan.suppliers,
                    supplierInfo.LocationSupplier
                );

                var commodity = getOrCreateCommodity(
                    supplier.commodities,
                    supplierInfo.SupplierProduct
                );

                commodity.plannedQuantity += quantity * supplierInfo.Quantity;
                console.log(quantity);
            }
        }

        function getOrCreateSupplier(suppliers, supplierName) {
            var supplier = null;
            for (var i = 0; i < suppliers.length; i++) {
                supplier = suppliers[i];
                if (supplier.name == supplierName) {
                    return supplier;
                }
            }

            supplier = {
                name: supplierName,
                commodities: []
            };

            suppliers.push(supplier);
            return supplier;
        }

        function getOrCreateCommodity(commodities, commodityName) {
            var commodity = null;
            for (var i = 0; i < commodities.length; i++) {
                commodity = commodities[i];
                if (commodity.name == commodityName) {
                    return commodity;
                }
            }

            commodity = {
                name: commodityName,
                plannedQuantity: 0.0
            };

            commodities.push(commodity);
            return commodity;
        }

        function addInfoWithQuantity(commodities, supplierInfo, quantity) { }

        return service;
    }
})();
