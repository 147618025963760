(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('auth', {
            controller: 'TabletAuthController',
            templateUrl:  '/components/auth/auth.html',
            bindings: {

            }
        });
}());