(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .component('filterButton', {
                templateUrl: '/components/filter-button/filter-button.html',
                controller: 'FilterButtonController',
                bindings: { 
                    'settings': '<',
                    'ifShowAllFilterCheckboxes': '<',
                    'showSorting': '<'
                }
            });
}());