(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("NumberInputController", [NumberInputController]);

    function NumberInputController() {
        var $ctrl = this;

        $ctrl.$onInit = function() {
            $ctrl.min = $ctrl.min || 0;
        };

        $ctrl.$onChanges = function() {
            $ctrl.checkBoundaries();
        };

        $ctrl.inc = function() {
            $ctrl.value += 1;
            $ctrl.checkBoundaries();
            $ctrl.valueChanged({ value: $ctrl.value });
        };

        $ctrl.dec = function() {
            $ctrl.value -= 1;
            $ctrl.checkBoundaries();
            $ctrl.valueChanged({ value: $ctrl.value });
        };

        $ctrl.changed = function() {
            $ctrl.checkBoundaries();
            $ctrl.valueChanged({ value: $ctrl.value });
        };

        $ctrl.checkBoundaries = function() {
            if ($ctrl.value < $ctrl.min) {
                $ctrl.value = $ctrl.min;
            }

            if ($ctrl.max && $ctrl.value > $ctrl.max) {
                $ctrl.value = $ctrl.max;
            }
        };
    }
})();
