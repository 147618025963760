(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("KitchenTimelineController", [
            "TabletConfigService",
            KitchenTimelineController]);

    function KitchenTimelineController(configService) {
        var $ctrl = this;
        $ctrl.selectedDate = null;
        $ctrl.freshnessHours = -1;

        $ctrl.$onInit = function() {
            $ctrl.settings = configService.getSettings();
            readFreshnessHours();
        };

        $ctrl.$onChanges = function(changes) {
            if (changes.quantity) {
                fitAmount(changes.quantity.currentValue);
            }

            if (changes.timeline) {
                fitAmount($ctrl.quantity);
            }
        };

        function readFreshnessHours() {
            $ctrl.freshnessHours = -1;
            if ($ctrl.job && $ctrl.job.FreshnessMinutes > 0) {
                $ctrl.freshnessHours = $ctrl.job.FreshnessMinutes/60;
            }
        }

        function fitAmount(remainingQty) {
            readFreshnessHours();
            var minDateMatched = true;
            var maxDateMatched = false;

            if ($ctrl.minQuantity) {
                remainingQty = Math.max($ctrl.min || remainingQty);
            }

            if ($ctrl.minDate) {
                minDateMatched = false;
            }

            for (var i = 0; i < $ctrl.timeline.length; i++) {
                var current = $ctrl.timeline[i];

                if (!minDateMatched) {
                    if (new Date(current.date).getTime() !== new Date($ctrl.minDate).getTime()) {
                        current.isSelectable = false;
                        continue;
                    }
                    minDateMatched = true;
                }

                if (maxDateMatched) {
                    current.isSelectable = false;
                    continue;
                }

                current.isSelectable = true;

                if (remainingQty >= current.value && current.value > 0) {
                    current.inProduction = 0;
                    current.isSelected = true;
                    remainingQty -= current.value;
                } else {
                    if (current.value > 0) {
                        current.inProduction = remainingQty;
                        remainingQty = 0;
                    } else {
                        current.inProduction = 0;
                    }
                    current.isSelected = false;
                }

                if ($ctrl.maxDate && !maxDateMatched) {
                    if (new Date(current.date).getTime() === new Date($ctrl.maxDate).getTime()) {
                        maxDateMatched = true;
                    }
                }
                if ($ctrl.job && $ctrl.job.Date && $ctrl.job.FreshnessHours > 0 && !maxDateMatched) {   
                    var currentEntryTime = moment(new Date($ctrl.job.Date).addHours(current.date));
                    if (currentEntryTime >= $ctrl.job.MaxFreshnessTime) {
                        current.isSelectable = false;
                        maxDateMatched = true;
                    }
                }
            }
        }

        Date.prototype.addHours = function(h){
            this.setHours(this.getHours()+h);
            return this;
        };

        $ctrl.toggleAll = function() {
            for (var i = $ctrl.timeline.length - 1; i >= 0; i--) {
                var current = $ctrl.timeline[i];
                if (current.value > 0) {
                    if (current === $ctrl.selectedDate) {
                        return $ctrl.selectedTimelineDate(null);
                    }
                    return $ctrl.selectedTimelineDate(current);
                }
            }
        };

        $ctrl.selectedTimelineDate = function(timelineDate) {
            var productionAmount = 0;
            var lastWithValue = null;
            var endIt = false;
            if (!timelineDate) {
                endIt = true;
                $ctrl.selectedDate = null;
            }

            for (var i = 0; i < $ctrl.timeline.length; i++) {
                var current = $ctrl.timeline[i];

                current.inProduction = 0;
                if (endIt || !current.isSelectable) {
                    current.isSelected = false;
                    continue;
                }

                if (current === timelineDate) {
                    if ($ctrl.selectedDate === timelineDate) {
                        $ctrl.selectedDate = lastWithValue;
                        timelineDate.isSelected = false;
                        break;
                    } else {
                        $ctrl.selectedDate = timelineDate;
                    }

                    endIt = true;
                }

                if (current.value > 0) {
                    current.isSelected = true;
                    lastWithValue = current;
                    productionAmount += current.value;
                }
            }

            productionAmount = Math.ceil(productionAmount);

            fitAmount(productionAmount);

            $ctrl.changeQuantity({ quantity: productionAmount });
        };

        return $ctrl;
    }
})();
