(function () {
    'use strict';
    var app = angular.module('laekkerAI.tablet');

    app.directive('backgroundImage', function () {
        return function (scope, element, attrs) {
            element.css({
                'background-image': 'url(' + attrs.backgroundImage + ')',
            });
        };
    });
})();