(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("KitchenCategorySelectionController", [
            "KitchenGroupService",
            "TabletConfigService",
            "KitchenJobService",
            "TabletEventService",
            "$state",
            "PRODUCTION_TYPE",
            "TabletBaseService",
            function(groupService, configService, jobService, eventService, $state,PRODUCTION_TYPE, baseService) {
                var $ctrl = this;

                var PRODUCTION_MODE_NAME = {DAILY: "Tagesproduktion", PRE:"Vorproduktion"};

                $ctrl.$onInit = function() {
                    checkDate();
                    $ctrl.settings = configService.getSettings();
                    setProductionType();
                };

                function checkDate() {        
                    if (requiresSwitchToDaily()) {
                        console.warn("checkDate: requiresSwitchToDaily is TRUE");
                        $ctrl.isNextDaySwitchingProgress = true;
                        configService.setProductionDate(baseService.getToday());
                        eventService.broadcast(eventService.events.resetJobs);
                        $ctrl.isNextDaySwitchingProgress = false;
                    } 
                }
        
                function requiresSwitchToDaily() {
                    var currentDate = new Date($ctrl.settings.production.currentDate);
                    var today = baseService.getToday();        
                    return currentDate < today;
                }

                function setProductionType() {
                    $ctrl.productionTypeName = PRODUCTION_MODE_NAME.DAILY;
                    $ctrl.productionType = $ctrl.settings.production.productionType;
                    if ($ctrl.settings.production.productionType == PRODUCTION_TYPE.PRE) {
                        $ctrl.productionTypeName = PRODUCTION_MODE_NAME.PRE;
                    }
                }

                $ctrl.setToDailyProduction = function() {
                    configService.setProductionType(PRODUCTION_TYPE.DAILY);
                    eventService.broadcast(eventService.events.switchedMode);
                    setProductionType();
                };

                $ctrl.setToPreProduction = function() {
                    configService.setProductionType(PRODUCTION_TYPE.PRE);
                    eventService.broadcast(eventService.events.switchedMode);
                    setProductionType();
                };

                $ctrl.selectCategory = function(category) {
                    $ctrl.selectedCategory = category;

                    if (!category) {
                        return;
                    }

                    $state.go("kitchen.category-selection.category", {
                        categoryId: category.ID
                    });

                    
                };


            }
        ]);
})();
