(function() {
    "use strict";

    angular.module("laekkerAI.tablet").controller("KitchenCategoryController", [
        "KitchenGroupService",
        "KitchenJobService",
        "Utility",
        "$state",
        "TabletConfigService",
        "TabletEventService",
        "TabletNotificationService",
        function(
            groupService,
            jobService,
            utility,
            $state,
            configService,
            eventService,
            notificationService
        ) {
            var $ctrl = this;

            $ctrl.COMMON_PLACEHOLDER = 'Allgemein';

            $ctrl.subCategories = [];
            $ctrl.groupedJobs = null;

            $ctrl.$onInit = function() {
                eventService.subscribe(
                    eventService.events.jobsLoaded,
                    changeJobCount
                );
                eventService.subscribe(
                    eventService.events.switchedMode,
                    changeJobCount
                );

                eventService.subscribe(
                    eventService.events.dateChanged,
                    changeJobCount
                );
                loadData();
            };

            function loadData() {
                jobService.getJobGroups(
                    $ctrl.settings.production.currentDate,
                    function(err, data) {
                        $ctrl.groupedJobs = data;
                        groupService.getChildGroups(
                            $ctrl.categoryId,
                            processSubCategories
                        );
                    }
                );
            }

            function processSubCategories(subCategories) {
                $ctrl.subCategories = subCategories.concat({
                    name : $ctrl.COMMON_PLACEHOLDER,
                    ID: $ctrl.categoryId
                });

                $ctrl.subCategories = $ctrl.subCategories.filter(function (sc){ return sc.name !== 'Postino'; });

                for (var i = 0; i < $ctrl.subCategories.length; i++) {
                    var s = $ctrl.subCategories[i];
                    s.isSelected = utility.contains(
                        $ctrl.settings.selectedCategories,
                        s.ID
                    );
                    s.jobCount = getJobCountForGroupFiltered(s.ID);
                }
            }

            function changeJobCount() {
                console.log("date changed");
                jobService.getJobGroups(
                    $ctrl.settings.production.currentDate,
                    function(err, data) {
                        $ctrl.groupedJobs = data;
                        for (var i = 0; i < $ctrl.subCategories.length; i++) {
                            $ctrl.subCategories[i].jobCount = getJobCountForGroupFiltered($ctrl.subCategories[i].ID);
                        }
                    }
                );
            }

            function getJobCountForGroupFiltered(groupID) {
                return groupService.getJobCountForGroupFiltered(groupID,$ctrl.groupedJobs);
            }

            $ctrl.toggleSelection = function(subCategory) {
                subCategory.isSelected = !subCategory.isSelected;
            };

            $ctrl.gotoProduction = function() {
                var selected = $ctrl.subCategories
                    .filter(function(s) {
                        return s.isSelected;
                    })
                    .map(function(s) {
                        return s.ID;
                    });

                if (selected.length === 0) {
                    return notificationService.info(
                        "Bitte wähle zuerst eine Kategorie aus.",
                        8000
                    );
                }

                var selectedWithCurrentCategoryId = selected.concat([ parseInt($ctrl.categoryId) ]);

                configService.setSelectedCategories(selectedWithCurrentCategoryId);
                $state.go("kitchen.production", {
                    categoryId: $ctrl.categoryId
                });
            };

            $ctrl.selectAll = function () {
                $ctrl.subCategories.filter(function(i) {
                    return !i.isSelected;
                }).forEach($ctrl.toggleSelection);
            };

            $ctrl.selectNone = function () {
                $ctrl.subCategories.filter(function(i) {
                    return i.isSelected;
                }).forEach($ctrl.toggleSelection);
            };
        }
    ]);
})();
