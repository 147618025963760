(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('StockQuestionController', [
            'KitchenStockService',
            'TabletNotificationService',
            function (stockService, notificationService) {
                var $ctrl = this;

                $ctrl.IsChangeStockMode = false;
                $ctrl.change = 0;
                $ctrl.newQuantity = 0;               

                $ctrl.$onInit = function () {
                    if($ctrl.resolve !== undefined) {
                        angular.extend($ctrl, $ctrl.resolve);
                    }
                };

                $ctrl.noChange = function () {
                    $ctrl.stockChanged($ctrl.currentStock);
                    $ctrl.close();
                };

                $ctrl.changeStockMode = function() {
                    $ctrl.IsChangeStockMode = true;
                };

                $ctrl.updateStock = function () {

                    var change = $ctrl.newQuantity - $ctrl.currentStock;
                    var changeType = 8;
                    if (change < 0) {
                        changeType = 3;
                    }

                    stockService.updateStock($ctrl.key, change, changeType, false,
                    function (err) {
                        if(err) {
                            return notificationService.error('Bei der Aktualisierung des Lagerbestandes ist ein Problem aufgetreten.');
                        }
                        
                        notificationService.success('Erfolg. Die Bestandskorrektur wird verarbeitet und sich in den nächsten Minuten auf den Lagerbestand auswirken');
                        $ctrl.stockChanged($ctrl.newQuantity);
                        $ctrl.close();
                    });
                };
            }]);
}());