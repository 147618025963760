(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("HomeButtonController", [HomeButtonController]);

    function HomeButtonController() {
        var $ctrl = this;

        $ctrl.$onInit = function() {};

        $ctrl.$onChanges = function(changes) {};

        $ctrl.$onDestroy = function() {};
    }
})();
