(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('myCity', {
            controller: 'MyCityController',
            templateUrl: '/components/my-city/my-city.html',
            bindings: {
            }
        });
})();