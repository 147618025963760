(function(angular, moment) {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("KitchenProductionJobContentController", [
            "TabletConfigService",
            "TabletDialogService",
            "TabletAuthService",
            "TabletEventService",
            function(configService, dialogService, authService, eventService) {
                var $ctrl = this;
                var sub;
                $ctrl.showRecipe = true;
                $ctrl.imageUrl = "images/product_default.png";

                $ctrl.$onInit = function() {
                    // $ctrl.job.ID
                    $ctrl.settings = configService.getSettings();
                    
                    if (!$ctrl.allowTimeMeasurement) {
                        $ctrl.showRecipe = false;
                    }

                    $ctrl.imageUrl = __appConfig.web + "products/image/" + $ctrl.job.Key.ID;
                    
                    authService.hasPermission('STOCK_CHANGE', function (result) {
                        if (result.permission === 'STOCK_CHANGE') {
                            $ctrl.stockChanger = result.granted;
                        }
                    });

                    sub = eventService.subscribe(
                        eventService.events.recipeVideoLoaded,
                        recipeVideoInfoUpdated
                    );
                };

                $ctrl.$onDestroy = function () {
                    eventService.unsubscribe(sub);
                };

                function recipeVideoInfoUpdated(recipeData) {
                    $ctrl.videoUrl = recipeData.videoUrl;
                    $ctrl.description = recipeData.description;
                }

                $ctrl.showVideo = function() {
                    dialogService.open("videoPlayer", {
                        imageUrl: $ctrl.imageUrl,
                        videoUrl: $ctrl.videoUrl,
                        title: $ctrl.job.Name,
                        description: $ctrl.description
                    });
                };

                $ctrl.reportProduct = function () {
                    dialogService.open("bugReport", {
                        job: $ctrl.job,
                        onDone: function () {}
                    });
                };
                

                $ctrl.editStock = function () {
                    dialogService.open('stockManager', {
                        key: $ctrl.job.Key,
                        currentStock: $ctrl.job.Stock,
                        title: $ctrl.job.Name,
                        stockChanged: function (newQuantity) {
                            $ctrl.job.Stock = newQuantity;
                            eventService.broadcast('updateTimeline');
                        }
                    });
                };

                return $ctrl;
            }
        ]);
})(window.angular, window.moment);
