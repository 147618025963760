(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name TabletBugService
     * @description
     * this controller is used for processing bug reports and provides various methods to do so
     */
    angular
        .module('laekkerAI.tablet')
        .factory(
        'TabletBugService',
        [
            'TabletApiService',
            'TabletConfigService',
            'TabletBaseService',
            'TabletLocalStorageService',
            '$http',
            '$q',
            function (ApiService, ConfigService, BaseService, LocalStorageService, $http, $q) {
                return {
                    /**
                    * @ngdoc method
                    * @methodOf TabletBugService
                    * @name createBugReport
                    * @description
                    * this method reports a bug to the api. it collects various informations about the current state of the tablet
                    * @param {String}   component   may contain for example aveatoKitchen or TabletApp and describes the type of component affected by the bug
                    * @param {String}   type   depending on the component you should provide a type too, to allow the developers to find the bug more quickly
                    * @param {function} callback    the callback method is invoked whenever the submit process is successfull
                    * @returns {Promise}    returns the api call promise. you can use this to invoke additional methods on success or error
                    */
                    createBugReport: _createBugReport,
                    /**
                    * @ngdoc method
                    * @methodOf TabletBugService
                    * @name getComponents
                    * @description
                    * this method provides the components that are currently available
                    * @param {function} callback    the callback method is invoked whenever the submit process is successfull
                    * @returns {Promise}    returns the api call promise. you can use this to invoke additional methods on success or error
                    */
                    getComponents: _getComponents,

                    /**
                    * @ngdoc method
                    * @methodOf TabletBugService
                    * @name importDumpFile
                    * @description
                    * the method processes a local storage dump file and sets its content to the local storage. afterwards the page refreshes
                    * and you should see the moment of the report
                    * @param {function} dump    contains the local storage dump
                    */
                    importDumpFile: _importDumpFile
                };

                function _importDumpFile(dump) {
                    //LocalStorageService.clear();

                    angular.forEach(dump, function (val, key) {
                        LocalStorageService.set(key, val);
                    });
                    LocalStorageService.set('_DUMP', true);
                    document.location.reload();
                }

                function _getComponents(callback) {
                    return $q(function (resolve, reject) {
                        var data = [{
                            title: 'AI Kitchen',
                            types: [
                                // { id: 0, messageRequired: true, title: 'Falscher Lagerbestand' },
                                { id: 1, messageRequired: true, title: 'Falsche Produktanzahl' },
                                { id: 2, messageRequired: false, title: 'Produkt nicht sichtbar' },
                                { id: 3, messageRequired: true, title: 'anderer Fehler' }
                            ]
                        }, {
                            title: 'Inventur App',
                            types: [
                                { id: 4, messageRequired: false, title: 'Inventur nicht start-/abschließbar' },
                                { id: 5, messageRequired: false, title: 'Werte nicht messbar' },
                                { id: 6, messageRequired: true, title: 'anderer Fehler' }
                            ]
                        }];
                        resolve(data);
                        return BaseService.invoke(callback, data);
                    });
                }

                function _createBugReport(component, type, message, product, callback) {
                    var summary = 'TAB ' + ConfigService.getLocation().Name + ': ' + product + ' (' + type + ')';
                    ApiService.saveRequestStore();

                    var desc = 'Es ist ein Fehler bei der Verwendung des Tablets aufgetreten. Im Anhang des Tickets befinden sich alle erfassten Informationen.\n\n' +
                        'h3. Allgemeines \n' +
                        'Standort: ' + ConfigService.getLocation().Name + '\n\n' +
                        'Mitarbeiter: ' + ConfigService.getUser().Name + '\n\n' +
                        'Uhrzeit: ' + (new Date()).toJSON() + '\n\n' +
                        'h3. Details \n' +
                        'Komponente: ' + component + '\n\n' +
                        'Art: ' + type + '\n\n' +
                        'Nachricht des Mitarbeiters: ' + message + '\n\n';

                    var formData = new FormData();
                    formData.append('summary', summary);
                    formData.append('description', desc);
                    formData.append('localStorageDump.json', _getLocalStorageContent());


                    return $http.post(__appConfig.api + 'tickets/tabletbug', formData, {
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined,
                            'ApiKey': ConfigService.getAuthToken()
                        }
                    }).then(function () {
                        BaseService.invoke(callback, 'success');
                    },
                        function () {
                            BaseService.invoke(callback, 'failed');
                        });
                }

                function _getLocalStorageContent() {
                    var lsDump = {};

                    LocalStorageService.keys().forEach(function (key) {
                        lsDump[key] = LocalStorageService.get(key);
                    });
                    return new Blob([JSON.stringify(lsDump, null, 2)], { type: 'application/json' });
                }
            }
        ]);
}());