(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("ProductStockItemController", [
            "KitchenJobService",
            "TabletEventService",
            "PRODUCTION_TYPE",
            "TabletConfigService",
            function (jobService, eventService, PRODUCTION_TYPE, configService) {
                var subscription = null;
                var $ctrl = this;
                $ctrl.timeline = null;

                $ctrl.settings = configService.getSettings();
                $ctrl.isPreproduction = false;
                $ctrl.allowTimeMeasurement = true;

                if ($ctrl.settings.production.productionType == PRODUCTION_TYPE.PRE) {
                    $ctrl.isPreproduction = true;
                }

                $ctrl.$onInit = function () {

                    if($ctrl.job.quantity && $ctrl.job.Status !== 35) {
                        $ctrl.changeQuantity($ctrl.job.quantity);
                    } else {
                        fixAmount();
                    }

                    subscription = eventService.subscribe(
                        eventService.events.updateTimeline,
                        updateTimeline
                    );

                    jobService.getTimeline($ctrl.job, function (err, timeline) {
                        $ctrl.timeline = timeline;
                    });
                };

                $ctrl.$onChanges = function (ch) {
                    if(ch.quantity) {
                        $ctrl.changeQuantity(ch.quantity);
                    }
                };

                function updateTimeline() {
                    console.log("updating timeline " + $ctrl.job.Name);
                    jobService.updateTimeline(
                        $ctrl.job,
                        $ctrl.timeline,
                        function (err, timeline) {
                            fixAmount()
                        }
                    );
                }

                $ctrl.$onChanges = function (changes) {
                    fixAmount();
                };

                function fixAmount() {
                    if ($ctrl.quantity === 0 || $ctrl.job.Status === 35) {
                        $ctrl.changeQuantity(Math.ceil($ctrl.useTotal ? $ctrl.job.Remaining : $ctrl.job.UpcomingAmount));
                    }
                }

                $ctrl.$onDestroy = function () {
                    eventService.unsubscribe(
                        eventService.events.updateTimeline,
                        subscription
                    );
                };

                $ctrl.toggleJob = function () {
                    $ctrl.toggleSelection();
                    if ($ctrl.isSelected) {
                        // load timeline
                    }
                };

                $ctrl.changeQuantity = function (quantity) {
                    $ctrl.quantity = quantity;
                    jobService.changeQuantity($ctrl.job, quantity);
                };

                return $ctrl;
            }
        ]);
})();
