(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("plannedTime", {
        templateUrl:
            "components/planned-time/planned-time.html",
        controller: "PlannedTimeController",
        bindings: {
            min: "<",
            max: "<"
        }
    });
})();