(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .factory("KitchenJobService", [
            "TabletBaseService",
            "TabletApiService",
            "TabletLocalStorageService",
            "TabletConfigService",
            "TabletEventService",
            "PRODUCTION_TYPE",
            JobService
        ]);

    function JobService(
        baseService,
        apiService,
        localStorageService,
        configService,
        eventService,
        PRODUCTION_TYPE
    ) {
        var MINHOUR = 6;
        var MAXHOUR = 16;

        var REMAININGTIME_COLORS = [
            {
                time: 86400,
                color: "green"
            },
            {
                time: 1800,
                color: "yellow"
            },
            {
                time: 900,
                color: "orange"
            },
            {
                time: 0,
                color: "red"
            }
        ];

        var loadedJobGroups = [];
        var loadedJobs = [];

        var service = this;

        service.init = function () {
            eventService.subscribe(
                eventService.events.updateSpecificJobs,
                updateSpecificJobs
            );

            eventService.subscribe(
                eventService.events.locationChanged,
                service.updateJobs
            );
        };

        function updateSpecificJobs(keys, date) {
            service.updateJobs(keys, function () {
                // eventService.broadcast(eventService.events.updateTimeline);
            });
        }

        service.getJobs = function (date, callback) {
            var formattedDate = baseService.dateToString(date);

            var entry = getFromDateDictionary(formattedDate, loadedJobs);

            if (entry) {
                callback(null, entry.jobs);
            }

            service.getJobGroups(date, function (err, jobGroups) {
                if (err) {
                    callback(err);
                }
                return getFromDateDictionary(formattedDate, loadedJobs);
            });
        };

        service.getJobGroups = function (date, callback) {
            getAllJobGroups(date, callback);
        };

        function getAllJobGroups(date, callback) {
            getJobGroupsFromCache(date, function (err, jobGroups) {
                if (err) {
                    return callback(err);
                }

                if (jobGroups) {
                    return callback(null, jobGroups);
                }

                service.updateJobs(null, function () {
                    service.getJobGroups(date, callback);
                });
            });
        }

        service.updateJobs = function (keys, callback) {
            var datesToUpdate = getDatesToUpdate();
            var idx = 0;

            var nowStamp = baseService.getStamp();

            function processJobsFromApi(err, updatedJobs, dateToUpdate, keys) {
                if (err) {
                    return console.log(err);
                }

                getJobGroupsFromCache(dateToUpdate, function (
                    err,
                    jobGroups
                ) {
                    if (err) {
                        return console.error(err);
                    }

                    if (!keys || keys.length === 0) {
                        removeOldJobs(jobGroups, updatedJobs);
                    }

                    var dateStamp = baseService.getStamp(dateToUpdate);
                    addOrUpdateJobs(
                        jobGroups,
                        updatedJobs,
                        dateStamp,
                        nowStamp
                    );

                    storeJobs(dateToUpdate, jobGroups);
                    console.log('jobService : call processNextDate from inside getJobGroupsFromCache');
                    processNextDate();
                });
            }

            function processNextDate() {
                console.log('jobService : processNextDate for date index ' + idx);
                if (idx >= datesToUpdate.length) {
                    console.warn('EVENT BROADCAST: JobService broadcasts eventService.events.jobsLoaded (datesToUpdate.length = ' + datesToUpdate.length + ' and idx = ' + idx + ')');
                    eventService.broadcast(eventService.events.jobsLoaded);
                    if (callback) {
                        return callback();
                    }
                    return;
                }

                getJobsFromApi(datesToUpdate[idx++], keys, processJobsFromApi);
            }

            console.log('jobService : start processNextDate');
            processNextDate();
        };

        function getDatesToUpdate() {
            var dates = [];
            for (var i = 0; i < 5; i++) {
                var today = baseService.getToday();
                var then = new Date(today.setDate(today.getDate() + i));
                dates.push(then);
            }
            return dates;
        }

        function getJobsFromApi(date, keys, callback) {
            var formattedDate = baseService.dateToString(date);

            var location = configService.getLocation();
            if (!location) {
                return callback("location is null");
            }
            var locationId = location.ID;

            var targetUrl =
                "productionstorages/jobs/" + locationId + "/" + formattedDate;

            function onSuccess(jobs) {
                callback(null, jobs, date, keys);
            }
            function onError(response) {
                callback(response);
            }

            return apiService.post(targetUrl, keys || [], onSuccess, onError);
        }

        function getJobGroupsFromCache(date, callback) {
            var formattedDate = baseService.dateToString(date);

            var jobs = getFromDateDictionary(formattedDate, loadedJobGroups);
            if (jobs) {
                return callback(null, jobs);
            }

            jobs = getJobsFromLocalStorage(date) || [];

            var list = createForDate(formattedDate, loadedJobGroups);
            list.jobs = jobs;

            return callback(null, jobs);
        }

        function getFromDateDictionary(formattedDate, list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].date != formattedDate) {
                    continue;
                }

                return list[i].jobs;
            }

            return null;
        }

        function createForDate(formattedDate, list) {
            var entry = {
                date: formattedDate,
                jobs: []
            };

            list.push(entry);
            return entry;
        }

        function removeOldJobs(jobGroups, updatedJobs) {
            for (
                var jobGroupIndex = 0;
                jobGroupIndex < jobGroups.length;
                jobGroupIndex++
            ) {
                var jobGroup = jobGroups[jobGroupIndex];

                for (
                    var jobIndex = jobGroup.jobs.length - 1;
                    jobIndex >= 0;
                    jobIndex--
                ) {
                    var job = jobGroup.jobs[jobIndex];
                    var updatedJob = getJobFromList(updatedJobs, job);
                    if (
                        updatedJob &&
                        getGroupIdFromJob(updatedJob) === jobGroup.groupId
                    ) {
                        continue;
                    }

                    jobGroup.jobs.splice(jobIndex, 1);
                }
            }
        }

        function addOrUpdateJobs(
            jobGroups,
            updatedJobs,
            dateStamp,
            nowStamp
        ) {
            if (!updatedJobs) {
                return;
            }

            var user = configService.getUser();
            if (!user) {
                throw "user is not present";
            }
            var currentUserName = user.Name;
            for (var i = 0; i < updatedJobs.length; i++) {
                updateJob(
                    jobGroups,
                    updatedJobs[i],
                    currentUserName,
                    dateStamp,
                    nowStamp
                );
            }
        }

        function updateJob(
            jobGroups,
            updatedJob,
            currentUserName,
            dateStamp,
            nowStamp
        ) {
            var job = getJobFromGroup(jobGroups, updatedJob); /// <-- type 
            if (job) {
                updateBaseJobInfos(job, updatedJob);
            } else {
                job = updatedJob;

                var groupId = getGroupIdFromJob(job);

                var jobGroup =
                    getJobGroup(jobGroups, groupId) ||
                    createJobGroup(jobGroups, groupId);
                jobGroup.jobs.push(job);
            }

            updateRemaining(job, updatedJob);

            updateUpcomingProductionHourAndAmounts(job, dateStamp, nowStamp);
            updateUpcomingSeverity(job);
            updateOwnership(job, currentUserName);
        }

        function updateRemaining(job, updatedJob) {
            job.Remaining = updatedJob.Required - updatedJob.Produced;
            if (isNaN(job.Remaining)) {
                job.Remaining = 0.0;
            } else {
                job.Remaining = Math.max(0, job.Remaining);
            }

            if (job.ProductionJobType === 0) {
                updateTasks(job, job.Remaining);
            }
        }

        function updateTasks(job, remainingQuantity) {
            var tasks = job.Tasks;
            for (var i = 0; i < tasks.length; i++) {
                var task = tasks[i];

                // reset
                task.Remaining = task.Required;
                task.Done = 0;

                // recalc remaining

                if (remainingQuantity >= task.Remaining) {
                    remainingQuantity -= task.Remaining;
                    continue;
                }

                if (remainingQuantity < task.Remaining) {
                    task.Done = task.Remaining - remainingQuantity;
                    task.Remaining = task.Required - task.Done;
                    remainingQuantity = task.Remaining;
                }
            }
        }

        function updateUpcomingSeverity(job) {
            var defaultColor = REMAININGTIME_COLORS[0];
            job.Severity = defaultColor.color;

            for (var i = 0; i < REMAININGTIME_COLORS.length; i++) {
                var color = REMAININGTIME_COLORS[i];

                if (job.RemainingTime <= color.time) {
                    job.Severity = color.color;
                    continue;
                }

                break;
            }
        }

        function getJobGroup(jobGroups, groupdId) {
            for (var i = 0; i < jobGroups.length; i++) {
                var jobGroup = jobGroups[i];

                if (jobGroup.groupId !== groupdId) {
                    continue;
                }

                return jobGroup;
            }
            return null;
        }

        function createJobGroup(jobGroups, groupId) {
            var jobGroup = {
                groupId: groupId,
                jobs: []
            };
            jobGroups.push(jobGroup);
            return jobGroup;
        }

        function updateOwnership(job, currentWorkerName) {
            job.IsOwner =
                job.WorkerName == currentWorkerName || !job.WorkerName;
        }

        function getJobFromList(jobs, searchedJob) {
            for (var i = 0; i < jobs.length; i++) {
                var job = jobs[i];
                if (!jobsHaveSameId(job, searchedJob) || job.ProductionJobType !== searchedJob.ProductionJobType) {
                    continue;
                }

                return job;
            }

            return null;
        }

        function getGroupIdFromJob(job) {
            if (!job.GroupIDs || job.GroupIDs.length == 0) {
                return "unknown";
            }

            return job.GroupIDs[0];
        }

        function getJobFromGroup(jobGroups, searchedJob) {
            var groupId = getGroupIdFromJob(searchedJob);

            var jobGroup = getJobGroup(jobGroups, groupId);
            if (!jobGroup) {
                return null;
            }

            return getJobFromList(jobGroup.jobs, searchedJob);
        }


        function jobsHaveSameId(firstJob, secondJob) {
            //console.log("check id",firstJob.Key.ID,secondJob.Key.ID);
            return (
                firstJob.Key.ID === secondJob.Key.ID &&
                firstJob.Key.Type === secondJob.Key.Type
            );
        }

        function updateBaseJobInfos(job, updatedJob) {
            job.Produced = updatedJob.Produced;
            job.Required = updatedJob.Required;

            job.Status = updatedJob.Status;
            job.Tasks = updatedJob.Tasks;
            job.WorkerCount = updatedJob.WorkerCount;
            job.WorkerName = updatedJob.WorkerName;
            job.Name = updatedJob.Name;
            job.RequiresHaccp = updatedJob.RequiresHaccp;
        }

        Date.prototype.addHours = function (h) {
            this.setHours(this.getHours() + h);
            return this;
        };

        Date.prototype.setToEndOfDay = function () {
            this.setHours(23);
            this.setMinutes(59);
            this.setSeconds(59);
            return this;
        };

        function updateUpcomingProductionHourAndAmounts(
            job,
            dateStamp,
            nowStamp
        ) {
            var upcomingAmount = 0;

            var minHour = MAXHOUR;
            var minHourAmount = 0;

            var maxDateMatched = false;

            job.FreshnessMaxAmount = 0;
            job.FreshnessHours = 0;
            job.FreshnessDays = 0;
            job.FreshnessTilEndOfDay = false;
            if (job.FreshnessMinutes > 0) {
                job.FreshnessDays = job.FreshnessMinutes / 1440;
                job.FreshnessHours = job.FreshnessMinutes / 60;
                job.FreshnessTilEndOfDay = (job.FreshnessHours >= 24);
            }
            if (job.Date && job.FreshnessHours > 0) {
                var maxFreshnessDate = new Date().addHours(job.FreshnessHours);
                if (job.FreshnessTilEndOfDay) {
                    maxFreshnessDate.setToEndOfDay();
                }
                job.MaxFreshnessTime = moment(maxFreshnessDate);
            }

            var allTasksEmpty = true;
            var task;
            for (var i = 0; i < job.Tasks.length; i++) {
                task = job.Tasks[i];

                task.Remaining = Math.max(task.Remaining, 0.0);

                if (isNaN(task.Remaining)) {
                    task.Remaining = 0.0;
                }

                if (isNaN(task.Hour)) {
                    continue;
                }

                if (maxDateMatched) {
                    continue;
                }
                var hour = parseInt(task.Hour);

                if (job.FreshnessHours > 0) {
                    var currentEntryTime = moment(new Date(job.Date).addHours(hour));
                    if (currentEntryTime >= job.MaxFreshnessTime) {

                        if (currentEntryTime <= moment().tomorrow) {
                            console.warn("NOTE: task disabled for job '" + job.Name + "' cause task.time '" + currentEntryTime.format("DD.MM.YY HH:mm") + "' > MaxFreshnessTime " + job.MaxFreshnessTime.format("DD.MM.YY HH:mm"));
                        }
                        task.isSelectable = false;
                        maxDateMatched = true;
                        continue;
                    } else {
                        task.isSelectable = true;
                        upcomingAmount += task.Remaining;
                        job.FreshnessMaxAmount += task.Remaining;
                    }
                } else {
                    upcomingAmount += task.Remaining;
                    task.isSelectable = true;
                    job.FreshnessMaxAmount += task.Remaining;
                }

                if (Math.min(MAXHOUR, hour) > minHour || task.Remaining <= 0) {
                    task.isSelectable = false;
                    continue;
                }

                minHour = hour;
                minHourAmount += task.Remaining;

                if (task.Required > 0) {
                    allTasksEmpty = false;
                }
            }

            minHour = parseInt(minHour);

            job.UpcomingProductionHour = minHour;
            job.RemainingTime = getRemainingTime(
                dateStamp,
                job.UpcomingProductionHour,
                nowStamp
            );

            job.UpcomingAmount = Math.ceil(upcomingAmount === 0 ? minHourAmount : upcomingAmount);
            job.Remaining = Math.ceil(job.Remaining);
            if (isNaN(job.Remaining)) {
                job.Remaining = 0;
            }

            //upcoming cannot be larger than remaining!!
            if (job.Remaining > 0 && job.UpcomingAmount > job.Remaining) {
                job.UpcomingAmount = job.Remaining;
            }

            // no tasks with required amount but job has remaining > 0?! SET UpcomingAmount to Remaining and UpcomingProductionHour to MINHOUR
            if (allTasksEmpty === true && job.Remaining > 0 &&
                (job.FreshnessMinutes <= 0 || (job.FreshnessMinutes > 0 && job.MaxFreshnessTime > moment(new Date(job.Date))))
            ) {
                if (job.MaxFreshnessTime && job.FreshnessMaxAmount == 0 && moment(new Date(job.Date)) <= moment().endOf('day') && job.MaxFreshnessTime > moment(new Date(job.Date).addHours(24))) {
                    job.FreshnessMaxAmount = job.Remaining;
                    console.warn("NOTE: set FreshnessMaxAmount to Remaining for job '" + job.Name + "' cause job is today and MaxFreshnessTime is later");
                }
                job.UpcomingAmount = job.Remaining;
                job.UpcomingProductionHour = MINHOUR;
                console.warn("NOTE: no tasks with required amount but job '" + job.Name + "' has remaining > 0. SET UpcomingAmount to Remaining and UpcomingProductionHour to MINHOUR");
            }
        }

        function getRemainingTime(dateStamp, hour, nowStamp) {
            return Math.max(0, dateStamp + hour * 3600 - nowStamp);
        }

        function storeJobs(date, jobs) {
            var formattedDate = baseService.dateToString(date);
            localStorageService.set(formattedDate, convertToStorageFormat(jobs));
        }

        function getJobsFromLocalStorage(date) {
            var formattedDate = baseService.dateToString(date);
            return convertFromStorageFormat(date, localStorageService.get(formattedDate));
        }

        service.startJob = function (job, quantity, callback) {

            job.Status = 36;
            _enqueue(job, "start", 0, callback);
        };

        function _enqueue(job, type, producedAmount, callback, forced) {
            setAsOwnerForJob(job);
            job.ProductionJobType = configService.getSettings().production.productionType;
            var actionContent = createActionContent(job, type, producedAmount, forced);
            sendActionToApi(actionContent, callback);
        }

        function sendActionToApi(actionContent, callback) {
            return apiService.post(
                "productionstations/processactions",
                [actionContent],
                function (data) {
                    callback(null, data);
                },
                function (response) {
                    callback(response);
                },
                apiService.options.STOREREQUEST
            );

        }

        function createActionContent(job, type, producedAmount, forced) {
            var workerCount = job.WorkerCount;
            if (!workerCount || workerCount < 1) {
                workerCount = 1;
            }

            var action = {
                key: job.Key,
                workerCount: workerCount,
                actionType: type,
                executionTimeUtc: baseService.getNow(),
                producedAmount: producedAmount,
                targetDate: baseService.dateToString(configService.getSettings().production.currentDate),
                productionType: job.ProductionJobType
            };

            if (forced === true) {
                action.overridePlausiblityChecks = true;
            }

            return action;
        }

        function setAsOwnerForJob(job) {
            job.WorkerName = configService.getUser().Name;
            job.IsOwner = true;
        }

        service.pauseJob = function (job, callback) {
            job.Status = 37;
            _enqueue(job, "pause", 0, callback);
        };

        service.addWorker = function (job, callback, workerCount) {
            job.WorkerCount = workerCount;
            _enqueue(job, "addactivityunit", 0, callback);
        };

        service.reduceWorker = function (job, callback) {
            job.WorkerCount = 1;
            _enqueue(job, "reduceactivityunit", 0, callback);
        };

        service.takeJob = function (job, callback) {
            setAsOwnerForJob(job);
            _enqueue(job, "takejob", 0, callback);
        };

        service.forceCheckIn = function (job, producedAmount, callback) {
            doCheckIn(job, producedAmount, true, callback);
        };

        service.checkIn = function (job, producedAmount, callback) {
            doCheckIn(job, producedAmount, false, callback);
        };

        function doCheckIn(job, producedAmount, forcedCheckin, callback) {
            if (!callback) {
                throw new Error('checkin requires a callback function to be present');
            }

            if (!job) {
                return callback('no job is present');
            }

            if (!producedAmount || producedAmount === 0) {
                return callback('produced amount must be greater than zero');
            }

            _enqueue(job, "complete", producedAmount, function (err) {
                if (err) {
                    return callback(err);
                }

                job.Status = 35;

                previewProductionChange(job, producedAmount);
                broadcastTimeLineUpdate();

                callback();
            }, forcedCheckin);
        }

        function broadcastTimeLineUpdate() {
            if (!eventService) {
                return;
            }
            eventService.broadcast(eventService.events.updateTimeline);
        }

        function previewProductionChange(job, producedAmount) {
            if (!configService) {
                return;
            }
            if (producedAmount < 0) {
                revertProduction(job, producedAmount);
            } else if (producedAmount > 0) {
                completeProduction(job, producedAmount);
            }
        }

        function revertProduction(job, amountToRevert) {
            job.Produced = Math.max(job.Produced - Math.abs(amountToRevert), 0);
            job.Stock = Math.max(job.Stock - Math.abs(amountToRevert), 0);
            recalcAndUpdateProducedAmounts(job);
        }

        function completeProduction(job, producedAmount) {
            job.Produced = Math.max(job.Produced + Math.abs(producedAmount), 0);
            job.Stock = Math.max(job.Stock + Math.abs(producedAmount), 0);
            job.Remaining = Math.max(job.Remaining - Math.abs(producedAmount), 0);
            job.UpcomingAmount = Math.max(job.UpcomingAmount - Math.abs(producedAmount), 0);
            recalcAndUpdateProducedAmounts(job);
        }

        function recalcAndUpdateProducedAmounts(job) {
            var currentDate = configService.getSettings().production
                .currentDate;
            var nowStamp = baseService.getStamp();
            var dateStamp = baseService.getTodayStamp(currentDate);

            recalcProducedAmounts(job);
            updateUpcomingProductionHourAndAmounts(job, dateStamp, nowStamp);
            updateUpcomingSeverity(job);
        }

        function recalcProducedAmounts(job) {
            if (!job.Tasks) {
                return;
            }

            job.Required = Math.max(job.Required, 0);

            if (isNaN(job.Remaining) || job.Remaining <= 0) {
                job.Remaining = Math.max(job.Required - job.Produced, 0);
            }

            if (job.Remaining > 0 && job.Produced > job.Required) {
                job.Remaining = 0;
            }

            var remainingProducedOnTasks = Math.max(0, job.Produced);

            for (var i = 0; i < job.Tasks.length; i++) {
                var task = job.Tasks[i];

                task.Done = 0;

                if (task.Required < remainingProducedOnTasks) {
                    remainingProducedOnTasks -= task.Required;
                    task.Done = task.Required;
                } else if (remainingProducedOnTasks > 0) {
                    task.Done = remainingProduced;
                    remainingProducedOnTasks = 0;
                }

                task.Remaining = task.Required - task.Done;
            }

        }

        service.getTimeline = function (job, callback) {
            var timeline = [];
            service.updateTimeline(job, timeline, callback);
        };

        service.updateTimeline = function (job, timeline, callback) {

            if (job.ProductionJobType == PRODUCTION_TYPE.PRE) {
                return callback(null, timeline);
            }

            resetTimelineRequirements(timeline);
            updateTimelineRequirements(job, timeline);

            callback(null, timeline);
        };

        function resetTimelineRequirements(timeline) {
            for (var hour = MINHOUR; hour <= MAXHOUR; hour++) {
                resetHour(timeline, hour);
            }

            resetHour(timeline, "Postino");
        }

        function resetHour(timeline, hour) {
            var timelineDate = getOrCreateTimelineDate(timeline, hour);
            if (!timelineDate) {
                return;
            }

            timelineDate.required = 0;
            timelineDate.open = 0;
            timelineDate.value = 0;
            timelineDate.done = 0;
        }

        function updateTimelineRequirements(job, timeline) {
            for (var i = 0; i < job.Tasks.length; i++) {
                var task = job.Tasks[i];
                var timelineDate = getOrCreateTimelineDate(timeline, task.Hour);
                addTaskTotimelineDate(timelineDate, task);
            }
        }

        function addTaskTotimelineDate(timelineDate, task) {
            timelineDate.required += task.Required;
            timelineDate.value += task.Remaining;
            timelineDate.done += task.Done;
        }

        function getOrCreateTimelineDate(timeline, date) {
            var dateString = date;
            if (date !== "Postino") {
                date = parseInt(date);
                if (date < MINHOUR) {
                    date = MINHOUR;
                }

                if (date > MAXHOUR) {
                    date = MAXHOUR;
                }

                dateString = date + " Uhr";
            }

            for (var i = 0; i < timeline.length; i++) {
                if (timeline[i].dateString === dateString) {
                    return timeline[i];
                }
            }

            var createdtimelineDate = {
                dateString: dateString,
                date: date,
                required: 0,
                value: 0,
                done: 0
            };

            timeline.push(createdtimelineDate);

            return createdtimelineDate;
        }

        service.changeQuantity = function (job, quantity) {
            job.quantity = quantity;
        };

        service.getLastActions = function (key, callback) {
            var locationId = configService.getLocation().ID;
            var today = moment({ hour: 0 }).format('YYYY-MM-DD');
            var tomorrow = moment({ hour: 0 }).add(1, 'days').format('YYYY-MM-DD');
            apiService.get('productionstations/performed-actions?locationId=' + locationId + '&from=' + today + '&to=' + tomorrow + '&productId=' + key.ID, function (data) {
                callback(null, data);
                console.log(data);
            }, function (err) {
                callback(err);
            });
        };

        function convertToStorageFormat(jobGroups) {
            if (!jobGroups) return [];
            return jobGroups.map(function (group) {
                return {
                    id: group.groupId,
                    jobs: group.jobs.map(function (job) {
                        return {
                            id: job.Key.ID,
                            type: job.Key.Type,
                            name: job.Name,
                            common: [
                                job.WorkerCount,
                                job.WorkerName,
                                job.Required,
                                job.Remaining,
                                job.Produced,
                                job.ProducedToday,
                                job.ProducedAmount,
                                job.Stock,
                                job.Status,
                                job.ProductionJobType,
                                job.RequiresRetainedSample,
                                job.MachineUsageTime,
                                job.ExpiresAfter,
                                job.ProductionDuration,
                                job.CooldownTime,
                                job.FreshnessMinutes,
                                job.Note,
                                job.FreshnessMaxAmount,
                                job.FreshnessHours,
                                job.FreshnessDays,
                                job.FreshnessTilEndOfDay,
                                job.MaxFreshnessTime,
                                job.UpcomingProductionHour,
                                job.RemainingTime,
                                job.UpcomingAmount,
                                job.Severity,
                                job.IsOwner,
                            ],
                            tasks: [
                                job.Tasks.map(function (task) {
                                    return [
                                        task.Hour,
                                        task.Required,
                                        task.Done,
                                        task.Remaining,
                                        task.isSelectable ? 1 : 0
                                    ];
                                })
                            ]
                        };
                    })
                };
            });
        }

        function convertFromStorageFormat(date, jobGroups) {
            if (!jobGroups) return [];
            return jobGroups.map(function (group) {
                return {
                    groupId: group.id,
                    jobs: group.jobs.map(function (job) {
                        return {
                            Key: {
                                ID: job.id,
                                Type: job.type
                            },
                            Date: date,
                            GroupIDs: [group.id],
                            Name: job.name,
                            WorkerCount: job.common[0],
                            WorkerName: job.common[1],
                            Required: job.common[2],
                            Remaining: job.common[3],
                            Produced: job.common[4],
                            ProducedToday: job.common[5],
                            ProducedAmount: job.common[6],
                            Stock: job.common[7],
                            Status: job.common[8],
                            ProductionJobType: job.common[9],
                            RequiresRetainedSample: job.common[10],
                            MachineUsageTime: job.common[11],
                            ExpiresAfter: job.common[12],
                            ProductionDuration: job.common[13],
                            CooldownTime: job.common[14],
                            FreshnessMinutes: job.common[15],
                            Note: job.common[16],
                            FreshnessMaxAmount: job.common[17],
                            FreshnessHours: job.common[18],
                            FreshnessDays: job.common[19],
                            FreshnessTilEndOfDay: job.common[20],
                            MaxFreshnessTime: job.common[21],
                            UpcomingProductionHour: job.common[22],
                            RemainingTime: job.common[23],
                            UpcomingAmount: job.common[24],
                            Severity: job.common[25],
                            IsOwner: job.common[26],
                            Tasks: job.tasks.map(function (task) {
                                return {
                                    Hour: task[0],
                                    Required: task[1],
                                    Done: task[2],
                                    Remaining: task[3],
                                    isSelectable: task[4] === 1
                                }
                            })
                        };
                    })
                };
            });
        }

        if (!window.jasmine) {
            service.init();
        }

        return service;
    }
})();
