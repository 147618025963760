(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller('QmSpotController', [
            function () {
                var $ctrl = this;
                $ctrl.$onInit = function () {
                    console.log('QmSpotController on init');
                };
            }
        ]);
})();
