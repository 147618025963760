(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('KitchenProductionJobHeaderController',
            [
                'KitchenJobService',
                'TabletNotificationService',
                "PRODUCTION_TYPE",
                "TabletConfigService",
                "TabletEventService",
                "$timeout",
                "TabletDialogService",
                KitchenProductionJobHeaderController
            ]);

    function KitchenProductionJobHeaderController(jobService, notificationService, PRODUCTION_TYPE, configService, eventService, $timeout, dialogService) {

        var $ctrl = this;

        $ctrl.settings = configService.getSettings();
        $ctrl.isPreproduction = false;
        $ctrl.allowTimeMeasurement = false;

        if ($ctrl.settings.production.productionType == PRODUCTION_TYPE.PRE) {
            $ctrl.isPreproduction = true;
        }

        $ctrl.$onInit = function () {
            $ctrl.jobName = $ctrl.job.Name;
            removePrefix('aufgebackene/r ');
            removePrefix('aufgetaute/r ');
            $ctrl.isInFuture = true;

            eventService.subscribe(
                eventService.events.dateChanged,
                dateChanged
            );
            checkIfDateIsInFuture();

            $(document).ready(function () {
                $("button,.dropdown").bind('touchstart hover click focus', function (e) {

                }, function () {
                    $(".lastclicked").removeClass("lastclicked");
                    $(this).addClass("lastclicked");
                });
            });

            refreshFreshnessInfo();
        };

        function dateChanged() {
            checkIfDateIsInFuture();
        }

        function checkIfDateIsInFuture() {
            var currentDate = new Date($ctrl.settings.production.currentDate);

            var maxDate = moment().startOf('day').add(1, 'days');
            // add a day for daily production
            if ($ctrl.settings.production.productionType == 0) {
                maxDate = maxDate.add(2, 'days');
            }

            var currentMomentDate = moment(currentDate, "DD-MM-YYYY");
            $ctrl.isInFuture = currentMomentDate > maxDate;
        }

        function removePrefix(prefix) {
            var idx = $ctrl.jobName.toLowerCase().indexOf(prefix.toLowerCase());
            if (idx === -1) {
                return;
            }

            $ctrl.jobName = $ctrl.jobName.substring(idx + prefix.length);
        }

        $ctrl.startOrPauseJob = function () {
            if ($ctrl.job.Status === 35 || $ctrl.job.Status === 37) {
                $ctrl.startJob();
            } else {
                $ctrl.pauseJob();
            }
        };


        $ctrl.startJob = function () {
            select();
            jobService.startJob($ctrl.job, $ctrl.quantity, handleAction);
        };

        $ctrl.pauseJob = function () {
            select();
            jobService.pauseJob($ctrl.job, handleAction);
        };

        $ctrl.setWorker = function (workerCount) {
            select();
            jobService.addWorker($ctrl.job, handleAction, workerCount);
        };

        $ctrl.showNote = function () {
            if ($ctrl.job && $ctrl.job.Note && $ctrl.job.GroupIDs && $ctrl.job.GroupIDs.length > 0) {
                return ($ctrl.job.GroupIDs.indexOf(830) >= 0 || $ctrl.job.GroupIDs.indexOf(831) >= 0 || $ctrl.job.GroupIDs.indexOf(832) >= 0);
            }
            return false;
        };

        $ctrl.checkIn = function () {
            select();
            jobService.checkIn($ctrl.job, $ctrl.quantity, function (err) {
                if (err) {
                    return dialogService.open('forceCheckinQuestion', {
                        job: $ctrl.job,
                        quantity: $ctrl.quantity,
                        errors: err
                    });
                }
            });
        };

        $ctrl.startAndCheckIn = function () {
            select();

            if ($ctrl.job.Status === 35 || $ctrl.job.Status === 37) {
                jobService.startJob($ctrl.job, $ctrl.quantity, function (err, data) {
                    handleAction(err, data);

                    if (err) {
                        return;
                    }

                    $ctrl.checkIn();
                });
            } else {
                $ctrl.checkIn();
            }
        };

        function select() {
            if (!$ctrl.isSelected) {
                $ctrl.toggleSelection();
            }
        }


        function handleAction(err, data) {
            $ctrl.saved = true;
            $timeout(function () {
                $ctrl.saved = false;
            }, 5000);

            if (err) {
                return notificationService.error(err, 8000);
            }
        }

        function refreshFreshnessInfo() {
            if ($ctrl.job)
            {
                $ctrl.Freshness = $ctrl.job.FreshnessHours + ' Std';
                if ($ctrl.job.FreshnessDays >= 1) { 
                    var freshnessHours = ($ctrl.job.FreshnessMinutes - ($ctrl.job.FreshnessDays*1440))/60;               
                    $ctrl.Freshness = $ctrl.job.FreshnessDays + ' Tag'; 
                    if ($ctrl.job.FreshnessDays >= 2) { 
                        $ctrl.Freshness = $ctrl.job.FreshnessDays + ' Tage';
                    }
                    if (freshnessHours > 0) {                            
                        $ctrl.Freshness += ', ' + freshnessHours + ' Std';
                    }
                }
            }
        }

        return $ctrl;

    }
})();