(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("ProductStockController", [
            "TabletBaseService",
            "KitchenJobService",
            "$interval",
            "TabletEventService",
            "TabletConfigService",
            ProductStockController
        ]);

    function ProductStockController(
        baseService,
        jobService,
        $interval,
        eventService,
        configService
    ) {
        var sub = null;
        var sub2 = null;
        var sub3 = null;
        var $ctrl = this;
        $ctrl.jobGroups = [];
        $ctrl.selectedJob = null;

        $ctrl.noJobs = false;
        $ctrl.loading = false;

        $ctrl.$onInit = function() {

            $ctrl.isNextDaySwitchingProgress = false;

            $interval(checkDate, 5000);

            $ctrl.settings = configService.getSettings();

            sub = eventService.subscribe(
                eventService.events.connectionStatusChanged,
                checkDate
            );
            sub2 = eventService.subscribe(
                eventService.events.resetJobs,
                loadJobs
            );
            sub3 = eventService.subscribe(
                eventService.events.jobsLoaded,
                updateJobGroupInfos
            );

            loadJobs();
        };

        $ctrl.selectCategory = function (category) {
            $ctrl.selectedCategory = category;
            loadJobs();
        }

        $ctrl.$onChanges = function() {
            updateJobGroupInfos();
        };

        $ctrl.$onDestroy = function() {
            eventService.unsubscribe(sub);
            eventService.unsubscribe(sub2);
            eventService.unsubscribe(sub3);
        };

        function checkDate() {

            if (requiresSwitchToDaily()) {
                $ctrl.isNextDaySwitchingProgress = true;
                configService.setProductionDate(baseService.getToday());
                eventService.broadcast(eventService.events.resetJobs);
                $ctrl.isNextDaySwitchingProgress = false;
            }
        }

        function requiresSwitchToDaily() {
            var currentDate = new Date($ctrl.settings.production.currentDate);
            var today = baseService.getToday();
            return currentDate < today;
        }

        function loadJobs() {
            $ctrl.loading = true;
            if ($ctrl.settings.listSettings.enableGrouping === true) {
                getJobGroups();
            } else {
            }
        }

        function getJobGroups() {
            jobService.getJobGroups(
                baseService.getToday(),
                function(err, jobGroups) {
                    if ($ctrl.jobGroups !== jobGroups) {
                        $ctrl.jobGroups = jobGroups;

                    }
                    updateJobGroupInfos();

                    $ctrl.loading = false;
                }
            );
        }

        function updateJobGroupInfos() {
            $ctrl.noJobs = true;
            var selectedCategories = $ctrl.selectedCategory ? $ctrl.selectedCategory.Groups.map(function(m) {return m.ID; }) : [];
            for (var i = 0; i < $ctrl.jobGroups.length; i++) {
                var jobGroup = $ctrl.jobGroups[i];
                jobGroup.isSelected = selectedCategories.indexOf(jobGroup.groupId) !== -1;

                if (jobGroup.jobs.length > 0) {
                    $ctrl.noJobs = false;
                }
            }
        }

        return $ctrl;
    }
})();
