(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('stockManager', {
            controller: 'StockManagerController',
            templateUrl: '/components/stock-manager/stock-manager.html',
            bindings: {
                'resolve': '<',
                'close': '&'
            }
        });
}());