(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("productionDateIndicator", {
        templateUrl: "/components/production-date-indicator/production-date-indicator.html",
        controller: "ProductionDateIndicatorController",
        bindings: {
            selectedDate: "<",
            currentMode: "<"
        }
    });
})();