(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('TabletKeypadController',
        [
            function () {
                var $ctrl = this;

                $ctrl.buttons = [];

                $ctrl.$onInit = function () {
                    if($ctrl.value === undefined)
                        $ctrl.value = '';

                    $ctrl.buttons = [
                        { key: "1", click: function () { $ctrl.add("1"); } },
                        { key: "2", click: function () { $ctrl.add("2"); } },
                        { key: "3", click: function () { $ctrl.add("3"); } },
                        { key: "4", click: function () { $ctrl.add("4"); } },
                        { key: "5", click: function () { $ctrl.add("5"); } },
                        { key: "6", click: function () { $ctrl.add("6"); } },
                        { key: "7", click: function () { $ctrl.add("7"); } },
                        { key: "8", click: function () { $ctrl.add("8"); } },
                        { key: "9", click: function () { $ctrl.add("9"); } },
                        { key: "X", click: $ctrl.clear },
                        { key: "0", click: function () { $ctrl.add("0"); } },
                        { key: "OK", click: $ctrl.done },
                    ];
                };

                $ctrl.add = function (value) {
                    if($ctrl.max && $ctrl.value.length === $ctrl.max)
                        return;

                    $ctrl.value += value;
                    if($ctrl.onChanged) 
                        $ctrl.onChanged({ value: $ctrl.value });
                };

                $ctrl.clear = function () {
                    $ctrl.value = '';
                    if($ctrl.onChanged) 
                        $ctrl.onChanged({ value: $ctrl.value });
                };

                $ctrl.done = function () {
                    if($ctrl.onDone)
                        $ctrl.onDone({ value: $ctrl.value });
                };

                return $ctrl;
            }
        ]);
})();