(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('mainMenu', {
            controller: 'TabletMainMenuController',
            templateUrl:  '/components/main-menu/main-menu.html',
            bindings: {
            }
        });
}());