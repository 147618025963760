(function() {
    "use strict";

    angular.module("laekkerAI.tablet").controller("KitchenJobGroupController", [
        "KitchenGroupService",
        "TabletConfigService",
        "TabletEventService",
        "PRODUCTION_TYPE",
        function(groupService, configService, eventService, PRODUCTION_TYPE) {
            var $ctrl = this;
            var sub = null;

            $ctrl.group = {
                imageUrl: "/images/product_default.png",
                name: "unbekannt",
                useTotal: false,
                groupType: 2
            };

            $ctrl.allowTimeMeasurement = true;

            $ctrl.$onInit = function() {
                $ctrl.group.name = $ctrl.jobGroup.name || "unbekannt";
                loadGroup();

                sub = eventService.subscribe(
                    eventService.events.resetJobs,
                    updateTotalSettings
                );
            };

            /**
             * Filter jobs from view
             * 
             * @param {*} job 
             * @returns true if this job should be shown
             */
            $ctrl.filterJobs = function (job) {
                var settings = configService.getSettings();

                var filterProductionTypeResult = (job.ProductionJobType === $ctrl.productionType);
                var filterStartedResult = false;
                var filterFinishedJobsResult = true;//we also want to show jobs which are on stock per default
                
                if(settings.listSettings.showProduced){
                    filterStartedResult = (job.Produced > 0);
                }
                if($ctrl.productionType === PRODUCTION_TYPE.PRE){
                    filterFinishedJobsResult = true;
                }
                if(settings.listSettings.showAll){
                    filterFinishedJobsResult = true;
                }
                
                return filterProductionTypeResult && (filterStartedResult || filterFinishedJobsResult);
            };

            $ctrl.$onDestroy = function() {
                if (sub) {
                    eventService.unsubscribe(
                        eventService.events.resetJobs,
                        sub
                    );
                }
            };

            $ctrl.$onChanges = function(changes) {};

            function loadGroup() {
                if (!$ctrl.jobGroup.groupId) {
                    return;
                }

                groupService.getGroupById($ctrl.jobGroup.groupId, function(
                    err,
                    group
                ) {
                    if (!group) {
                        return;
                    }
                    $ctrl.group.name = group.name;
                    $ctrl.group.imageUrl = group.imageUrl;
                    $ctrl.group.groupType = group.GroupType;
                    updateTotalSettings();
                });
            }

            function updateTotalSettings() {
                $ctrl.group.useTotal =
                    $ctrl.group.groupType === 3 ||
                    // configService.getSettings().production.productionType === PRODUCTION_TYPE.PRE ||                    
                    $ctrl.productionType  === PRODUCTION_TYPE.PRE; 

                $ctrl.allowTimeMeasurement = $ctrl.group.groupType !== 3;
            }

            return $ctrl;
        }
    ]);
})();
