(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .factory('TabletDialogService', [
            '$uibModal',
            function ($uibModal) {
                var service = this;

                /**
                 * @param {string} component the name of the component
                 * @param {json} bindings the bindings as a json object. we pass them to the dialog
                 */
                service.open = function (component, bindings) {
                    var resolver = {};

                    if (bindings) {
                        var keys = Object.keys(bindings || {});
                        for (var i = 0; i < keys.length; i++) {
                            _apply(resolver, keys[i], bindings[keys[i]]);
                        }
                    }

                    var instance = $uibModal.open({
                        component: component,
                        resolve: resolver
                    });
                    return instance;
                }

                function _apply(resolver, key, value) {
                    resolver[key] = function () { return value; };
                }

                return service;
            }
        ]);
}());
