(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("CalendarIconController", [
            "TabletBaseService",
            CalendarIconController
        ]);

    function CalendarIconController(baseService) {
        var $ctrl = this;

        $ctrl.$onInit = function() {
            $ctrl.date = new Date($ctrl.dateValue);
        };
    }
})();
