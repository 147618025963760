(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("DaySelectorController", [
            "TabletBaseService",
            "TabletEventService",
            DaySelectorController
        ]);

    function DaySelectorController(baseService, eventService) {
        var $ctrl = this;
        var sub = null;

        $ctrl.dates = [];

        $ctrl.selectedDate = null;

        $ctrl.$onInit = function() {
            buildPossibilities();
        };

        $ctrl.$onChanges = function(changes) {
            if (changes.value) {
                buildPossibilities();
            }
        };

        function buildPossibilities() {
            $ctrl.dates.length = 0;
            var val = new Date($ctrl.value);

            for (var i = 0; i < 5; i++) {
                var date = baseService.getToday(i);
                if (date.getTime() === val.getTime()) {
                    $ctrl.selectedDate = date;
                }
                $ctrl.dates.push(date);
            }
        }

        $ctrl.selectDate = function(date) {
            $ctrl.updateDate({ date: date });
            eventService.broadcast(eventService.events.dateChanged);
        };
    }
})();
