(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("searchButton", {
        templateUrl: "components/search-button/search-button.html",
        controller: "SearchButtonController",
        bindings: {
            onSearch: "&"
        }
    });
})();
