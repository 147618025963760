(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("calendarIcon", {
        templateUrl: "/components/calendar-icon/calendar-icon.html",
        controller: "CalendarIconController",
        bindings: {
            'dateValue': "<?"
        }
    });
})();
