(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("CategoryListController", [
            "KitchenGroupService",
            "TabletConfigService",
            "TabletEventService",
            "$state",
            "KitchenJobService",
            CategoryListController
        ]);

    function CategoryListController(groupService, configService, eventService, $state, jobService) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.settings = configService.getSettings();

            eventService.subscribe(eventService.events.switchedMode, onSubscribedEvent);
            eventService.subscribe(eventService.events.resetJobs, onSubscribedEvent);
            eventService.subscribe(eventService.events.updateSpecificJobs, onSubscribedEvent);
            eventService.subscribe(eventService.events.locationChanged, onSubscribedEvent);
            eventService.subscribe(eventService.events.jobsLoaded, onSubscribedEvent);

            loadData();
        };

        function onSubscribedEvent() {
            console.log('CategoryListController.onSubscribedEvent => does updateRootGroupJobCount()');
            getGroupedJobs(updateRootGroupJobCount);
        }

        function loadData() {
            console.log('CategoryListController.loadData()');
            groupService.getGroups(function (categories) {
                $ctrl.categories = categories;
                var categoryId = parseInt($state.params.categoryId);
                if (categoryId) {
                    $ctrl.categories.forEach(function (element) {
                        if (element.ID === categoryId) {
                            console.log('found' + element.ID);
                            select(element);
                        }
                    });
                }
                if (!$ctrl.selectedCategory) {
                    select($ctrl.categories[0]);
                }
                getGroupedJobs(updateRootGroupJobCount);
            });
        }

        function getGroupedJobs(callback) {
            jobService.getJobGroups(
                $ctrl.settings.production.currentDate,
                function (err, data) {
                    $ctrl.groupedJobs = data;
                    return callback( $ctrl.groupedJobs);
                }
            );
        }

        function updateRootGroupJobCount() {            
            if ($ctrl.categories && $ctrl.categories.length > 0) {
                $ctrl.categories.forEach(function (category) {
                    setPendingJobCount(category);
                });
            }
        }

        function setPendingJobCount(category) {
            if (!$ctrl.groupedJobs) {
                console.error('$ctrl.groupedJobs not loaed! Do getGroupedJobs() first');
                return;
            }
            category.pendingJobs = 0;
            for (var i = 0; i < category.Groups.length; i++) {
                var groupId = category.Groups[i].ID;
                category.pendingJobs += groupService.getJobCountForGroupFiltered(groupId, $ctrl.groupedJobs);
            }
        }

        function select(category) {
            console.log('selected category', category);
            $ctrl.selectedCategory = category;
            $ctrl.onSelect({ category: category });
        }
    }
})();