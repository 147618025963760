(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .factory("KitchenStockService", [
            "TabletApiService",
            "TabletConfigService",
            "TabletAuthService",
            KitchenStockService
        ]);

    function KitchenStockService(apiService, configService, authService) {
        var service = this;

        service.getStock = function (key, callback) {
            var location = configService.getLocation();
            if (!location) {
                return callback("location is not present");
            }
            var locationId = location.ID;

            apiService.get('stock/' + locationId + '/' + key.Type + '/' + key.ID,
                function (data) {

                    var unit = data.UnitName;
                    if (data.DisplayUnitFactor && data.DisplayUnitFactor !== 1) {
                        unit = data.DisplayUnitName;
                    }

                    if (!unit || unit.length === 0) {
                        return callback('invalid serverside data');
                    }

                    var result = {
                        balance: data.Quantity * (data.DisplayUnitFactor || 1),
                        unit: unit,
                        factor: data.DisplayUnitFactor || 1,
                    };

                    return callback(null, result);
                }, function (resp) {
                    return callback(resp);
                });
        };

        service.getStocks = function (callback) {
            var location = configService.getLocation();
            if (!location) {
                return callback("location is not present");
            }
            var locationId = location.ID;
            apiService.get(
                "location/" + locationId + "/recipes/production-informations",
                function (stocks) {
                    callback(null, stocks);
                },
                function (response) {
                    callback(response.message);
                }
            );
        };

        service.updateStock = function (key, quantity, changeType, checkPermission, callback) {

            if (checkPermission === true) {
                authService.hasPermission('STOCK_CHANGE', function (result) {
                    if (result.permission !== 'STOCK_CHANGE' || !result.granted) {
                        return callback('Sie haben nicht die Berechtigung den Lagerbestand zu ändern');
                    }
                    sendStockChange(key, quantity, changeType, callback);
                });
            }
            else {
                sendStockChange(key, quantity, changeType, callback);
            }
        };

        function sendStockChange(key, quantity, changeType, callback) {
            var location = configService.getLocation();
            if (!location) {
                return callback("location is not present");
            }
            var locationId = location.ID;

            var user = configService.getUser();
            if (!user) {
                return callback("location is not present");
            }
            var userId = user.ID;

            var requestObject = {
                LocationID: locationId,
                ObjectTypeID: key.Type,
                ObjectID: key.ID,
                Quantity: quantity,
                Date: new Date(),
                CreatedBy: userId,
                Reason: getReasonByChangeType(changeType),
                ChangeType: changeType
            };

            apiService.post('stock', requestObject, function () {
                callback();
            }, function (err) {
                callback(err);
            });
        }


        function getReasonByChangeType(changeType) {
            switch (changeType) {
                case 1: return 'Das Produkt wurde durch ein nicht näher spezifizierten Händler eingekauft.';
                case 2: return 'Der Bestand wird aufgrund Bruchs reduziert.';
                case 3: return 'Der Bestand wird aufgrund Schwunds reduziert.';
                case 11: return 'Der Bestand wird aufgrund Verderbs reduziert.';
                default: return 'Der Bestand wird aus nicht näher spezifizierten Gründen verändert';
            }
        }

        return service;
    }
})();
