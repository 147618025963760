(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("KitchenProductionSettingsController", [
            "TabletConfigService",
            "TabletEventService",
            "TabletAuthService",
            function(configService, eventService, authService) {
                var $ctrl = this;

                $ctrl.sorting = "alpha";
                $ctrl.showAllEnabled = false;

                $ctrl.$onInit = function() {
                    if (!$ctrl.settings) {
                        return;
                    }
                    if ($ctrl.settings.listSettings.sorting.indexOf("RemainingTime") !== -1)
                    {
                        $ctrl.sorting = "time";
                    }

                    $ctrl.updateSortingSettings(true);

                    /*
                    eventService.subscribe(
                        eventService.events.resetJobs,
                        $ctrl.updateSortingSettings
                    );
                    */

                   authService.hasPermission('PRODUCTION_SHOWALL', function (result) {
                        if (result.permission === 'PRODUCTION_SHOWALL' && result.granted) {
                            $ctrl.showAllEnabled = true;
                        }                        
                    });
                };

                $ctrl.updateDate = function(date) {
                    $ctrl.settings.production.currentDate = date;
                    configService.setProductionDate(date);
                    eventService.broadcast(eventService.events.resetJobs);
                };

                $ctrl.updateSortingSettings = function(doNotStoreSettings) {
                    if (
                        typeof $ctrl.sorting === "string"
                    ) {
                        $ctrl.settings.listSettings.sorting = [];
                    }
                    $ctrl.settings.listSettings.sorting.length = 0;
                    if ($ctrl.sorting === "time") {
                        $ctrl.settings.listSettings.sorting.push(
                            "RemainingTime"
                        );
                    }

                    $ctrl.settings.listSettings.sorting.push("Name");
                    if (!doNotStoreSettings) {
                        storeSettings();
                    }
                };

                function storeSettings() {
                    configService.setListSettings($ctrl.settings.listSettings);
                    eventService.broadcast(eventService.events.resetJobs);
                }
            }
        ]);
})();
