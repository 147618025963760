(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('KitchenAmountInputController', [function () {

            var $ctrl = this;

            $ctrl.$onInit = function () {
                $ctrl.showPad = false;
                $ctrl.checkinText = "einchecken";
                $ctrl.onCheckin = null;
                if (!angular.isDefined($ctrl.stepSize) || $ctrl.stepSize === null || $ctrl.stepSize === 0) {
                    $ctrl.stepSize = 10;
                }
            };

            $ctrl._checkin = function () {
                if ($ctrl.checkin === undefined) {
                    return;
                }
                $ctrl.checkin()($ctrl.current);
            };

            $ctrl.raise = function () {
                $ctrl.current += $ctrl.stepSize;
                _trySetCurrent($ctrl.current);
            };

            function _trySetCurrent(val) {
                if ($ctrl.setCurrent === undefined) {
                    return;
                }
                var exec = $ctrl.setCurrent();

                if (exec === undefined) {
                    return;
                }
                exec(val);
            }

            $ctrl.isCurrentOnly = function () {
                return !angular.isDefined($ctrl.total) || $ctrl.total === null || $ctrl.total === 0;
            };

            $ctrl.reduce = function () {
                $ctrl.current -= $ctrl.stepSize;
                _trySetCurrent($ctrl.current);
            };

            $ctrl.addNum = function (val) {
                $ctrl.current = parseInt('' + $ctrl.current + val);
            };

            $ctrl.invert = function () {
                $ctrl.current *= -1;
                _trySetCurrent($ctrl.current);
            };

            $ctrl.resetNum = function () {
                $ctrl.current = 0;
            };

            $ctrl.apply = function () {
                $ctrl.showPad = false;
                _trySetCurrent($ctrl.current);
            };

        }]);
})();