(function () {
    "use strict";

    /**
     * @ngdoc service
     * @name KitchenrecipeService
     * @description #todo
     * @requires TabletConfigService
     * @requires TabletApiService
     * @requires TabletBaseService
     **/
    var app = angular.module("laekkerAI.tablet");

    app.factory("KitchenRecipeService", [
        "TabletConfigService",
        "TabletApiService",
        "TabletBaseService",
        "KitchenInformationService",
        function (configService, apiService, baseService, informationService) {
            /* public API */
            return {
                getRecipeByKey: _getRecipeByKey,
                checkRecipeStocks: _checkRecipeStocks,
                preloadRecipes: _preloadRecipes
            };

            /**
             * @ngdoc function
             * @name _getRecipeByKey
             * @methodOf  KitchenrecipeService
             * @description todo
             **/
            function _getRecipeByKey(key, callback) {
                return informationService.getInformationByKey(key, callback);
            }

            /**
             * @ngdoc function
             * @name _checkRecipeStocks
             * @methodOf  KitchenrecipeService
             * @description todo
             **/
            function _checkRecipeStocks(recipeId, amount, callback) {
                return apiService.get(
                    "location/" +
                    configService.getLocation().ID +
                    "/recipes/" +
                    recipeId +
                    "/checkstock/" +
                    amount,
                    function (data) {
                        return callback(null, data);
                    },
                    function (response) {
                        callback(response);
                    },
                    apiService.options.withDurability(10)
                );
            }

            function _preloadRecipes() {
                if (!__appConfig.preloadRecipes) {
                    return;
                }
                _loadRecipeIds(function (err, recipeIds) {
                    if (err || !recipeIds) {
                        return;
                    }
                    var locationId = configService.getLocation().ID;
                    _asyncPreloadRecipe(locationId, 0, recipeIds);
                });
            }

            function _asyncPreloadRecipe(locationId, idx, recipeIds) {    
                if (recipeIds.length <= idx) {
                    return;
                }              
                var recipeId = recipeIds[idx];
                window.setTimeout(function () {
                    console.log("preloading recipe " + (idx + 1) + " of " + recipeIds.length);
                    _preloadRecipeFromService(locationId, recipeId);
                    _asyncPreloadRecipe(locationId, idx+1, recipeIds);
                }, 250);
            }


            function _preloadRecipeFromService(locationId, recipeId) {
                var params = {
                    LocationID: locationId,
                    Key: { Type: 0, ID: recipeId }
                };
                console.log("preloading recipe with id " + recipeId);
                informationService.getInformationByKey(params.Key, function (err) {
                    if (err) {
                        return console.error('ERROR loading recipe ' + params.Key.ID + '!');
                    }
                });
            }


            function _loadRecipeIds(callback) {
                if (!configService.getUser() || !configService.getLocation()) {
                    return callback();
                }

                return apiService.get(
                    "products/with-recipe",
                    function (recipeIds) {
                        return callback(null, recipeIds);
                    },
                    function (response) {
                        return callback(response.statusText);
                    }
                );
            }
        }
    ]);
})();
