(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('categorySelection', {
            controller: 'KitchenCategorySelectionController',
            templateUrl: '/components/category-selection/category-selection.html',
            bindings: {
                settings: '<'
            }
        });
})();