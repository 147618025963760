(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('HaccpIconController', [function () {

            var $ctrl = this;

            $ctrl.$onInit = function () {
              
            };
        }]);
})();