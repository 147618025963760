(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("ProductionDateIndicatorController", ["TabletConfigService","TabletEventService",
            ProductionDateIndicatorController
        ]);

    function ProductionDateIndicatorController(configService,eventService) {
        var $ctrl = this;

        $ctrl.$onInit = function() {

            $ctrl.checkValues();

            $ctrl.isShown = false;
            $ctrl.settings=configService.settings;
        };

        $ctrl.$onChanges = function(changes) {
            if (changes.currentMode || changes.selectedDate)  {
                $ctrl.checkValues();
            }
            if (changes.selectedDate)  {
                $ctrl.checkIfSelectedIsToday(changes.selectedDate.currentValue);
            }
        };

        $ctrl.checkIfSelectedIsToday = function(selectedDay) {
            var selectedDayDate = moment(selectedDay);
            if(moment(selectedDayDate).isSame(moment(), 'day')){
                $ctrl.isToday = true;
            } else {
                $ctrl.isToday = false;
            }
        };

        $ctrl.checkValues = function() {
            var date = moment().startOf('day').add(2, 'days');
            if($ctrl.currentMode !== 'pre' || $ctrl.selectedDate < date) {
                $ctrl.isOtherDay = false;
            }
            else {
                $ctrl.isOtherDay = true;
            }
        };


        $ctrl.showHideDateSelector = function() {
            if ($ctrl.isShown){
                $ctrl.isShown = false;
            }else{
                $ctrl.isShown = true;
            }
        };
        
        $ctrl.updateDate = function(event,date) {
            $ctrl.settings = configService.getSettings();
            $ctrl.settings.production.currentDate = date;
            
            $ctrl.selectedDate = date;
            configService.setProductionDate(date);
            eventService.broadcast(eventService.events.resetJobs);
            eventService.broadcast(eventService.events.dateChanged,date);
            $ctrl.isShown = false;
        };
        
    }
})();