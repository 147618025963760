(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('bootstrapper', {
            controller: 'TabletBootstrapperController',
            templateUrl:  '/components/bootstrapper/bootstrapper.html',
            bindings: {
                targetWhenUnauthorized: '<',
                targetWhenAuthorized: '<'
            },
            transclude: true
        });
}());