(function() {
    "use strict";

    console.info(" __        __   _                            _                   \n \\ \\      / /__| | ___ ___  _ __ ___   ___  | |_ ___             \n  \\ \\ /\\ / / _ \\ |/ __/ _ \\| '_ ` _ \\ / _ \\ | __/ _ \\            \n   \\ V  V /  __/ | (_| (_) | | | | | |  __/ | || (_) |           \n    \\_/\\_/ \\___|_|\\___\\___/|_| |_| |_|\\___|  \\__\\___/            \n    / \\__   _____  __ _| |_ ___ | |/ (_) |_ ___| |__   ___ _ __  \n   / _ \\ \\ / / _ \\/ _` | __/ _ \\| ' /| | __/ __| '_ \\ / _ \\ '_ \\ \n  / ___ \\ V /  __/ (_| | || (_) | . \\| | || (__| | | |  __/ | | |\n /_/   \\_\\_/ \\___|\\__,_|\\__\\___/|_|\\_\\_|\\__\\___|_| |_|\\___|_| |_|\n                                                                 \n");

    angular.module("laekkerAI.tablet", [
        "ui.router",
        "ui.bootstrap",
        "angularjs-gauge",
        "SignalR",
        "duScroll"
    ]);
})();
