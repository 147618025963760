(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("numberInput", {
        templateUrl: "/components/number-input/number-input.html",
        controller: "NumberInputController",
        bindings: {
            value: "<",
            min: "<?",
            max: "<?",
            valueChanged: "&"
        }
    });
})();
