(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("RefreshButtonController",
            ['KitchenJobService',
                'TabletNotificationService',
                'TabletEventService',
                RefreshButtonController]);

    function RefreshButtonController(jobService, notificationService, eventService) {
        var $ctrl = this;
        $ctrl.loading = false;

        $ctrl.updateCurrentDay = function () {
            $ctrl.loading = true;
            notificationService.info('Aktualisiere Tablet-Zahlen');
            jobService.updateJobs([], function () {
                eventService.broadcast(eventService.events.resetJobs);
                notificationService.success('Update abgeschlossen');                
                $ctrl.loading = false;
            });
        };

        return $ctrl;
    }
})();
