(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('stockItem', {
            controller: 'StockItemController',
            templateUrl:  '/components/stock-item/stock-item.html',
            bindings: {
                value: '<',
                onCompleted: '&',
                isSelected: '<'
            }
        });
}());