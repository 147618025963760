(function() {
    "use strict";

    angular.module("laekkerAI.tablet").controller("KitchenJobTakerController", [
        "KitchenJobService",
        "TabletBaseService",
        "TabletDialogService",
        function(jobService, baseService, dialogService) {
            var $ctrl = this;

            $ctrl.formattedOwnerName = "";

            $ctrl.$onInit = function() {
                updateOwnerName();
            };

            $ctrl.$onChanges = function(changes) {
                if (changes.ownerName) {
                    updateOwnerName();
                }
            };

            $ctrl.initiateTakeover = function() {
                dialogService.open("takeJobQuestion", {
                    jobName: $ctrl.job.Name,
                    ownerName: $ctrl.formattedOwnerName,
                    job: $ctrl.job
                });
            };

            function updateOwnerName() {
                $ctrl.formattedOwnerName = "";
                if ($ctrl.job.WorkerName) {
                    $ctrl.formattedOwnerName = baseService.formatName(
                        $ctrl.ownerName
                    );
                }
            }
        }
    ]);
})();
