(function() {
    "use strict";
    angular.module("laekkerAI.tablet").component("productStockItem", {
        controller: "ProductStockItemController",
        templateUrl: "/components/product-stock-item/product-stock-item.html",
        bindings: {
            job: "<",
            useTotal: "<",
            toggleSelection: "&",
            isSelected: "<",
            showProduced: "<",
            allowTimeMeasurement: "<"
        }
    });
})();
