(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('recipe', {
            controller: 'KitchenRecipeController',
            templateUrl: '/components/recipe/recipe.html',
            bindings: {
                showRecipe: '<',
                quantity: '<',
                changeQuantity: '&',
                recipeKey: '<'
            }
        });
})();