(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller('MyCityController', [
            "TabletConfigService",
            MyCityCtrl
        ]);

    function MyCityCtrl(
        configService
    ) {
        var $ctrl = this;
        $ctrl.statistics = [];
        $ctrl.statisticsRows = [];

        $ctrl.$onInit = function () {
            console.log('PersonalPlanningController on init');
            $ctrl.settings = configService.getSettings();
            $ctrl.loadReports();
        };

        $ctrl.loadReports = function () {

            $ctrl.statisticsRows.length = 0;

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: 85.0,
                maxValue: 115.0,
                label: 'Produktivitaet',
                description: 'Umsatz pro MA-Stunde, Gemessen wird die Abweichung vom Zielwert in %. (Gelb unter 103% / Rot unter 99%)',
                textValue: '',
                unit: '%',
                thresholdConfig: {
                    '85.0': { color: 'red' },
                    '99.0': { color: 'orange' },
                    '103.0': { color: 'green' },
                }
            });

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: -24,
                maxValue: 0,
                label: 'Vollsperrung',
                description: 'Anzahl der Vollsperrungen in den letzten x Tagen. (Gelb: ab 8 / Rot: ab 12)',
                textValue: '',
                unit: '',
                thresholdConfig: {
                    '-24': { color: 'red' },
                    '-12': { color: 'orange' },
                    '-8': { color: 'green' },
                }
            });

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: -3,
                maxValue: 0,
                label: 'Breaker',
                // tslint:disable-next-line:max-line-length
                description: 'Durchschn. Anzahl der MA mit Schichten > 10 Stunden pro Mitarbeiter in den letzten x Tagen. (Gelb: ab 1 / Rot: ab 1,5)',
                textValue: '',
                unit: '',
                thresholdConfig: {
                    '-3': { color: 'red' },
                    '-1.5': { color: 'orange' },
                    '-1.0': { color: 'green' },
                }
            });

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: 90,
                maxValue: 100,
                label: 'Puenktlichkeit',
                description: 'Anteil der puenktlichen Lieferungen in den letzten x Tagen. (Gelb: unter 98% / Rot: unter 95%)',
                textValue: '',
                unit: '%',
                thresholdConfig: {
                    '90': { color: 'red' },
                    '95': { color: 'orange' },
                    '98': { color: 'green' },
                }
            });


            $ctrl.statisticsRows.push({
                value: 0,
                minValue: 3.9,
                maxValue: 4.7,
                label: 'FUP Note',
                description: 'Kunden-Feedback-Note aus den Bewertungen der letzten x Tage. (Gelb: unter 4,4 / Rot: unter 4,2)',
                textValue: '',
                unit: '',
                thresholdConfig: {
                    '3.9': { color: 'red' },
                    '4.2': { color: 'orange' },
                    '4.4': { color: 'green' },
                }
            });

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: -10,
                maxValue: 0,
                label: 'Teilsperrung',
                description: 'Anteil der Teilsperrungen in den letzten x Tagen (ohne Vollsperrungen). (Gelb: ab 3,75% / Rot: alles ab 5%)',
                textValue: '',
                unit: '%',
                thresholdConfig: {
                    '-10': { color: 'red' },
                    '-5.0': { color: 'orange' },
                    '-3.75': { color: 'green' },
                }
            });

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: -18,
                maxValue: 0,
                label: 'Krankheit',
                description: 'Die durchschn. Anzahl der Krank-Tage pro Mitarbeiter in den letzten x Tagen. (Gelb ab 6,5 / Rot: ab 9)',
                textValue: '',
                unit: '',
                thresholdConfig: {
                    '-18': { color: 'red' },
                    '-9.0': { color: 'orange' },
                    '-6.5': { color: 'green' },
                }
            });

            $ctrl.statisticsRows.push({
                value: 0,
                minValue: 90,
                maxValue: 100,
                label: 'Vollstaendigkeit',
                description: 'Anteil der vollstaendigen Lieferungen in den letzten x Tagen. (Gelb: unter 98% / Rot: unter 95%)',
                textValue: '',
                unit: '%',
                thresholdConfig: {
                    '90': { color: 'red' },
                    '95': { color: 'orange' },
                    '98': { color: 'green' },
                }
            });

            //set fake data
            $ctrl.statistics = $ctrl.fakedata;

            $ctrl.setGaugeValues();
        };



        $ctrl.setGaugeValues = function () {
            $ctrl.statisticsRows[0].value = ($ctrl.isYearStats ? $ctrl.statistics.Efficiency365Perc : $ctrl.statistics.Efficiency30Perc);
            $ctrl.statisticsRows[1].value = ($ctrl.isYearStats ? $ctrl.statistics.Blocked365 : $ctrl.statistics.Blocked90) * -1;
            $ctrl.statisticsRows[2].value = ($ctrl.isYearStats ? ($ctrl.statistics.Breaker365 / $ctrl.statistics.Workdays365) : ($ctrl.statistics.Breaker90 / $ctrl.statistics.Workdays90)) * -1;
            $ctrl.statisticsRows[3].value = ($ctrl.isYearStats ? $ctrl.statistics.IntimePerc365 : $ctrl.statistics.IntimePerc90);
            $ctrl.statisticsRows[4].value = ($ctrl.isYearStats ? $ctrl.statistics.Fup365 : $ctrl.statistics.Fup90);
            $ctrl.statisticsRows[5].value = ($ctrl.isYearStats ? $ctrl.statistics.PartBlockedPerc365 : $ctrl.statistics.PartBlockedPerc90) * -1;
            $ctrl.statisticsRows[6].value = ($ctrl.isYearStats ? $ctrl.statistics.SickDaysPerTeam365 : $ctrl.statistics.SickDaysPerTeam90) * -1;
            $ctrl.statisticsRows[7].value = ($ctrl.isYearStats ? $ctrl.statistics.CompletePerc365 : $ctrl.statistics.CompletePerc90);
        };


        $ctrl.fakedata =
        {
            LocationID: 1,
            LocationName: "Berlin",
            Efficiency30: 60.8,
            Efficiency30Perc: 99.7,
            EfficiencyNote: "schlecht",
            Efficiency365: 61.9,
            Efficiency365Perc: 89.9,
            Late365: 4,
            LatePerc365: 0.3,
            Late90: 2,
            LatePerc90: 0.5,
            InComplete365: 1,
            InCompletePerc365: 0.1,
            InComplete90: 1,
            InCompletePerc90: 0.3,
            Fup90: 4.8,
            FupPerc90: 120.6,
            FupNote90: "excellent",
            Fup365: 4.8,
            FupPerc365: 120.1,
            FupNote365: "excellent",
            ServiceFup90: 4.8,
            ProductionFup90: 4.8,
            Breaker30: 25,
            Breaker90: 112,
            Breaker365: 480,
            EmplCount30: 16,
            EmplCount90: 16,
            EmplCount365: 16,
            Blocked365: 22,
            Blocked90: 3,
            Workdays90: 70,
            Workdays365: 300,
            PartBlocked365: 1,
            PartBlockedPerc365: 0.3,
            PartBlocked90: 1,
            PartBlockedPerc90: 1.3,
            IntimePerc365: 99.6,
            IntimePerc90: 99.4,
            CompletePerc365: 99.9,
            CompletePerc90: 99.7,
            AvgEmpHours: 12.3,
            MaxEmpOverHours: 18.5,
            MaxDiffEmployee: "Mustermann, Max",
            SickDaysPerTeam365: 10.3,
            SickDaysPerTeam90: 3.8
        };
    }

})();
