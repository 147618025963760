(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("goodsReceivingOrder", {
        templateUrl:
            "/components/goods-receiving-order/goods-receiving-order.html",
        controller: "GoodsReceivingOrderController",
        bindings: {
            orderId: "<"
        }
    });
})();
