(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('hiddenMenuDialog', {
            controller: 'HiddenMenuDialogController',
            templateUrl: '/components/hidden-menu-dialog/hidden-menu-dialog.html',
            bindings: {
                'resolve': '<',
                'close': '&'
            }
        });
})();