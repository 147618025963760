(function () {
    'use strict';
    var app = angular.module('laekkerAI.tablet');

    app.controller(
        'TabletTabletHeaderController',
        [
            '$state',
            'TabletConfigService',
            '$uibModal',
            'TabletHomeService',
            'TabletDialogService',
            function ($state, configService, $uibModal, homeService, dialogService) {
                var $ctrl = this;

                $ctrl.goHome = function () {
                    homeService.goHome();
                };

                $ctrl.openMenu = function () {
                    dialogService.open('hiddenMenuDialog', {});
                }

                /**
                 * @ngdoc function
                 * @name resetStation
                 * @methodOf Kitchencontroller:kitchenController
                 * @description todo
                **/
                $ctrl.resetStation = function () {
                    configService.resetStation();
                    document.location.reload(true);
                };

                $ctrl.reportBug = function () {
                    $uibModal.open({
                        template: '<bug-report on-done="closeModal()"></bug-report>',
                    });
                };

                $ctrl.gotoLogin = function () {
                    document.location.href = '/';
                }

                return $ctrl;
            }
        ]);
}());