(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("GoodsReceivingOrderController", [
            "GoodsReceivingService",
            GoodsReceivingOrderController
        ]);

    function GoodsReceivingOrderController(goodsService) {
        var $ctrl = this;
        $ctrl.allSet = false;

        $ctrl.$onInit = function() {
            goodsService.getSupplierOrder($ctrl.orderId, function(
                err,
                supplierOrder
            ) {
                $ctrl.supplierOrder = supplierOrder;
                for (var i = 0; i < $ctrl.supplierOrder.Items.length; i++) {
                    if ($ctrl.supplierOrder.Items[i].ActualQuantity !== 0) {
                        $ctrl.supplierOrder.Items[i].done = true;
                    }
                }
                updateAllSet();
            });
        };

        $ctrl.updateDeliveredQuantity = function(item, actualQuantity) {
            item.ActualQuantity = actualQuantity;
            // $ctrl.markDone(item);
        };

        $ctrl.markDone = function(item) {
            item.done = true;
            updateAllSet();
        };

        function updateAllSet() {
            for (var i = 0; i < $ctrl.supplierOrder.Items.length; i++) {
                if (!$ctrl.supplierOrder.Items[i].done) {
                    $ctrl.allDone = false;
                    return;
                }
            }

            $ctrl.allDone = true;
        }
    }
})();
