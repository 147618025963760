(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("plannedJobGroup", {
        templateUrl: "/components/planned-job-group/planned-job-group.html",
        controller: "PlannedJobGroupController",
        bindings: {
            groupId: "<",
            isSelected: "<",
            jobs: "<",
            toggleSelection: "&",
            selectedJob: "<",
            moveNext: "&"
        }
    });
})();
