(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("backButton", {
        templateUrl: "components/back-button/back-button.html",
        controller: "BackButtonController",
        bindings: {
            state: "@",
            params: "<?",
            onClick: "&?"
        }
    });
})();
