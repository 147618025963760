(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("plannedProductionJob", {
        templateUrl:
            "components/planned-production-job/planned-production-job.html",
        controller: "PlannedProductionJobController",
        bindings: {}
    });
})();
