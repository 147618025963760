(function () {
    "use strict";

    /**
     * @ngdoc service
     * @name KitchengroupService
     * @description #todo
    **/
    var app = angular.module("laekkerAI.tablet");

    app.factory("KitchenGroupService", [
        "TabletApiService",
        "TabletConfigService",
        function (apiService, configService) {
            var cache = {
                availableGroups: null,
                selectedCategories: null
            };

            var service = {
                getGroups: getGroups,
                getChildGroups: _getChildGroups,
                preloadGroups: preloadGroups
            };
            service.preloadInitiated = false;

            function preloadGroups() {
                asyncPreload();
            }

            function asyncPreload() {
                window.setTimeout(function () {
                    return apiService.get(
                        "grouplist",
                        fillGroupNameAndImage,
                        function (response) { },
                        apiService.options.DAY
                    );
                });
            }

            function fillGroupNameAndImage(groups) {
                if (groups !== null && groups !== undefined && groups.length > 0) {
                    for (var i = 0; i < groups.length; i++) {
                        var s = groups[i];
                        if (s.Name && s.Name.Contents && s.Name.Contents.length > 0) {
                            s.name = s.Name.Contents[0].Value;
                        } else {
                            console.error('Error: group has no name property to read! OBJECT: ' + JSON.stringify(s));
                            s.Name = '[Datenfehler]';
                        }
                        s.imageUrl = s.imagePath = "/images/category_" + s.ID + ".jpg";
                    }
                }
            }


            service.getGroupById = function (groupId, callback) {
                return _getGroupByIdFromGroupCache(groupId, callback, _getGroupByIdFromDb)
                    .then(function () {
                        console.log('Success: getGroupByIdFromGroupCache');
                    },
                        function (error) {
                            _getGroupByIdFromDb(groupId, callback); //error fallback
                        }
                    );
            };

            function _getGroupByIdFromGroupCache(groupId, callback, fallbackFunc) {
                return apiService.get(
                    "grouplist",
                    function (groups) {
                        if (groups !== null && groups !== undefined && groups.length > 0) {
                            try {                               
                                fillGroupNameAndImage (groups);
                                var filteredGroups = groups.filter(function (g) {
                                    return g.ID == groupId;
                                });
                                var group = filteredGroups.pop();
                                callback(null, group);
                            } catch (e) {
                                console.error('Error: _getGroupByIdFromGroupCache => call fallbackFunc');
                                fallbackFunc(groupId, callback); //error fallback 
                            }
                        } else {
                            console.warn('EMPTY _getGroupByIdFromGroupCache => call fallbackFunc');
                            fallbackFunc(groupId, callback); //error fallback   
                        }
                    },
                    function (response) { },
                    apiService.options.DAY
                );
            }

            function _getGroupByIdFromDb(groupId, callback) {
                return apiService.get(
                    "groups/" + groupId,
                    function (groups) {
                        var group = groups.pop();
                        group.name = group.Name.Contents[0].Value;
                        group.imageUrl = group.imagePath = "/images/category_" +  groupId + ".jpg";
                        callback(null, group);
                    },
                    function (response) {
                        callback(response);
                    },
                    apiService.options.DAY
                );
            }


            function _getChildGroups(groupId, callback) {
                return _getChildGroupsFromGroupCache(groupId, callback, _getChildGroupsFromDb)
                    .then(function () {
                        console.log('Success: getChildGroupsFromGroupCache');
                    },
                        function (error) {
                            console.error('Error: getChildGroupsFromGroupCache => call fallback _getChildGroupsFromDb');
                            _getChildGroupsFromDb(groupId, callback); //error fallback
                        });
            }

            function _getChildGroupsFromGroupCache(groupId, callback, fallbackFunc) {
                return apiService.get(
                    "grouplist",
                    function (groups) {
                        if (groups !== null && groups !== undefined && groups.length > 0) {
                            try {                                 
                                fillGroupNameAndImage (groups);
                            } catch (e) {
                                console.error('Error: _getChildGroupsFromGroupCache => call fallbackFunc');
                                fallbackFunc(groupId, callback); //error fallback   
                            }
                            callback(_filterChildGroups(groups, groupId));
                        } else {
                            console.warn('EMPTY _getChildGroupsFromGroupCache => call fallbackFunc');
                            fallbackFunc(groupId, callback); //error fallback         
                        }
                    },
                    function (response) { },
                    apiService.options.DAY
                );
            }

            function _filterChildGroups(groups, parendId) {
                var filteredGroups = groups.filter(function (g) {
                    var matchedParents = g.Parents.filter(function (pid) {
                        return pid == parendId;
                    });
                    return matchedParents.length > 0;
                });
                return filteredGroups;
            }

            function _getChildGroupsFromDb(groupId, callback) {
                return apiService.get(
                    "groups/children/" + groupId,
                    function (childGroups) {                         
                        fillGroupNameAndImage (childGroups);
                        callback(childGroups);
                    },
                    function (response) { },
                    apiService.options.DAY
                );
            }

            function getGroups(callback) {
                apiService.get(
                    "productionstations/productiongroups",
                    function (groups) {
                        callback(groups);
                    },
                    function (response) { },
                    apiService.options.DAY
                );
            }

            /**
             * @description get "remaining" of jobs in group
             * @param {int} groupID 
             * @param {array} groupsWithJobs
             */
            service.getJobCountForGroup = function (groupID, groupsWithJobs) {
                if (groupsWithJobs == null) {
                    return 0;
                }
                var filteredJobs = groupsWithJobs.filter(function (group) {
                    return group.groupId == groupID;
                });
                if (filteredJobs.length == 0) {
                    return 0;
                }
                var jobsInGroup = filteredJobs[0].jobs;
                var count = 0;
                count = jobsInGroup.filter(function (job) {
                    return job.Remaining > 0;
                }).length;
                return count;
            };

            /**
             * @description get "remaining" of jobs in group for actual productionType
             * @param int groupID 
             * @param {int} groupsWithJobs
             * @returns {int}
             */
            service.getJobCountForGroupFiltered = function (groupID, groupsWithJobs) {
                if (groupsWithJobs == null) {
                    return 0;
                }
                var filteredJobs = groupsWithJobs.filter(function (group) {
                    return group.groupId == groupID;
                });
                if (filteredJobs.length == 0) {
                    return 0;
                }
                var jobsInGroup = filteredJobs[0].jobs;
                var count = 0;
                count = jobsInGroup.filter(function (job) {
                    return job.Remaining > 0 && job.ProductionJobType === configService.getSettings().production.productionType;
                }).length;
                return count;
            };

            return service;
        }
    ]);
})();
