(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .component('daySelector', {
                templateUrl: 'components/day-selector/day-selector.html',
                controller: 'DaySelectorController',
                bindings: {
                    updateDate: '&',
                    value: '<'
                 }
            });
}());