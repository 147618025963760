(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('category', {
            controller: 'KitchenCategoryController',
            templateUrl: '/components/category/category.html',
            bindings: {
                'categoryId': '<',
                'settings': '<'
            }
        });
})();