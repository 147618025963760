(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('gauge', {
            controller: 'GaugeController',
            templateUrl:  '/components/gauge/gauge.html',
            bindings: {
                size: '@',
                value: '@',
                label: '@',
                description: '@',
                textValue: '@',
                prepend: '@',
                unitName: '@',
                minValue: '@',
                maxValue: '@',
                thresholds: '@'
            },
            transclude: true
        });
}());