(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("KitchenProductionController", [
            "TabletBaseService",
            "KitchenJobService",
            "KitchenGroupService",
            "$interval",
            "$document",
            "$timeout",
            "TabletHomeService",
            "TabletEventService",
            "TabletConfigService",
            "TabletDialogService",
            "$state",
            "PRODUCTION_TYPE",
            ProductionController
        ])
        .value("duScrollDuration", 250)
        .value("duScrollOffset", 131)
        .run([
            "$anchorScroll",
            function($anchorScroll) {
                $anchorScroll.yOffset = 148;
            }
        ]);

    function ProductionController(
        baseService,
        jobService,
        groupService,
        $interval,
        $document,
        $timeout,
        homeService,
        eventService,
        configService,
        dialogService,
        $state,
        PRODUCTION_TYPE
    ) {
        var sub = null;
        var sub2 = null;
        var sub3 = null;
        var $ctrl = this;
        $ctrl.jobGroups = [];
        $ctrl.selectedJob = null;

        var PRODUCTION_MODE_NAME = {DAILY: "Tagesproduktion", PRE:"Vorproduktion"};
        
        $ctrl.productionType = PRODUCTION_TYPE.DAILY;
        $ctrl.productionTypeName = PRODUCTION_MODE_NAME.DAILY;

        $ctrl.noJobs = false;
        $ctrl.loading = false;
        $ctrl.isToday = true;

        $ctrl.myJobs = {
            isSelected: true,
            groupId: 0,
            name: "Meine Aufgaben",
            jobs: []
        };

        $ctrl.sorting = ["RemainingTime", "Name"];

        $ctrl.$onInit = function() {

            $ctrl.categoryId = $state.params.categoryId;

            $ctrl.isNextDaySwitchingProgress = false;

            $interval(checkDate, 5000);

            $ctrl.settings = configService.getSettings();
            setProductionType();

            $ctrl.isPreProduction=false;
            if ($ctrl.settings.production.productionType == PRODUCTION_TYPE.PRE) {
                $ctrl.isPreProduction = true;
            }

            sub = eventService.subscribe(
                eventService.events.connectionStatusChanged,
                checkDate
            );
            sub2 = eventService.subscribe(
                eventService.events.resetJobs,
                loadJobs
            );
            sub3 = eventService.subscribe(
                eventService.events.jobsLoaded,
                updateJobGroupInfos
            );

            $ctrl.unsetSelection($ctrl.selectedJob);
            loadJobs();
            checkIfDateIsInFuture();
        };

        function checkIfDateIsInFuture() {
            var currentDate = new Date($ctrl.settings.production.currentDate);
            var today = baseService.getToday();
            $ctrl.isToday = (currentDate <= today);
        }

        $ctrl.$onChanges = function() {
            updateJobGroupInfos();
        };

        function setProductionType() {
            $ctrl.productionTypeName = PRODUCTION_MODE_NAME.DAILY;
            $ctrl.productionType = $ctrl.settings.production.productionType;
            if ($ctrl.settings.production.productionType == PRODUCTION_TYPE.PRE) {
                $ctrl.productionTypeName = PRODUCTION_MODE_NAME.PRE;
            }
        }

        $ctrl.$onDestroy = function() {
            eventService.unsubscribe(sub);
            eventService.unsubscribe(sub2);
            eventService.unsubscribe(sub3);
        };

        function checkDate() {
            checkIfDateIsInFuture();
            if (requiresSwitchToDaily()) {
                console.warn("dateChange requires job update");
                //console.time('TimerCheckDate');
                $ctrl.isNextDaySwitchingProgress = true;
                configService.setProductionDate(baseService.getToday());
                eventService.broadcast(eventService.events.resetJobs);
                //console.warn("after dateChange requires job update");
                //console.timeEnd('TimerCheckDate');
                //console.timeStamp('TimerCheckDate');
                $ctrl.isNextDaySwitchingProgress = false;
            }
        }

        function requiresSwitchToDaily() {
            var currentDate = new Date($ctrl.settings.production.currentDate);
            var today = baseService.getToday();

            return currentDate < today;
        }

        function loadJobs() {
            $ctrl.loading = true;
            if ($ctrl.settings.listSettings.enableGrouping === true) {
                getJobGroups();
            } else {
            }
        }

        $ctrl.loadJobs = loadJobs;

        function getJobGroups() {
            var selectedKey = $ctrl.selectedJob ? $ctrl.selectedJob.Key : configService.getLastSelectedJob();
            jobService.getJobGroups(
                $ctrl.settings.production.currentDate,
                function(err, jobGroups) {
                    if ($ctrl.jobGroups !== jobGroups) {
                        $ctrl.jobGroups = jobGroups;

                        var job = findByKey(selectedKey);

                        if(job) {
                            $ctrl.toggleSelection(job);
                        }

                    }
                    updateJobGroupInfos();

                    $ctrl.loading = false;
                    setProductionType();
                }
            );
        }

        function findByKey(key) {
            if(!key) {
                return null;
            }
            var result = []
                .concat
                .apply([], $ctrl.jobGroups.map(function (jg) { return jg.jobs; }))
                .filter(function(job) { return job.Key.ID == key.ID && job.Key.Type == key.Type; });

            if(result.length === 0) {
                return null;
            }

            return result[0];
        }

        function updateJobGroupInfos() {
            $ctrl.noJobs = true;
            $ctrl.myJobs.jobs.length = 0;
            for (var i = 0; i < $ctrl.jobGroups.length; i++) {
                var jobGroup = $ctrl.jobGroups[i];
                jobGroup.isSelected = isSelected(jobGroup);

                if (jobGroup.jobs.length > 0) {
                    $ctrl.noJobs = false;
                }

                for (var j = 0; j < jobGroup.jobs.length; j++) {
                    var job = jobGroup.jobs[j];
                    if (job.IsOwner && job.WorkerName) {
                        $ctrl.myJobs.jobs.push(job);
                    }
                }
            }
            if($ctrl.autoScrollingFixed === true){
                $ctrl.setSelection($ctrl.selectedJob, true);
            }
        }

        function isSelected(jobGroup) {
            return (
                $ctrl.settings.selectedCategories.indexOf(jobGroup.groupId) !== -1
            );
        }

        $ctrl.toggleSelection = function(job) {
            if ($ctrl.selectedJob === job) {
                $ctrl.unsetSelection(job);                
            } else {
                configService.setLastSelectedJob(job.Key);
                $ctrl.selectedJob = job;
                if (!$ctrl.selectedJob) {
                    return;
                }
                $ctrl.setSelection(job);                
            }
        };

        $ctrl.setSelection = function(job, noAnimation) {
            $timeout(function() {
                var element = angular.element(
                    document.getElementById("job_" + job.Key.ID + "_" + job.ProductionJobType)
                );
                if(element.length > 0){
                    //console.log(element, "job_" + job.Key.ID + "_" + job.ProductionJobType);
                    if(noAnimation != undefined && noAnimation === true){
                        $document.scrollToElement(element);    
                    }else{
                        $document.scrollToElementAnimated(element);
                    }
      
                    $ctrl.autoScrollingFixed = true;
                    $document.on('scroll', function() {
                        //console.log('Document scrolled to ', $document.scrollLeft(), $document.scrollTop());
                        $ctrl.autoScrollingFixed = false;
                    });
                }else{
                    //console.warn("scrolling to element was impossible");
                }
            }, 500);
        };

        $ctrl.unsetSelection = function(job) {
            $ctrl.selectedJob = null;
            $ctrl.autoScrollingFixed = false;
            configService.setLastSelectedJob(null);
        };

        return $ctrl;
    }
})();
