(function () {
    'use strict';
    var app = angular.module('laekkerAI.tablet');

    app.controller(
        'TabletImageTileController',
        [
            function () {
                var $ctrl = this;
                
                return $ctrl;
            }
        ]);
}());