(function($, angular) {
    "use strict";
    angular.module("laekkerAI.tablet").factory("LoadingInterceptor", [
        "$q",
        "$injector",
        function($q, $injector) {
            var open = 0;
            var done = 0;

            function complete() {
                done++;
                // if (open === done) {
                //     $("connection-indicator").removeClass("loading");
                // }
                window.setTimeout(function() {
                    if (open === done) {
                        $("connection-indicator").removeClass("loading");
                    }
                }, 200);
            }

            function start() {
                open++;

                window.setTimeout(function() {
                    if (open !== done) {
                        $("connection-indicator").addClass("loading");
                    }
                }, 200);
            }

            var service = this;

            service.response = function(response) {
                complete();
                return $q.when(response);
            };

            service.responseError = function(response) {
                complete();
                return $q.reject(response);
            };

            service.request = function(config) {
                start();
                return config || $q.when(config);
            };

            return service;
        }
    ]);
})(window.jQuery, window.angular);
