(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('personalPlanning', {
            controller: 'PersonalPlanningController',
            templateUrl: '/components/personal-planning/personal-planning.html',
            bindings: {
            }
        });
})();