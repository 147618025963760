(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .factory('TabletHomeService', [
           '$state',
           '$location',
           function ($state, $location) {

               var service = this;
               var home = null;

               service.goHome = function() {
                   if(!home) {
                       document.location.href = '/';
                       return;
                   }
                   $state.go(home);
               };

               service.setHome = function (newHome) {
                   home = newHome;
               };

               return service;
           }
        ]);
}());