(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name Tabletcontroller:userSelectionController
     * @description
     * @requires TabletConfigService
     * @requires TabletAuthService
    **/
    var app = angular.module('laekkerAI.tablet');

    app.controller('TabletAuthUserController', ['$state', 'TabletAuthService', function ($state, AuthService) {

        var $ctrl = this;
        $ctrl.users = [];

        $ctrl.$onInit = function () {
            if(!$ctrl.location){
                return $ctrl.back();}
            AuthService.getUsers($ctrl.location.ID, function (users) {
                $ctrl.users = users;
            });
        };
        
        $ctrl.selectUser = function (user) {
            $state.go('auth.pin', { 
                location: $ctrl.location, 
                user: user 
            });
        };

        $ctrl.back = function () {
            $state.go('auth.location');
        };
    }]);

}());