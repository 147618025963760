(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('TabletBugReportController', [
            'TabletBugService',
            'TabletConfigService',
            '$rootScope',
            '$uibModalStack',
            'TabletNotificationService',
            'ProductService',
            function (BugService, ConfigService, $rootScope, $modalStack, notificationService, productService) {

                var $ctrl = this;

                $ctrl.components = [];

                $ctrl.selectedComponent = null;
                $ctrl.selectedType = null;

                $ctrl.description = '';

                $ctrl.suggestedProducts = [];

                $ctrl.$onInit = function () {
                    if ($ctrl.resolve) {
                        angular.extend($ctrl, $ctrl.resolve);
                    }

                    if($ctrl.job) {
                        $ctrl.expectedTotal = $ctrl.job.Required;
                        $ctrl.expectedStock = $ctrl.job.Stock;
                    }
                    
                };

                function enhanceDescription() {
                    if(!$ctrl.job) {
                        return;
                    }
                    var enhancedDescription = 
                      '\r\n\r\nSysteminformationen\r\n' +
                      '===================\r\n' +
                      'Product ID: ' + $ctrl.job.Key.ID + '\r\n' +
                      'Produkt: ' + $ctrl.job.Name + '\r\n' + 
                      'Benötigt: ' + $ctrl.job.Required + '\r\n' + 
                      'Im Lager: ' + $ctrl.job.Stock + '\r\n' + 
                      'Verbleibend: ' + $ctrl.job.Remaining + '\r\n' + 
                      'Produziert:' + $ctrl.job.Produced + '\r\n' +
                      '-------------------\r\n';

                      enhancedDescription += 'Erwarteter Lagerbestand: ' + $ctrl.expectedStock + '\r\n';
                      enhancedDescription += 'Erwartete Produktionsmenge: ' + $ctrl.expectedTotal + '\r\n';
                      if($ctrl.missingProductName) {
                        enhancedDescription += 'Fehlendes Produkt: ' + $ctrl.missingProductName + '\r\n';
                      }

                      enhancedDescription +='-------------------\r\n';
                      
                      enhancedDescription +=
                      'Stundenfenster (R = Required, D = Done O = Open)\r\n' +
                      '==============\r\n';
  
                      $ctrl.job.Tasks.forEach(function(task) {
                          enhancedDescription += task.Hour + ' Uhr (R: ' + task.Required + ' | D: ' + task.Done + ' | O: ' + task.Remaining + ')\r\n';
                      });

                    $ctrl.description += '\r\n\r\n' + enhancedDescription;
                }

                $ctrl.suggestProducts = function ()  {
                    productService.findProducts($ctrl.missingProductName, 6, function (err, prods) {
                        $ctrl.suggestedProducts = prods || [];
                    });
                };

                $ctrl.selectSuggestion = function (product) {
                    $ctrl.missingProductName = product.Name;
                    $ctrl.missingProductId = product.ID;
                    $ctrl.suggestedProducts = [];
                };

                $ctrl.send = function () {
                    var infoRemoval = notificationService.info('Der Fehlerbericht wird hochgeladen', 0);

                    enhanceDescription();

                    var product = 'allgemein';

                    if($ctrl.missingProductName && $ctrl.missingProductName.length > 0) {
                        product = $ctrl.missingProductName;
                        if($ctrl.missingProductId) {
                            product += '- ID: ' + $ctrl.missingProductId;
                        }
                    } else if($ctrl.job) {
                        product = $ctrl.job.Name;
                    }

                    BugService.createBugReport($ctrl.selectedComponent.title, $ctrl.selectedType.title, $ctrl.description, product, function () {
                        $modalStack.dismissAll();
                        infoRemoval();
                        if ($ctrl.onDone) {
                            $ctrl.onDone();
                        }
                    }).then(function () {
                        notificationService.success('Der Fehlerbericht wurde hochgeladen.');
                    }, function () {
                        notificationService.error('Der Fehlerbericht konnte NICHT hochgeladen werden.');
                    });
                };

                BugService.getComponents(function (components) {
                    $ctrl.components = components;
                    $ctrl.selectedComponent = $ctrl.components[0];
                    $ctrl.selectedType = $ctrl.components[0].types[0];
                });
            }]);
}());