(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('HiddenMenuDialogController', [
            'TabletConfigService',
            'TabletDialogService',
            function (configService, dialogService) {

            var $ctrl = this;

            $ctrl.$onInit = function () {
                if($ctrl.resolve !== undefined) {
                    angular.extend($ctrl, $ctrl.resolve);
                }
            };

            $ctrl.resetStation = function () {
                configService.resetStation();
                document.location.reload(true);
            };

            $ctrl.reportBug = function () {
                dialogService.open('bugReport', {});
                $ctrl.close();
            };

        }]);
})();