(function () {
    'use strict';

    var app = angular.module('laekkerAI.tablet');
    
    /**
      * @ngdoc service
      * @name TabletLogService
      * @description deprecated <strong>wird nicht mehr verwendet</strong>
      * # TicketService 
     **/
    app.factory('TabletLogService', [function () {

        var subs = [
          function (logMessage) { console.log(logMessage); }
        ];

        return {
            subscribe: function (callback) { subs.push(callback); },
            debug: function (msg) { _log('DEBUG', msg); },
            warn: function (msg) { _log('WARN', msg); },
            info: function (msg) { _log('INFO', msg); },
            error: function (msg) { _log('ERROR', msg); },
        };

        function _log(level, msg) {
            var dt = new Date();
            var builtMsg = '[' + level + '] ' + dt.toISOString() + ': ' + msg;

            angular.forEach(subs, function (sub) {
                sub(builtMsg);
            });
        }

    }]);
})();