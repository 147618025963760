(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('time', {
            controller: 'KitchenTimeController',
            templateUrl: '/components/time/time.html',
            bindings: {
                'seconds': '<',
                'prefix': '@?',
                'showNowFlag': '<',
                'showFor1Flag': '<',
                'hideDays': '<?'
            }
        });
})();