(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('authUser', {
            controller: 'TabletAuthUserController',
            templateUrl:  '/components/auth-user/auth-user.html',
            bindings: {
                location: '<'
            }
        });
}());