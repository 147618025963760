(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("plannedJob", {
        templateUrl: "/components/planned-job/planned-job.html",
        controller: "PlannedJobController",
        bindings: {
            job: "<",
            isSelected: "<",
            toggleSelection: "&",
            moveNext: "&",
        }
    });
})();
