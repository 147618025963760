(function () {
    "use strict";

    angular.module("laekkerAI.tablet").config([
        "$stateProvider",
        function ($stateProvider) {
            if ($("body").attr("ng-app") !== "laekkerAI.tablet") {
                return;
            }
            $stateProvider
                .state({
                    url: "",
                    name: "default",
                    component: "bootstrapper",
                    resolve: {
                        targetWhenAuthorized: function () {
                            return "main-menu";
                        },
                        targetWhenUnauthorized: function () {
                            return "auth";
                        }
                    }
                })
                .state({
                    name: "auth",
                    url: "/auth",
                    component: "auth",
                    redirectTo: "auth.location",
                    resolve: {}
                })
                .state({
                    name: "auth.location",
                    url: "/location",
                    component: "authLocation",
                    resolve: {}
                })
                .state({
                    name: "auth.user",
                    url: "/user/",
                    component: "authUser",
                    params: {
                        location: null
                    },
                    resolve: {
                        location: [
                            "$transition$",
                            function ($transition$) {
                                return $transition$.params().location;
                            }
                        ]
                    }
                })
                .state({
                    name: "auth.pin",
                    url: "/pin",
                    component: "authPin",
                    params: {
                        location: null,
                        user: null
                    },
                    resolve: {
                        location: [
                            "$transition$",
                            function ($transition$) {
                                return $transition$.params().location;
                            }
                        ],
                        user: [
                            "$transition$",
                            function ($transition$) {
                                return $transition$.params().user;
                            }
                        ]
                    }
                })
                .state({
                    name: "main-menu",
                    url: "/main-menu",
                    component: "mainMenu",
                    resolve: {},
                    requireAuth: true
                })
                .state({
                    name: "goods-receiving",
                    url: "/goods-receiving",
                    component: "goodsReceiving"
                })
                .state({
                    name: "goods-receiving.list",
                    url: "/list",
                    component: "goodsReceivingList"
                })
                .state({
                    name: "goods-receiving.order",
                    url: "/supplier-order/:orderId",
                    component: "goodsReceivingOrder",
                    resolve: {
                        orderId: [
                            "$transition$",
                            function ($transition$) {
                                return $transition$.params().orderId;
                            }
                        ]
                    }
                })
                .state({
                    name: "kitchen",
                    url: "/kitchen",
                    component: "kitchenApp"
                })
                .state({
                    name: "kitchen.category-selection",
                    url: "/category-selection",
                    component: "categorySelection",
                    resolve: {
                        settings: [
                            "TabletConfigService",
                            function (configService) {
                                return configService.getSettings();
                            }
                        ]
                    }
                })
                .state({
                    url: "/{categoryId:[0-9]+}",
                    name: "kitchen.category-selection.category",
                    component: "category",
                    resolve: {
                        categoryId: [
                            "$transition$",
                            function ($transition$) {
                                return $transition$.params().categoryId;
                            }
                        ],
                        settings: [
                            "TabletConfigService",
                            function (configService) {
                                return configService.getSettings();
                            }
                        ]
                    }
                })
                .state({
                    name: "kitchen.production",
                    url: "/production/:categoryId",
                    component: "production",
                    params: {
                        categoryId: null
                    },
                    resolve: {
                        settings: [
                            "TabletConfigService",
                            function (configService) {
                                return configService.getSettings();
                            }
                        ],
                        categoryId: [
                            "$transition$",
                            function ($transition$) {
                                return $transition$.params().categoryId;
                            }
                        ]
                    }
                })
                .state({
                    name: "kitchen.planning",
                    url: "/planning",
                    component: "productionPlanning",
                    needPermission: "PRODUCTIONPLANNING_ACCESS"
                })
                .state({
                    name: "product-stock-items",
                    url: "/product-stock-items",
                    component: "productStock",
                })
                .state({
                    name: "qm-spot",
                    url: "/qm-spot",
                    component: "qmSpot",
                })
                .state({
                    name: "personal-planning",
                    url: "/personal-planning",
                    component: "personalPlanning",
                    needPermission: "PRODUCTIONPLANNING_ACCESS"
                })
                .state({
                    name: "my-city",
                    url: "/my-city",
                    component: "myCity",
                    needPermission: "PRODUCTIONPLANNING_ACCESS"
                })
                .state({
                    url: '/stock-items',
                    name: 'stock-items',
                    component: 'stockItems'
                });
        }
    ])
    .config(['$injector', function ($injector) {
        $injector.invoke(['$qProvider', function($qProvider) {
            $qProvider.errorOnUnhandledRejections(false);
        }]);
    }])
    .run(['$transitions', 'TabletAuthService', 'TabletNotificationService', function ($transitions, AuthService, NotificationService) {
        $transitions.onBefore({}, function (transition) {
            console.log("transitions", transition);
            var neededPermission = transition.to().needPermission;
            if (!neededPermission) {
                return;
            }

            var promise = new Promise(function (resolve, reject) {
                AuthService.hasPermission(neededPermission, function (result) {
                    console.log("transitions result",result);
                    if (result.granted === true) {
                        //console.info("permission ok");
                        return resolve();
                    } else {
                        //console.warn("permission not ok");
                        return reject("Sie haben nicht die entsprechende Berechtigung, um diesen Bereich sehen zu können. (Benötigt wird: " + neededPermission + ").");
                    }
                });
            });

            return promise;

        });

        $transitions.onError({}, function (transition) {
            var err = transition.error();
            if(!err.detail){
                return;
            }
            NotificationService.error(err.detail, 8000);
        });
    }]);
})();
