(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("PlannedJobController", [
            "PlanningService",
            "TabletBaseService",
            "TabletEventService",
            PlannedJobController
        ]);

    function PlannedJobController(planningService, baseService, eventService) {
        var $ctrl = this;



        $ctrl.$onInit = function() {
            $ctrl.minDate = new Date($ctrl.job.minSelectableDate);
            $ctrl.maxDate = new Date($ctrl.job.maxSelectableDate);
            $ctrl.timeline = $ctrl.job.timeline;
            updateDuration();

            
            
            $ctrl.showNowFlag=false;
        };

        function updateDuration() {
            $ctrl.duration = Math.ceil(
                $ctrl.job.statisticalProductionDuration * $ctrl.job.plannedQuantity
            );
            $ctrl.showFor1Flag = false;
            if($ctrl.duration == 0){
                $ctrl.showFor1Flag = true;
                $ctrl.duration = Math.ceil(
                    $ctrl.job.statisticalProductionDuration * 1
                );
            }
        }

        $ctrl.changeQuantity = function(quantity) {
            var value = quantity - $ctrl.job.plannedQuantity;
            updateTimes($ctrl.job.plannableFor, Math.ceil($ctrl.job.statisticalProductionDuration * value));

            $ctrl.job.plannedQuantity = quantity;
            $ctrl.job.isPlanned = false;
            updateDuration();

            if (!$ctrl.isSelected) {
                $ctrl.toggleSelection();
            }
        };

        $ctrl.markAsPlanned = function() {
            if (!$ctrl.isSelected) {
                return;
                console.warn("nothing selected");
            }

            var oldValue = $ctrl.job.isPlanned;

            if($ctrl.job.plannedQuantity < $ctrl.job.minQuantity) {
                $ctrl.changeQuantity($ctrl.job.minQuantity);
            }

            $ctrl.job.isPlanned = true;

            planningService.updateProductionPlanFor(
                $ctrl.job,
                $ctrl.job.plannedQuantity,
                function(err) {
                    if(err) {
                        $ctrl.job.isPlanned = oldValue;
                    }           

                    $ctrl.moveNext();
                }
            );
        };

        function updateTimes(date, seconds) {
            eventService.broadcast(eventService.events.updatePlannedProduct, date, seconds);
        }

        $ctrl.$onChanges = function(changes) {};

        $ctrl.$onDestroy = function() {};
    }
})();
