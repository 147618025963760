(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("card", {
        templateUrl: "components/card/card.html",
        controller: "CardController",
        bindings: {},
        transclude: true
    });
})();
