(function() {
    "use strict";
    angular.module("laekkerAI.tablet").component("jobTaker", {
        controller: "KitchenJobTakerController",
        templateUrl: "/components/job-taker/job-taker.html",
        bindings: {
            ownerName: "<",
            job: "<"
        }
    });
})();
