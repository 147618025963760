(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("CardController", [CardController]);

    function CardController() {
        var $ctrl = this;

        $ctrl.$onInit = function() {};

        $ctrl.$onChanges = function(changes) {};

        $ctrl.$onDestroy = function() {};

        return $ctrl;
    }
})();
