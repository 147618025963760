(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller('PersonalPlanningController', [
            "TabletConfigService",
            PersonalPlanController
        ]);

    function PersonalPlanController(
        configService
    ) {
        var $ctrl = this;
        $ctrl.showDetails = false;

        $ctrl.$onInit = function () {
            console.log('PersonalPlanningController on init');

            $ctrl.settings = configService.getSettings();
        };

        $ctrl.toggleDetails = function () {
            $ctrl.showDetails = !$ctrl.showDetails;
        };
    }
})();
