(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .factory('TabletNotificationService', [
            'TabletEvents',
            'TabletEventService',
            function (events, eventService) {
                var service = this;
                service.error = function (message, duration, actions) {
                    return createNotification('error', message, duration, actions);
                };

                service.success = function (message, duration, actions) {
                    return createNotification('success', message, duration, actions);
                };

                service.info = function (message, duration, actions) {
                    return createNotification('info', message, duration, actions);
                };

                service.createUndoActions = function (onUndo) {
                    return [createAction('rückgängig', onUndo)];
                };

                service.createDismissActions = function (onClose) {
                    return [createAction('X', onClose)];
                };

                service.createOkCancelActions = function (onOkay, onCancel) {
                    return [
                        createAction('Okay', onOkay),
                        createAction('Abbrechen', onCancel)
                    ];
                };

                function createAction(title, callback) {
                    return {
                        title: title,
                        callback: callback || doNothing
                    };
                }

                function createNotification(type, message, duration, actions) {
                    if (!actions) {
                        actions = service.createDismissActions();
                    }

                    if (!duration) {
                        duration = 10000;
                    }

                    var notification = {
                        type: type,
                        message: message,
                        duration: duration,
                        actions: actions
                    };

                    eventService.broadcast(events.notificationCreated, notification);

                    return function () {
                        eventService.broadcast(events.removeNotification, notification);
                    };
                }

                function doNothing() { }

                return service;
            }]);
}());