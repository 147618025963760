(function () {
    "use strict";
    angular
        .module("laekkerAI.tablet")
        .constant("TabletEvents", {
            connectionStatusChanged: "connectionStatusChanged",
            notificationCreated: "notificationCreated",
            removeNotification: "removeNotification",
            updateTimeline: "updateTimeline",
            updateSpecificJobs: "updateSpecificJobs",
            resetJobs: "resetJobs",
            jobsLoaded: "jobsLoaded",
            locationChanged: "locationChanged",
            recipeVideoLoaded: "recipeVideoLoaded"
        })
        .constant("PRODUCTION_TYPE", {
            PRE: 1,
            DAILY: 0
        })
        .config([
            "$httpProvider",
            function ($httpProvider) {
                $httpProvider.interceptors.push("UnauthorizedInterceptor");
                $httpProvider.interceptors.push("LoadingInterceptor");
            }
        ])
        .run([
            "KitchenRecipeService",
            "KitchenGroupService",
            "TabletEventService",
            "KitchenJobService",
            "$interval",
            function (recipeService, groupService, eventService, jobService, $interval) {

                if(!window.jasmine) {
                    autoUpdateJobs($interval, eventService, jobService);
                }

                // keep this for karma to success in tests
                if (__appConfig.api.indexOf("localhost") !== -1) {
                    return;
                }

                groupService.preloadGroups();
                recipeService.preloadRecipes();

            }
        ]);

    function autoUpdateJobs($interval, eventService, jobService) {
        function autoUpdate() {
            jobService.updateJobs(null, function () {
                eventService.broadcast(eventService.events.updateTimeline);
            });
        }
        // autoUpdate();
        $interval(autoUpdate, __appConfig.jobUpdateInterval);
    }
})();
