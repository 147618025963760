(function() {
    "use strict";

    /**
     * @ngdoc function
     * @name Kitchencontroller:kitchenController
     * @description todo
    **/
    var app = angular.module("laekkerAI.tablet");

    app.controller("KitchenKitchenController", [function() {}]);
})();
