(function () {
    'use strict';
    
    var app = angular.module('laekkerAI.tablet');
    app.controller('StockItemsController',
        [
            'CommodityService',
            'ProductService',
            'TabletNotificationService',
            'TabletAuthService',
            function (commodityService, productService, NotificationService, AuthService) {
                var $ctrl = this;

                $ctrl.products = [];
                $ctrl.commodities = [];

                $ctrl.searchTerm = 'wrap';
                $ctrl.searching = false;
                $ctrl.editingStockItem = null;
                $ctrl.hasChangePermission = false;

                $ctrl.$onInit = function () {
                    updatePermissions();
                    $ctrl.search();
                };

                $ctrl.search = function (searchTerm) {
                    $ctrl.searchTerm = searchTerm || '';
                    beginSearch();
                    getCommodities();
                    getProducts();
                };

                $ctrl.edit = function(stockItem) {
                    $ctrl.editingStockItem = stockItem;
                };

                $ctrl.endSearch = function () {
                    $ctrl.searchTerm = '';
                };

                $ctrl.endEdit = function () {
                    $ctrl.editingStockItem = null;
                };

                function updatePermissions() {
                    AuthService.hasPermission('STOCK_CHANGE', function (result) {
                    if (result.permission === 'STOCK_CHANGE') {
                        $ctrl.hasChangePermission = result.granted;
                    }
                });
                }

                function beginSearch() {
                    $ctrl.endEdit();
                    $ctrl.searching = true;
                    $ctrl.commodities = [];
                    $ctrl.products = [];
                }

                function getCommodities() {
                    commodityService.getCommodities($ctrl.searchTerm, function (err, commodities) {
                        $ctrl.searching = false;
                        if(err) {
                            return NotificationService.error(err);
                        }

                        commodities.forEach(function (c) {
                            c.Key = { Type: 1, ID: c.ID };
                        });

                        $ctrl.commodities = commodities;
                    });
                }

                function getProducts() {
                    productService.findProducts($ctrl.searchTerm, 10, function (err, products) {
                        if(err) {
                            return NotificationService.error(err);
                        }

                        products.forEach(function (p) {
                            p.Key = { Type: 0, ID: p.ID };
                        });

                        $ctrl.products = products;
                    });
                }

                return $ctrl;

            }
        ]);

})();