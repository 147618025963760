(function () {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("SearchButtonController", [SearchButtonController]);

    function SearchButtonController() {
        var $ctrl = this;

        $ctrl.$onInit = function () { };

        $ctrl.$onChanges = function (changes) { };

        $ctrl.$onDestroy = function () { };

        $ctrl.startSearch = function () {
            console.log('SearchButton .startSearch with searchTerm ' + $ctrl.searchTerm);
            $ctrl.onSearch({searchTerm: $ctrl.searchTerm});
        };

        $ctrl.reset = function () {
            $ctrl.searchTerm = '';
        };
    }
})();
