(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("ProductionPlanningController", [
            "PlanningService",
            "KitchenGroupService",
            "Utility",
            "$document",
            "$timeout",
            "TabletNotificationService",
            ProductionPlanningController
        ]);

    function ProductionPlanningController(
        planningService,
        groupService,
        utility,
        $document,
        $timeout,
        notificationService
    ) {
        var $ctrl = this;

        $ctrl.selectedCategory = null;
        $ctrl.selectedSupplier = null;
        $ctrl.subCategories = null;

        $ctrl.plan = null;

        $ctrl.minDate = null;
        $ctrl.maxDate = null;
        $ctrl.savedText = null;
        $ctrl.dateOffset = null;

        $ctrl.loading = true;

        $ctrl.serviceAvailable = true;

        $ctrl.$onInit = function() {
            $ctrl.savedText="Alle speichern";

            $ctrl.dateOffset = 14;
            $ctrl.minDate = new Date();
            $ctrl.maxDate = new Date(new Date().setDate(new Date().getDate() + $ctrl.dateOffset)); 
            
            $ctrl.loadPlan($ctrl.minDate,$ctrl.maxDate);

        };

        $ctrl.loadPlan = function(minDate,maxDate) {
            planningService.getProductionPlan(minDate,maxDate,function(err, plan) {
                $ctrl.loading = false;
                if(err) {
                    $ctrl.serviceAvailable = false;
                    return notificationService.error(err);
                }

                $ctrl.plan = plan;

                $ctrl.selectableDates = getPlanableforDateFromJobs($ctrl.plan);
                $ctrl.minSelectableDate = getMinSelectableDate();
                $ctrl.maxSelectableDate = getMaxSelectableDate();
    
                $timeout(function() {
                    $ctrl.moveToNextCategory();
                }, 500);
            });
        };

        $ctrl.saveCompleteProductionPlan = function() {
            $ctrl.justSaved=true;
            $ctrl.saved=true;
            $ctrl.savedText="Wird gespeichert";
            planningService.saveCompleteProductionPlan($ctrl.plan,function(){
                $ctrl.savedText="Wurde gespeichert";
                $ctrl.justSaved=false;
                $timeout(function(){$ctrl.savedText="Alle speichern";}, 5000);
            });
        };

        $ctrl.toggleSelection = function(job) {
            if ($ctrl.selectedJob === job) {
                $ctrl.selectedJob = null;
            } else {
                $ctrl.selectedJob = job;

                $timeout(function() {
                    var elem = document.getElementById(
                        "plannedJob_" + job.productId
                    );
                    var someElement = angular.element(elem);
                    $document.scrollToElementAnimated(someElement);

                }, 200);
            }
        };

        $ctrl.selectCategory = function(category) {
            console.log('production-planning.controller.selectCategory: ' + category);
            $ctrl.selectedCategory = category;
            $ctrl.selectedSupplier = null;

            if (!$ctrl.selectedCategory) {
                console.warn('production-planning.controller.selectCategory is null');
                $ctrl.subCategories = null;
                return;
            }
            groupService.getChildGroups(
                $ctrl.selectedCategory.ID,
                processSubCategories
            );
        };

        $ctrl.selectSupplier = function(supplier) {
            $ctrl.selectedCategory = null;
            $ctrl.selectedSupplier = supplier;
        };

        function processSubCategories(subCategories) {
            console.log('production-planning.controller.processSubCategories: ' + (subCategories ? subCategories.length : 0));
            $ctrl.subCategories = subCategories;

            var subCategoryIds = $ctrl.subCategories.map(function(sc) {
                return sc.ID;
            });

            var anySelected = false;

            for (var i = 0; i < $ctrl.plan.jobGroups.length; i++) {
                var jobGroup = $ctrl.plan.jobGroups[i];
                jobGroup.isSelected = utility.contains(
                    subCategoryIds,
                    jobGroup.groupId
                );

                if(jobGroup.isSelected) {
                    anySelected = true;
                }
            }

            console.log('production-planning.controller.anySelected = ' + anySelected);
            $ctrl.anySelected = anySelected;
        }

        $ctrl.moveToNextCategory = function(currentJobGroup) {
            if(!$ctrl.plan) {
                return;
            }

            var toggleNext = !currentJobGroup;
            for (var i = 0; i < $ctrl.plan.jobGroups.length; i++) {
                var jobGroup = $ctrl.plan.jobGroups[i];
                if (jobGroup.jobs.length === 0 || !jobGroup.isSelected) {
                    continue;
                }

                if (toggleNext) {
                    $ctrl.toggleSelection(jobGroup.jobs[0]);
                    return;
                }

                if (jobGroup === currentJobGroup) {
                    toggleNext = true;
                }
            }
        };

        function getMinSelectableDate() {
            return moment(Math.min.apply(Math, $ctrl.selectableDates)).format("YYYY-MM-DD");
        }

        function getMaxSelectableDate() {
            return moment(Math.max.apply(Math, $ctrl.selectableDates)).format("YYYY-MM-DD");
        }

        function getPlanableforDateFromJobs(plan) {
            
            if(!plan.jobGroups || plan.jobGroups.length === 0) {
                return moment();
            }
            return plan.jobGroups
            .map(function(jg) { return jg.jobs; })
            .reduce(function(a,b) {return a.concat(b); })
            .map(function(j) {return moment(j.plannableFor);});
        }
    }
})();
