(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('jobGroup', {
            controller: 'KitchenJobGroupController',
            templateUrl: '/components/job-group/job-group.html',
            bindings: {
                jobGroup: '<',
                toggleSelection: '&',
                selectedJob: '<',
                listSettings: '<',
                productionType: "<"
            }
        });
})();