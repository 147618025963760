(function () {
    'use strict';
    
    angular
    .module('laekkerAI.tablet')
    .controller('PlannedTimeController', [
        "PlanningService",
        "TabletEventService",
        PlannedTimeController
    ]);

    function PlannedTimeController(planningService, eventService) {
        var $ctrl = this;
    
        $ctrl.$onInit = function () {
            eventService.subscribe(eventService.events.updatePlannedProduct, $ctrl.addTime);
            $ctrl.updateTimesFromApi();
        };
    
        $ctrl.$onChanges = function(changes) {
        };
    
        $ctrl.$onDestroy = function () {
        };
        
        $ctrl.updateTimesFromApi = function() {
            planningService.getEstimatedProductionLocation(
                $ctrl.min,
                $ctrl.max,
                function (times) {
                    if(times !== null) {
                        $ctrl.times = times;
                    }
                }
            );
        };

        $ctrl.addTime = function(date, seconds) {
            var filtered = $ctrl.times.filter(function (f){ return f.date === date; });
            if(filtered.length > 0) {
                filtered[0].durationInSeconds += seconds;
            }            
        };

        $ctrl.getWorkerCount = function(seconds) {
            return Math.ceil(seconds / (8 * 3600));
        };
    }
}());