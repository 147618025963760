(function() {
    "use strict";
    angular.module("laekkerAI.tablet").component("timeline", {
        controller: "KitchenTimelineController",
        templateUrl: "/components/timeline/timeline.html",
        bindings: {
            changeQuantity: "&",
            quantity: "<",
            timeline: "<",
            total: "<?",
            minDate: "<?",
            maxDate: "<?",
            showProduced: "<?",            
            job: "<?"
        }
    });
})();
