(function(document) {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("BackButtonController", ["$state", BackButtonController]);

    function BackButtonController($state) {
        var $ctrl = this;

        $ctrl.back = function() {
            if ($ctrl.onClick) {
                return $ctrl.onClick();
            }
            if (!$ctrl.state) {
                document.location.href = "/";
            }
            $state.go($ctrl.state, $ctrl.params);
        };
    }
})(window.document);
