(function() {
    "use strict";
    var app = angular.module("laekkerAI.tablet");

    app.controller("TabletBootstrapperController", [
        "$state",
        "TabletAuthService",
        function($state, AuthService) {
            var $ctrl = this;
            $ctrl.isInitialized = false;

            $ctrl.$onInit = function() {
                AuthService.isAuthorized(function(result) {
                    $ctrl.isInitialized = true;
                    jQuery("#theHEADER").hide();
                    if (result) {
                        var currentState = "default";
                        if ($state.current) {
                            currentState = $state.current.name;
                        }
                        if (currentState === "default") {
                            return go($ctrl.targetWhenAuthorized);
                        } else {
                            return go(
                                currentState || $ctrl.targetWhenAuthorized
                            );
                        }
                    }

                    if ($ctrl.targetWhenUnauthorized) {
                        return go($ctrl.targetWhenUnauthorized);
                    }

                    document.location.href = '/';
                });
            };

            function go(target) {
                if (typeof target === "string") {
                    $state.go(target);
                } else {
                    $state.go(target.state, target.params);
                }
            }

            return $ctrl;
        }
    ]);
})();
