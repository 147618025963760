(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('HygenicIconController', [
            "KitchenRecipeService",
            function (recipeService) {

            var $ctrl = this;

            $ctrl.$onInit = function () {              
                $ctrl.loadRecipe();
            };

            
            $ctrl.loadRecipe = function() {
                if ($ctrl.recipeKey) {
                    _loadRecipe();
                }
            };

            function _loadRecipe() {
                recipeService.getRecipeByKey(
                    $ctrl.recipeKey,
                    function(err, recipe, groups, stats) {                        
                        if (err) {
                            return; 
                        }
                        $ctrl.recipe = recipe;
                    }
                );
            }
            
        }]);
})();