(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .factory('TabletEventService',
        ['$rootScope', 'TabletEvents', function ($rootScope, events) {
            var subscriptions = {};

            var service = this;

            service.events = events;

            service.subscribe = function (event, callback) {
                if (subscriptions[event] === undefined) {
                    subscriptions[event] = [];
                }

                var sub = { callback: callback };
                subscriptions[event].push(sub);
                return sub;
            };

            service.unsubscribe = function (event, subscription) {
                if (subscriptions[event] === undefined) {
                    return;
                }
                var id = subscriptions[event].indexOf(subscription);
                if (id === -1) {
                    return;
                }

                subscriptions[event].splice(id, 1);

            };

            service.broadcast = function (event, arg1, arg2, arg3, arg4) {
                if (subscriptions[event] !== undefined) {
                    for (var i = 0; i < subscriptions[event].length; i++) {
                        var sub = subscriptions[event][i];
                        sub.callback(arg1, arg2, arg3, arg4);
                    }
                }
                console.log('EVENT: ' + event);
                $rootScope.$broadcast(event, arg1, arg2, arg3, arg4);
            };

            return this;
        }]);
}());