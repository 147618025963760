(function () {
    'use strict';

    var PLATTENLISTE_NAME = 'Packaging';
    var PLATTENLISTE_GROUPID = 829;
    var COLDKITCHEN_NAME = 'Kalte Küche';
    var COLDKITCHEN_GROUPID = 6;

    angular
        .module('laekkerAI.tablet')
        .controller('TogglePlattenlisteButtonController',
        [
            'TabletConfigService',
            'KitchenGroupService',
            function (configService, categoryService) {
                var $ctrl = this;

                $ctrl.targetCategoryName = PLATTENLISTE_NAME;
                $ctrl.targetGroupID = PLATTENLISTE_GROUPID;
                $ctrl.buttons = [];
                $ctrl.settings = null;
                $ctrl.categoryId = null;
                $ctrl.category = null;
                $ctrl.selectedCategories = [];
                $ctrl.targetCategories = [];
                $ctrl.isPlattenliste = false;
                $ctrl.isColdkitchen = false;
                $ctrl.toggleOn = false;


                $ctrl.$onInit = function () {

                    $ctrl.isPlattenliste = $ctrl.categoryId == PLATTENLISTE_GROUPID;
                    $ctrl.isColdkitchen = $ctrl.categoryId == COLDKITCHEN_GROUPID;
                    
                    $ctrl.targetCategoryName =  $ctrl.isPlattenliste ? COLDKITCHEN_NAME : PLATTENLISTE_NAME;
                    $ctrl.targetGroupID = $ctrl.isPlattenliste ? COLDKITCHEN_GROUPID : PLATTENLISTE_GROUPID;

                    $ctrl.selectedCategories = $ctrl.settings.selectedCategories || [];

                    categoryService.getChildGroups($ctrl.targetGroupID, function (children) {
                        $ctrl.targetCategories = children.map(function(group) {
                            return group.ID;
                        }) || [];

                        $ctrl.targetCategories.push($ctrl.targetGroupID);
                    });

                    categoryService.getGroupById($ctrl.categoryId, function (err, group) {
                        $ctrl.category = group;
                    });
                   
                };

                $ctrl.doToggle = function () {
                    
                    if ($ctrl.toggleOn) { 
                        configService.setSelectedCategories($ctrl.selectedCategories);
                        $ctrl.toggleOn = false;
                    } else {
                        configService.setSelectedCategories($ctrl.targetCategories);
                        $ctrl.toggleOn = true;
                    }

                    if($ctrl.onToggle) {
                        $ctrl.onToggle();
                    }
                };

                return $ctrl;
            }
        ]);
})();