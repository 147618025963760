(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('imageTile', {
            controller: 'TabletImageTileController',
            templateUrl:  '/components/image-tile/image-tile.html',
            bindings: {
                imageUrl: '@',
                iconClass: '@',
                text: '@'
            },
            transclude: true
        });
}());