(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('NotificationsController', ['TabletEvents', 'TabletEventService', '$timeout', function (events, eventService, $timeout) {

            var $ctrl = this;
            var sub = null;
            var sub2 = null;

            $ctrl.notifications = [];

            $ctrl.$onInit = function () {
                sub = eventService.subscribe(events.notificationCreated, addNotification);
                sub2 = eventService.subscribe(events.removeNotification, removeNotification);
            };

            $ctrl.$onDestroy = function () {
                eventService.unsubscribe(events.notificationCreated, sub);
                eventService.unsubscribe(events.removeNotification, sub2);
            };

            $ctrl.removeNotificationAndInvokeCallback = function (notification, callback) {
                removeNotification(notification);
                if (callback) {
                    callback();
                }
            }

            function addNotification(notification) {
                if(notificationExists(notification)) {
                    return;
                }

                $ctrl.notifications.push(notification);
                enableRemoval(notification);
            }

            function notificationExists(notification) {
                for(var i = 0; i < $ctrl.notifications.length; i++) {
                    if($ctrl.notifications[i].message === notification.message)
                        return true;
                }

                return false;
            }

            function enableRemoval(notification) {
                if (notification.duration <= 0) {
                    return;
                }
                $timeout(function () { removeNotification(notification); }, notification.duration);
            }

            function removeNotification(notification) {
                var idx = $ctrl.notifications.indexOf(notification);
                if (idx === -1) {
                    return;
                }
                $ctrl.notifications.splice(idx, 1);
            }

            return $ctrl;

        }]);
})();