(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('productStock', {
            controller: 'ProductStockController',
            templateUrl: '/components/product-stock/product-stock.html',
            bindings: {
            }
        });
})();