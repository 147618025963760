(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .component('togglePlattenlisteButton', {
            controller: 'TogglePlattenlisteButtonController',
            templateUrl:  '/components/toggle-plattenliste-button/toggle-plattenliste-button.html',
            bindings: {
                settings: '<',
                categoryId: '<',
                onToggle: '&'
            }
        });
})();