(function() {
    "use strict";

    angular.module("laekkerAI.tablet").factory("TabletLocalStorageService", [
        "$window",
        function($window) {
            var service = this;

            service.set = function(key, obj) {
                if (obj !== undefined && obj !== null) {
                    $window.localStorage.setItem(key, JSON.stringify(obj));
                }
            };

            service.get = function(key) {
                var item = $window.localStorage.getItem(key);

                if (!item) {
                    return null;
                }

                try {
                    return JSON.parse(item);
                } catch (error) {
                    return {};
                }
            };

            service.remove = function(key) {
                $window.localStorage.removeItem(key);
            };

            service.clear = function() {
                $window.localStorage.clear();
            };

            service.keys = function() {
                var keys = [];

                for (
                    var index = 0;
                    index < $window.localStorage.length;
                    index++
                ) {
                    keys.push($window.localStorage.key(index));
                }

                return keys;
            };

            var dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;

            service.cleanStorage = function () {
                var keys = service.keys();
                var currentDate = new Date();
                for(var i = 0; i < keys.length; i++) {
                    var key = keys[i];
                    var keyDate = Date.parse(key);
                    if (dateRegex.test(key) && keyDate < currentDate.getTime()) {
                        service.remove(key);
                    }
                }
            };

            return service;
        }
    ]);
})();
