(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('amountInput', {
            controller: 'KitchenAmountInputController',
            templateUrl: '/components/amount-input/amount-input.html',
            bindings: {
                'min': '<',
                'max': '<',
                'quantity': '<',
                'onChange': '&'
            }
        });
})();