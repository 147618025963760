(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('authPin', {
            controller: 'TabletAuthPinController',
            templateUrl:  '/components/auth-pin/auth-pin.html',
            bindings: {
                location: '<',
                user: '<'
            }
        });
}());