(function () {
    'use strict';

    angular
    .module('laekkerAI.tablet')
    .component('connectionIndicator', {
            restrict: 'E',
            templateUrl:  '/components/connection-indicator/connection-indicator.html',
            controller: 'TabletConnectionIndicatorController'
    });
})();