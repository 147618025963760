(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .component('keypad', {
            controller: 'TabletKeypadController',
            templateUrl:  '/components/keypad/keypad.html',
            bindings: {
                value: '<',
                max: '<?',
                onChanged: '&?',
                onDone: '&?'
            }
        });
})();