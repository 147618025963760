(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("productionPlanningSuppliers", {
        templateUrl:
            "components/production-planning-suppliers/production-planning-suppliers.html",
        controller: "ProductionPlanningSuppliersController",
        bindings: {
            suppliers: "<",
            onSelect: "&"
        }
    });
})();
