(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('productionJobContent', {
            controller: 'KitchenProductionJobContentController',
            templateUrl: '/components/production-job-content/production-job-content.html',
            bindings: {
                job: '<',
                changeQuantity: '&',
                quantity: '<',
                isSelected: '<',
                allowTimeMeasurement: "<"
            }
        });
})();