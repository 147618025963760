(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("PlannedJobGroupController", [
            "KitchenGroupService",
            PlannedJobGroupController
        ]);

    function PlannedJobGroupController(groupService) {
        var $ctrl = this;

        $ctrl.group = {
            imageUrl: "/images/product_default.png",
            name: "unbekannt"
        };

        $ctrl.$onInit = function() {
            groupService.getGroupById($ctrl.groupId, function(err, group) {
                if (!group) {
                    return;
                }
                $ctrl.group.name = group.name;
                $ctrl.group.imageUrl = group.imageUrl;
            });
        };

        $ctrl.moveToNextJob = function() {
            var toggleNext = false;
            for (var i = 0; i < $ctrl.jobs.length; i++) {
                var job = $ctrl.jobs[i];
                if (toggleNext) {
                    $ctrl.toggleSelection({ job: job });
                    return;
                }
                if (job === $ctrl.selectedJob) {
                    toggleNext = true;
                }
            }

            $ctrl.moveNext();
        };
    }
})();
