(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("refreshButton", {
        templateUrl: "components/refresh-button/refresh-button.html",
        controller: "RefreshButtonController",
        bindings: {},
        transclude: true
    });
})();
