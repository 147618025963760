(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('videoPlayer', {
            controller: 'KitchenVideoPlayerController',
            templateUrl: '/components/video-player/video-player.html',
            bindings: {
                resolve: '<',
                'close': '&'
                // 'videoUrl': '<',
                // 'title': '<',
                // 'description': '<'
            }
        });
})();