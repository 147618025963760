(function () {
    'use strict';
    var app = angular.module('laekkerAI.tablet');

    app.controller(
        'TabletUserInfoController',
        [
            'TabletConfigService',
            'TabletBaseService',
            'TabletEventService',
            'TabletAuthService',
            '$state',
            function (ConfigService, baseService, eventService, authService, $state) {

                var $ctrl = this;
                $ctrl.user = null;
                $ctrl.location = null;
                $ctrl.displayName = '';

                $ctrl.locations = null;

                var sub = null;
                var sub2 = null;

                $ctrl.$onInit = function () {
                    sub = eventService.subscribe(eventService.events.userLogin, function () {
                        _updateUserInfo();
                        _updateLocations();    
                    });
                    sub2 = eventService.subscribe(eventService.events.locationChanged, _updateUserInfo);
                    
                    _updateUserInfo();
                    _updateLocations();
                };

                $ctrl.selectLocation = function (location) {
                    authService.switchLocation(location, function (err) {
                        if (!err) {
                            $ctrl.location = location;
                        }
                    });
                };

                $ctrl.$onDestroy = function () {
                    eventService.unsubscribe(eventService.events.userLogin, sub);
                    eventService.unsubscribe(eventService.events.locationChanged, sub2);
                };

                $ctrl.$onChanges = function (changes) {
                    if (changes.user) {
                        _updateUserInfo();
                    }
                };
                
                $ctrl.logout = function () {
                    ConfigService.logout();
                    _updateUserInfo();
                    _updateLocations();
                    $state.go('auth.location');
                };

                function _updateUserInfo() {
                    $ctrl.location = ConfigService.getLocation();
                    $ctrl.user = ConfigService.getUser();
                    usernameToDisplayName();
                }

                function _updateLocations() {
                    if(!$ctrl.location) {
                        return;
                    }
                    
                    authService.hasPermission('PERM_Switch_Location', function (result) {
                        if (result.granted === true) {
                            authService.getLocations(function (err, locations) {
                                if (err) {
                                    return;
                                }
                                $ctrl.locations = locations;
                            });
                        } else {
                            $ctrl.locations = null;
                        }
                    });
                }

                function usernameToDisplayName() {
                    if (!$ctrl.user) {
                        return;
                    }
                    $ctrl.displayName = baseService.formatName($ctrl.user.Name);
                }

            }]);
}());