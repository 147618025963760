(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("goodsReceivingList", {
        templateUrl:
            "/components/goods-receiving-list/goods-receiving-list.html",
        controller: "GoodsReceivingListController",
        bindings: {}
    });
})();
