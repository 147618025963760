(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('qmSpot', {
            controller: 'QmSpotController',
            templateUrl: '/components/qm-spot/qm-spot.html',
            bindings: {
            }
        });
})();