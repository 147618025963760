(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('TabletConnectionIndicatorController',
        [
            'TabletHubService',
            'TabletConfigService',
            'TabletEventService',
            function (hubService, ConfigService, EventService) {

                var $ctrl = this;
                var sub;

                $ctrl.status = 'disconnected';
                $ctrl.connectionStatus = null;
                $ctrl.color = null;
                $ctrl.statusName = null;

                $ctrl.$onInit = function () {
                    sub = EventService.subscribe(EventService.events.connectionStatusChanged, _updateStatus);
                    _updateStatus();
                };

                $ctrl.$onDestroy = function () {
                    EventService.unsubscribe(sub);
                };

                /**
                 * @ngdoc function
                 * @name reconnect
                 * @methodOf Tabletcontroller:connectionIndicatorController
                 * @description todo
                **/
                $ctrl.reconnect = function () {
                    if ($ctrl.status !== 'connecting' && $ctrl.status !== 'connected') {
                        hubService.initHub(ConfigService.getAuthToken());
                    }
                };

                function _updateStatus(status) {
                    if (status) {
                        $ctrl.status = status;
                    }
                    $ctrl.connectionStatus = _getConnectionStatus();
                    $ctrl.color = _getIndicatorColor();
                    $ctrl.statusName = _getStatusName();
                }

                /**
                 * @ngdoc function
                 * @name getConnectionStatus
                 * @methodOf Tabletcontroller:connectionIndicatorController
                 * @description todo
                **/
                function _getConnectionStatus() {
                    switch ($ctrl.status) {
                        case 'connecting':
                            return 'orange';
                        case 'connected':
                            return 'green';
                        default:
                            return 'red';
                    }
                }

                /**
                 * @ngdoc function
                 * @name getTextClass
                 * @methodOf Tabletcontroller:connectionIndicatorController
                 * @description todo
                **/
                function _getIndicatorColor() {
                    switch ($ctrl.status) {
                        case 'connecting':
                            return 'orange';
                        case 'connected':
                            return 'green';
                        default:
                            return 'red';
                    }
                }

                /**
                 * @ngdoc function
                 * @name getStatusName
                 * @methodOf Tabletcontroller:connectionIndicatorController
                 * @description todo
                **/
                function _getStatusName() {
                    switch ($ctrl.status) {
                        case 'connecting':
                            return 'verbinde';
                        case 'connected':
                            return 'verbunden';
                        default:
                            return 'verbindung herstellen';
                    }
                }

            }]);
})();