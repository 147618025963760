(function() {
    "use strict";

    angular
        .module("laekkerAI.tablet")
        .controller("CleanupController", [
            "TabletLocalStorageService",
            "TabletApiService",
            CleanupController
        ]);

    function CleanupController(localStorageService, apiService) {
        var $ctrl = this;

        $ctrl.$onInit = function() {
            cleanOldData();
        };

        $ctrl.$onChanges = function(changes) {};

        $ctrl.$onDestroy = function() {};

        function cleanOldData() {
            apiService.cleanRequestDb();
            localStorageService.cleanStorage();
        }
    }
})();
