(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('stockItems', {
            controller: 'StockItemsController',
            templateUrl:  '/components/stock-items/stock-items.html',
            bindings: {
                onChange: '&',
                value: '<'
            }
        });
}());