(function() {
    "use strict";

    /**
     * @ngdoc function
     * @name Tabletcontroller:pinInputController
     * @description
     * @requires TabletConfigService
     * @requires TabletAuthService
     * @requires TabletHubService
    **/

    var app = angular.module("laekkerAI.tablet");

    app.controller("TabletAuthPinController", [
        "$state",
        "TabletAuthService",
        function($state, AuthService) {
            var $ctrl = this;
            $ctrl.pin = "";
            $ctrl.max = 4;

            $ctrl.$onInit = function() {
                if ($ctrl.user === null) {
                    return $state.go("default");
                }

                $ctrl.firstname = $ctrl.user.Name.substring(
                    $ctrl.user.Name.indexOf(",") + 1
                );
                $ctrl.lastname = $ctrl.user.Name.substring(
                    0,
                    $ctrl.user.Name.indexOf(",")
                );
            };

            /**
             * @ngdoc function
             * @name authenticate
             * @methodOf Tabletcontroller:pinInputController
             * @description todo
            **/
            $ctrl.authenticate = function() {
                $ctrl.errorMessage = "";
                if ($ctrl.pin.length < $ctrl.max) {
                    $ctrl.errorMessage =
                        "Bitte geben Sie Ihren vollständigen PIN ein.";
                    return;
                }

                AuthService.authenticate(
                    $ctrl.location,
                    $ctrl.user,
                    $ctrl.pin,
                    function(err, data) {
                        if (err) {
                            $ctrl.errorMessage =
                                err ||
                                "Leider sind die Zugangsdaten nicht korrekt. Bitte korrigieren Sie die Eingabe.";
                            $ctrl.pin = "";
                            return;
                        }
                        
                        $state.go("main-menu");
                    }
                );
            };

            $ctrl.pinChanged = function(value) {
                $ctrl.errorMessage = "";
                $ctrl.pin = value;
            };
        }
    ]);
})();
