(function () {
    'use strict';
    angular.module('laekkerAI.tablet').factory('ProductService', ['TabletApiService', function (apiService) {

            var service = {
                findProducts: _findProducts
            };

            function _findProducts(searchTerm, limit, callback) {
                searchTerm = searchTerm.toLowerCase().trim();
                var url = 'products/search';

                if(searchTerm.length > 0) {
                    url += '/' + searchTerm;
                }

                return apiService.get(url, function (items) {
                    return callback(null, items);
                }, function (err) {
                    return callback(err);  
                });
            }

            return service;
        }
    ]);

})();