(function () {
    "use strict";
    var app = angular.module("laekkerAI.tablet");

    /**
     * @ngdoc service
     * @name TabletApiService
     * @description #todo
     **/
    app.factory("TabletApiService", [
        "$http",
        "TabletConfigService",
        "$rootScope",
        "$state",
        "TabletLocalStorageService",
        "TabletBaseService",
        "$q",
        "$interval",
        "TabletNotificationService",
        function (
            $http,
            ConfigService,
            $rootScope,
            $state,
            LocalStorageService,
            BaseService,
            $q,
            $interval,
            notificationService
        ) {
            var db = new Dexie('laekkerai-tablet');
            db.version(1).stores({
                requests: "key"
            });
            db.debug = false;

            var isDump = LocalStorageService.get("_DUMP");
            if (isDump === true) {
                console.log("entered DUMP MODE");
            }

            var requestStore = LocalStorageService.get("requestStore") || [];
            var failedRequests =
                LocalStorageService.get("failedRequests") || [];

            var _options = {
                API: { URL: __appConfig.api },
                STOREREQUEST: { durability: 0, storeRequest: true },
                NEVER: { durability: 0, storeRequest: false },
                WEEK: { durability: 86400 * 7, storeRequest: false },
                TWODAYS: { durability: 86400 * 2, storeRequest: false },
                DAY: { durability: 86400, storeRequest: false },
                HOUR: { durability: 3600, storeRequest: false },
                TENMINUTES: { durability: 600, storeRequest: false },
                withDurability: function (durability) {
                    return {
                        durability: durability,
                        storeRequest: false
                    };
                }
            };

            var service = {
                get: _get,
                get2: _get2,
                post: _post,
                delete: _delete,
                put: _put,
                options: _options,
                resetPostCache: _resetPostCache,
                saveRequestStore: _cleanAndSaveRequestStore
            };

            service.getOptions = function () {
                return _options;
            };

            service.setOptions = function (options) {
                this._options = options;
            };

            service.flushFailedRequests = function () {
                if (failedRequests.length <= 0) {
                    return;
                }
                var copy = failedRequests.slice();
                failedRequests.length = 0;
                LocalStorageService.set("failedRequests", []);

                console.log(
                    "resending " + copy.length + " previously failed requests"
                );

                for (var i = 0; i < copy.length; i++) {
                    console.log("resending request");
                    var localCopy = copy[i];

                    _handleRequest(
                        localCopy.method,
                        localCopy.url,
                        localCopy.data,
                        function () { },
                        function () { }
                    );
                }
            };

            service.storeFailedRequest = function (method, url, data) {
                failedRequests.push({
                    method: method,
                    url: url,
                    data: data
                });

                LocalStorageService.set("failedRequests", failedRequests);
            };


            /**
             * @ngdoc function
             * @name _handleRequest
             * @methodOf TabletApiService
             * @description todo
             **/
            function _handleRequest(
                method,
                url,
                data,
                callback,
                errorCallback,
                options
            ) {
                var absoluteUrl;
                if (typeof url === 'object') {
                    absoluteUrl = url.base + url.url;
                } else {
                    absoluteUrl = _options.API.URL + url;
                }

                //options = {
                //    durability: 86400
                //    force: true
                //}

                return $q(function (accept, reject) {
                    _tryGetFromCache(method, url, data, function (cacheItem) {
                        if (cacheItem) {
                            BaseService.invoke(callback, cacheItem.item);
                            if (
                                cacheItem.expiresOn >= BaseService.getStamp() &&
                                (!options || options.force !== true)
                            ) {
                                return accept(cacheItem.item);
                            }
                        }

                        var request = {
                            method: method.toUpperCase(),
                            url: absoluteUrl,
                            headers: {
                                ApiKey: ConfigService.getAuthToken()
                            }
                        };

                        var user = ConfigService.getUser();
                        if (user) {
                            request.headers.ActivityUnitID = user.ID;
                        }

                        if (data !== null) {
                            if (method == "GET") {
                                request.params = data;
                            } else {
                                request.data = data;
                            }
                        }

                        function __executeRequest(rawRequest) {
                            var promise = $http(rawRequest);
                            _log(
                                "PENDING",
                                rawRequest.method,
                                rawRequest.url,
                                data,
                                options
                            );

                            return promise.then(
                                function (value) {
                                    service.flushFailedRequests();
                                    _log(
                                        "SUCCESS",
                                        rawRequest.method,
                                        rawRequest.url,
                                        data,
                                        options
                                    );
                                    if (!value.data) {
                                        BaseService.invoke(callback, null);
                                        return accept(null);
                                    }
                                    else {
                                        _tryStoreInCache(
                                            method,
                                            url,
                                            data,
                                            options,
                                            value.data.ApiObject || value.data,
                                            function (value) {
                                                BaseService.invoke(
                                                    callback,
                                                    value
                                                );
                                                return accept(value);
                                            }
                                        );
                                    }
                                },
                                function (reason) {
                                    _log(
                                        "FAILED",
                                        rawRequest.method,
                                        rawRequest.url +
                                        ' | reason: "' +
                                        reason.statusText +
                                        '" | status: ' +
                                        reason.status,
                                        undefined,
                                        options
                                    );
                                    if (
                                        reason.status === 403 &&
                                        $state.current.name.indexOf("auth") === -1
                                    ) {
                                        notificationService.error(
                                            "Sie sind in der Zeiterfassung nicht mehr angemeldet. Bitte beenden Sie aveato Kitchen, um sich mit einem zeiterfassten Benutzer anzumelden.",
                                            0
                                        );
                                    } else if (reason.data && reason.data.UserMessage) {
                                        notificationService.error(
                                            reason.data.UserMessage
                                        );
                                    } else if (
                                        reason.statusText &&
                                        reason.statusText.length > 0 &&
                                        !errorCallback
                                    ) {
                                        notificationService.error(reason.statusText);
                                    } else if (!reason.data && !reason.data.Messages) {
                                        if (rawRequest.method !== "GET") {
                                            console.log(
                                                "adding request to failed request list"
                                            );
                                            service.storeFailedRequest(
                                                method,
                                                url,
                                                data
                                            );
                                            console.log(
                                                "failed request count equals " +
                                                failedRequests.length
                                            );
                                            notificationService.error(
                                                "Sie sind Offline. Die Aktion wird übermittelt, sobald Sie wieder Online sind"
                                            );
                                        } else {
                                            notificationService.error(
                                                "Zur Zeit können keine Daten abgerufen werden."
                                            );
                                        }
                                    }

                                    if (errorCallback) {
                                        if (reason.data) {
                                            if (reason.data.UserMessage) {
                                                errorCallback(reason.data.UserMessage);
                                                return reject(reason.data.UserMessage);
                                            }

                                            if (reason.data.Messages) {
                                                errorCallback(reason.data.Messages);
                                                return reject(reason.data.Messages);
                                            }

                                        } else {
                                            errorCallback(reason.statusText);
                                            return reject(reason.statusText);
                                        }
                                    }
                                }
                            );
                        }

                        if (isDump) {
                            accept();
                        } else {
                            return __executeRequest(request);
                        }
                    });
                });
            }

            /**
             * @ngdoc function
             * @name _buildCacheKey
             * @methodOf TabletApiService
             * @description todo
             **/
            function _buildCacheKey(method, relativeUrl, params) {
                if (!params) {
                    return method + "_" + relativeUrl;
                }

                return (
                    method + "_" + relativeUrl + "_" + JSON.stringify(params)
                );
            }

            /**
             * @ngdoc function
             * @name _tryGetFromCache
             * @methodOf TabletApiService
             * @description todo
             **/
            function _tryGetFromCache(method, relativeUrl, params, callback) {
                var key = _buildCacheKey(method, relativeUrl, params);

                db.requests.get(key).then(function (value) {
                    if (!value) { return callback(null); }
                    callback(value);
                });
            }

            /**
             * @ngdoc function
             * @name _tryStoreInCache
             * @methodOf TabletApiService
             * @description todo
             **/
            function _tryStoreInCache(
                method,
                relativeUrl,
                params,
                options,
                value,
                callback
            ) {
                // do not store without options
                if (
                    options === undefined ||
                    options === null ||
                    options.durability === undefined ||
                    options.durability === null ||
                    options.durability === 0
                ) {
                    return callback(value);
                }

                // do not store without value
                if (value === undefined || value === null) {
                    return callback(null);
                }

                var key = _buildCacheKey(method, relativeUrl, params);

                var expiration = BaseService.getStamp() + options.durability;

                db.requests.put({
                    key: key,
                    expiresOn: expiration,
                    item: value
                }).then(function () {
                    callback(value);
                });
            }

            /**
             * @ngdoc function
             * @name _get2
             * @methodOf TabletApiService
             * @description todo
             **/
            function _get2(url, data, callback, errorCallback, options) {
                return _handleRequest(
                    "GET",
                    url,
                    data,
                    callback,
                    errorCallback,
                    options
                );
            }

            /**
             * @ngdoc function
             * @name _get
             * @methodOf TabletApiService
             * @description todo
             **/
            function _get(url, callback, errorCallback, options) {
                return _handleRequest(
                    "GET",
                    url,
                    null,
                    callback,
                    errorCallback,
                    options
                );
            }

            /**
             * @ngdoc function
             * @name _post
             * @methodOf TabletApiService
             * @description todo
             **/
            function _post(url, param, callback, errorCallback, options) {
                return _handleRequest(
                    "POST",
                    url,
                    param,
                    callback,
                    errorCallback,
                    options
                );
            }

            /**
             * @ngdoc function
             * @name resetPostCache
             * @methodOf TabletApiService
             * @description todo
             **/
            function _resetPostCache(url, param) {
                _resetCache("POST", url, param);
            }

            /**
             * @ngdoc function
             * @name _put
             * @methodOf TabletApiService
             * @description todo
             **/
            function _put(url, param, callback, errorCallback, options) {
                return _handleRequest(
                    "PUT",
                    url,
                    param,
                    callback,
                    errorCallback,
                    options
                );
            }

            /**
             * @ngdoc function
             * @name _delete
             * @methodOf TabletApiService
             * @description todo
             **/
            function _delete(url, callback, errorCallback, options) {
                return _handleRequest(
                    "DELETE",
                    url,
                    null,
                    callback,
                    errorCallback,
                    options
                );
            }

            function _resetCache(method, relativeUrl, params) {
                var key = _buildCacheKey(method, relativeUrl, params);
                console.log("resetted cache for " + key);
                return LocalStorageService.remove(key);
            }

            /**
             * @ngdoc function
             * @name _log
             * @methodOf TabletApiService
             * @description todo
             **/
            function _log(status, method, url, data, options) {
                return url;

                // var logstring = '';
                // if (data !== null && data !== undefined) {
                //     logstring = '[' + status + '] ' + method + ': ' + url + '(' + JSON.stringify(data) + ')';
                // } else {
                //     logstring = '[' + status + '] ' + method + ': ' + url;
                // }

                // if (options !== undefined && options.storeRequest === true) {
                //     requestStore.push({ stamp: BaseService.getStamp(), log: logstring });
                // }
                // console.log(logstring);
                // return url;
            }

            function _cleanAndSaveRequestStore() {
                if (isDump) {
                    return;
                }

                var minBorder = BaseService.getStamp() - 3600000 * 2; // two hours
                if (requestStore.length > 0) {
                    for (var i = requestStore.length - 1; i >= 0; i--) {
                        var entry = requestStore[i];
                        if (entry.stamp < minBorder) {
                            requestStore.splice(i, 1);
                        }
                    }
                }

                LocalStorageService.set("requestStore", requestStore);
            }

            $interval(function () {
                _cleanAndSaveRequestStore();
            }, 60000 * 5);
            service.flushFailedRequests();

            service.cleanRequestDb = function () {

                var nowStamp = BaseService.getStamp();

                if (!db.requests) { return; }

                db.requests.each(function (item) {
                    db.requests.get(item.key, function (keyValue) {
                        if (!keyValue.expiresOn || keyValue.expiresOn > nowStamp) {
                            return;
                        }                        
                        console.log('apiService removes ' + item.key + ' from request store in db');
                        db.requests.delete(item.key);
                    });
                });
            };

            return service;
        }
    ]);
})();
