(function() {
    "use strict";

    angular.module("laekkerAI.tablet").component("categoryList", {
        templateUrl: "/components/category-list/category-list.html",
        controller: "CategoryListController",
        bindings: {
            selectedCategory: '<',
            showPending: '<',
            onSelect: '&'
        }
    });
})();
