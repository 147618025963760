(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('hygenicIcon', {
            controller: 'HygenicIconController',
            templateUrl: '/components/hygenic-icon/hygenic-icon.html',
            bindings: {
                recipeKey: '<'
            }
        });
})();