(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('tabletFooter', {
            controller: 'TabletTabletFooterController',
            templateUrl:  '/components/tablet-footer/tablet-footer.html',
            bindings: {
            }
        });
}());