(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('tabletHeader', {
            controller: 'TabletTabletHeaderController',
            templateUrl:  '/components/tablet-header/tablet-header.html',
            bindings: {
                home:'<'
            }
        });
}());