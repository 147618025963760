(function () {
    'use strict';
    var app = angular.module('laekkerAI.tablet');

    app.controller(
        'GaugeController', [
        GaugeCtrl
    ]);


    function GaugeCtrl() {
        var $ctrl = this;

        $ctrl.options = {
            type: 'arch',
            cap: 'round',
            size: 175,
            value: 0,
            thick: 20,
            label: 'n/a',
            prepend: '',
            append: '',
            min: -100,
            max: 100,
            fractionSize: 1,
            foregroundColor: 'rgba(0, 150, 136, 1)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            thresholds: {
                '-99': {color: 'red'},
                '0': {color: 'yellow'},
                '99': {color: 'green'},
            }
        };


        $ctrl.$onInit = function () {
            $ctrl.thresholds = JSON.parse($ctrl.thresholds);
        };

    }
})();