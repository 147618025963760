(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('notifications', {
            controller: 'NotificationsController',
            templateUrl: '/components/notifications/notifications.html',
            bindings: {
            }
        });
})();