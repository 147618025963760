(function () {
    "use strict";

    /**
     * @ngdoc function
     * @name Tabletcontroller:AuthLocationController
     * @description
    **/
    var app = angular.module('laekkerAI.tablet');

    app.controller('TabletAuthLocationController', [
        '$state',
        'TabletAuthService',
        function ($state, AuthService) {
            var $ctrl = this;
            $ctrl.locations = [];

            $ctrl.$onInit = function () {
                AuthService.getLocations(function (err, locations) {
                    if(err) {
                        console.error(err);
                    }
                    $ctrl.locations = locations;
                });
            };

            /**
             * @ngdoc function
             * @name selectLocation
             * @methodOf Tabletcontroller:locationSelectionController
             * @description todo
            **/
            $ctrl.selectLocation = function (location) {
                $state.go('auth.user', { location: location });
            };
        }]);
}());