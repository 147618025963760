(function () {
    "use strict";

    angular
        .module('laekkerAI.tablet')
        .component('authLocation', {
            controller: 'TabletAuthLocationController',
            templateUrl:  '/components/auth-location/auth-location.html',
            bindings: {

            }
        });
}());