(function () {
    'use strict';
    var app = angular.module('laekkerAI.tablet');

    app.directive('badge', function () {
        return {
            restrict: 'E',
            template: '<span class="label" ng-class="{\'label-danger\': value < 0, \'label-success\': value > 0}" ng-hide="value == 0">{{value > 0 ? "+" : ""}}{{value | jobunit: information }}</span>',
            scope: {
                'information': '&information',
                'value': '@value'
            }
        };
    });
})();