(function($, angular) {
    "use strict";
    angular.module("laekkerAI.tablet").factory("UnauthorizedInterceptor", [
        "$q",
        "$injector",
        "$state",
        function($q, $injector, $state) {
            var service = this;

            service.responseError = function(response) {
                if (
                    response.status === 403 &&
                    $state.current.name.indexOf("auth") === -1
                ) {
                    $(".production-header").hide();
                    $("#unauthorized").show();
                }
                return $q.reject(response);
            };

            return service;
        }
    ]);
})(window.jQuery, window.angular);
