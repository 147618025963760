(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('productionJobHeader', {
            controller: 'KitchenProductionJobHeaderController',
            templateUrl: '/components/production-job-header/production-job-header.html',
            bindings: {
                job: '<',
                quantity: '<',
                toggleSelection: '&',
                isSelected: '<',
                isPreproduction: '<',
                allowTimeMeasurement: '<'
            }
        });
})();