(function () {
    'use strict';

    var app = angular.module('laekkerAI.tablet');

    /**
     * @ngdoc service
     * @name TabletHubService
     * @description
     * # HubService
     * @requires $rootScope
     * @requires $timeout
     * @requires Hub
     * @requires TabletConfigService
     * @requires TabletLogService
    **/
    app.factory('TabletHubService',
        [
            '$rootScope',
            '$timeout',
            'Hub',
            'TabletLogService',
            'TabletEventService',
            'TabletApiService', _HubService
        ]);

    function _HubService($rootScope,
        $timeout,
        Hub,
        LogService,
        EventService,
        apiService) {
        var kitchenProxy = null;

        var storedCalls = [];

        /**
         * @ngdoc function
         * @name _initHub
         * @methodOf TabletHubService
         * @description todo
        **/
        function _initHub(apiKey) {
            if (kitchenProxy !== null) {
                kitchenProxy._destroy();
                kitchenProxy = null;
            }

            if (apiKey === undefined || apiKey === null) {
                return;
            }

            kitchenProxy = new Hub('productionhub', {
                jsonp:true,
                listeners: {
                    'updateStation': function (data, dateStamp) {
                        EventService.broadcast(EventService.events.updateSpecificJobs, data, dateStamp);
                    },
                    'subscriptionRegistered': function (data) {
                        angular.forEach(storedCalls, function (storedCall) {
                            storedCall.call(apiKey);
                        });

                        storedCalls.length = 0;
                    },

                    'updateMeasurements': function (inventoryId, storageKey) {
                        $rootScope.$broadcast('updateMeasurements', inventoryId, storageKey);
                    },

                    'updateInventoryStatus': function (inventoryId, status) {
                        $rootScope.$broadcast('updateInventoryStatus', inventoryId, status);
                    }
                },

                methods: [
                    'unsubscribe',
                    'subscribe',
                    'processTasks',
                    'updateStation',
                    'subscribeInventory',
                    'unsubscribeInventory'
                ],

                rootPath: __appConfig.signalr,

                queryParams: {
                },

                errorHandler: function (error) {
                    LogService.error(error);
                },

                stateChanged: function (state) {
                    switch (state.newState) {
                        case $.signalR.connectionState.connecting:
                            EventService.broadcast(EventService.events.connectionStatusChanged, 'connecting');
                            break;
                        case $.signalR.connectionState.connected:
                        EventService.broadcast(EventService.events.connectionStatusChanged, 'connected');
                            kitchenProxy.subscribe(apiKey);
                            apiService.flushFailedRequests();
                            $timeout(function () {
                                $rootScope.$broadcast('forceUpdate');
                            }, 1000);

                            break;
                        case $.signalR.connectionState.reconnecting:
                        EventService.broadcast(EventService.events.connectionStatusChanged, 'connecting');
                            break;
                        case $.signalR.connectionState.disconnected:
                            EventService.broadcast(EventService.events.connectionStatusChanged, 'disconnected');
                            break;
                    }
                }
            });

            kitchenProxy._invoke = function (name, call) {
                call(apiKey);
            };

            kitchenProxy._destroy = function () {
                if (kitchenProxy.connection.state !== $.signalR.connectionState.connected) {
                    return;
                }

                kitchenProxy.disconnect();
            };
        }

        /**
         * @ngdoc function
         * @name _tryInvoke
         * @methodOf TabletHubService
         * @description todo
        **/
        function _tryInvoke(name, call) {
            if (kitchenProxy === null || kitchenProxy.connection.state !== $.signalR.connectionState.connected) {
                // make sure not to store it twice
                if (storedCalls.filter(function (itm) { return itm.name == name; }).length > 0) {
                    return false;
                }

                storedCalls.push({ 'name': name, 'call': call });
                return false;
            }
            kitchenProxy._invoke(name, call);
        }

        /**
         * @ngdoc function
         * @name _addProductionAction
         * @methodOf TabletHubService
         * @description todo
        **/
        function _addProductionAction(action) {
            if (kitchenProxy === null) {
                return;
            }
            // we need to bundle and store this queue
        }

        /**
         * @ngdoc function
         * @name _subscribeInventory
         * @methodOf TabletHubService
         * @description todo
        **/
        function _subscribeInventory(inventoryId) {
            _tryInvoke('subscribeInventory', function (apiKey) {
                kitchenProxy.subscribeInventory(inventoryId);
            });
        }

        /**
         * @ngdoc function
         * @name _unsubscribeInventory
         * @methodOf TabletHubService
         * @description todo
        **/
        function _unsubscribeInventory(inventoryId) {
            _tryInvoke('unsubscribeInventory', function (apiKey) {
                kitchenProxy.unsubscribeInventory(inventoryId);
            });
        }

        /* public API */
        return {
            initHub: _initHub,
            addProductionAction: _addProductionAction,
            subscribeInventory: _subscribeInventory,
            unsubscribeInventory: _unsubscribeInventory
        };
    }

})();