(function () {
    'use strict';
    
    angular
    .module('laekkerAI.tablet')
    .controller('ProductionPlanningSuppliersController', [
        ProductionPlanningSuppliersController
    ]);

    function ProductionPlanningSuppliersController() {
        var $ctrl = this;
    
        $ctrl.$onInit = function () {
        };
    
        $ctrl.$onChanges = function(changes) {
        };
    
        $ctrl.$onDestroy = function () {
        };
    
    }
}());