(function () {
    'use strict';

    var app = angular.module('laekkerAI.tablet');
    app.controller('StockItemController',
        [
            'CommodityService',
            'TabletNotificationService',
            'KitchenStockService',
            function (CommodityService, NotificationService, StockService) {
                var $ctrl = this;
                $ctrl.stock = { balance: 0, unit: 'Stck.', factor: 1 };
                $ctrl.requestedValue = 0;
                $ctrl.newBalane = 0;

                $ctrl.max = 100000000;
                $ctrl.min = 0;

                $ctrl.changeType = 3;
                $ctrl.changeTypes = [
                    { value: 2, text: 'Bruch' },
                    { value: 3, text: 'Schwund' },
                    { value: 11, text: 'Verderb' },
                    { value: 1, text: 'Einkauf' },
                    { value: 13, text: 'Ueberproduktion' }
                ];

                $ctrl.$onInit = function () {
                    StockService.getStock($ctrl.value.Key, function (err, data) {
                        
                        if (err) {
                            autoFix();
                            return err;
                        }
                        $ctrl.stock = data;
                        $ctrl.newBalance = data.balance;

                        autoFix();
                    });
                };

                function autoFix() {
                    $ctrl.min = 0;
                    $ctrl.max = 100000000;

                    if ($ctrl.stock.balance < 0) {
                        $ctrl.changeType = 1;
                        $ctrl.min = Math.abs($ctrl.stock.balance);
                        $ctrl.max = 100000000;
                    }

                    if ($ctrl.stock.balance >= 0) {
                        if ($ctrl.changeType === 1) {
                            $ctrl.max = 100000000;
                            $ctrl.min = 0;
                        } else {
                            $ctrl.max = $ctrl.stock.balance;
                            $ctrl.min = 0;
                        }
                    }

                    if(isNaN($ctrl.requestedValue) || $ctrl.requestedValue < $ctrl.min) {
                        $ctrl.requestedValue = $ctrl.min;
                    }

                    if($ctrl.requestedValue > $ctrl.max) {
                        $ctrl.requestedValue = $ctrl.max;
                    }

                    var factor = getFactorByChangeType();
                    $ctrl.newBalance = $ctrl.stock.balance + $ctrl.requestedValue * factor;
                }



                $ctrl.requestedValueChanged = function () {
                    autoFix();
                };

                function getFactorByChangeType() {
                    return $ctrl.changeType === 1 ? 1 : -1;
                }

                $ctrl.storeChanges = function () {
                    var realValue = getRealValue();
                    if (realValue === 0) {
                        return NotificationService.error('Bitte geben Sie einen Wert ungleich null an.', 4000);
                    }
                    StockService.updateStock($ctrl.value.Key, realValue, $ctrl.changeType, true, function (err) {
                        if (err) {
                            NotificationService.error(err, 4000);
                        } else {

                            $ctrl.stock.balance = $ctrl.newBalance;
                            $ctrl.requestedValue = 0;
                            NotificationService.success('Die Änderung wurde erfolgreich an das Lager übertragen.', 4000);
                            autoFix();
                            $ctrl.onCompleted();
                        }
                    });
                };

                function getRealValue() {
                    return $ctrl.requestedValue / $ctrl.stock.factor * getFactorByChangeType();
                }
            }
        ]);

})();