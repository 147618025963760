(function() {
    "use strict";
    angular.module("laekkerAI.tablet").component("productionJob", {
        controller: "KitchenProductionJobController",
        templateUrl: "/components/production-job/production-job.html",
        bindings: {
            job: "<",
            toggleSelection: "&",
            isSelected: "<",
            showProduced: "<",
            allowTimeMeasurement: "<"
        }
    });
})();
