(function () {
    'use strict';
    angular
        .module('laekkerAI.tablet')
        .component('stockQuestion', {
            controller: 'StockQuestionController',
            templateUrl: '/components/stock-question/stock-question.html',
            bindings: {
                'resolve': '<',
                'close': '&'
            }
        });
}());