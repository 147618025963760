(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('KitchenTimeController', [function () {

            var $ctrl = this;

            $ctrl.$onInit = function () {
                updateTime();
            };

            $ctrl.$onChanges = function (changes) {
                if (changes.seconds) {
                    updateTime();
                }
            };

            function updateTime() {

                var remaining = {
                    remainingTime: $ctrl.seconds,
                    count: 0
                };

                $ctrl.remaining = {};
                $ctrl.visible = 0;

                var remainingTime = $ctrl.seconds;
                var result = {};
                if(!$ctrl.hideDays) {
                    result = countAndReduce(remainingTime, 86400);
                    $ctrl.remaining.days = result.count;
                    if(result.count > 0) {
                        $ctrl.visible++;
                    }
                    remainingTime = result.remainingTime;
                }
                else {
                    $ctrl.remaining.days = 0;
                }
                result = countAndReduce(remainingTime, 3600);
                $ctrl.remaining.hours = withLeadingZero(result.count);
                if(result.count > 0) {
                    $ctrl.visible++;
                }
                remainingTime = result.remainingTime;

                if($ctrl.visible >= 2) {
                    return;
                }
                result = countAndReduce(remainingTime, 60);
                $ctrl.remaining.minutes = withLeadingZero(result.count);
                if(result.count > 0) {
                    $ctrl.visible++;
                }
                remainingTime = result.remainingTime;

                if($ctrl.visible >= 2) {
                    return;
                }
                result = countAndReduce(remainingTime, 1);
                $ctrl.remaining.seconds = withLeadingZero(result.count);
            }

            function withLeadingZero(count) {
                if(count < 10) {
                    return '0' + count;
                }

                return count;
            }

            function countAndReduce(remainingTime, interval) {

                var result = {
                    count: 0,
                    remainingTime: Math.max(remainingTime, 0)
                };

                if (result.remainingTime <= 0) {
                    return result;
                }

                while (result.remainingTime >= interval) {
                    result.count++;
                    result.remainingTime -= interval;
                }

                return result;
            }

        }]);
})();