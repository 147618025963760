(function() {
    "use strict";

    var app = angular.module("laekkerAI.tablet");

    /**
     * @ngdoc service
     * @name TabletBaseService
     * @description #todo
    **/
    app.factory("TabletBaseService", [
        "$timeout",
        "TabletLocalStorageService",
        function($timeout, LocalStorageService) {
            var _debugNow = LocalStorageService.get("DEBUG_now");
            if (_debugNow !== null) {
                _debugNow = new Date(_debugNow);
            }

            var cachedNames = {};

            /* public API */
            return {
                invoke: _invoke,
                compareKeys: _compareKeys,
                dateToString: _dateToString,
                getStamp: _getStamp,
                getTodayStamp: _getTodayStamp,
                isTooOld: _isTooOld,
                getHours: _getHours,
                getNow: _getNow,
                debugSetNow: _debugSetNow,
                localize: _localize,
                getToday: _getToday,
                formatName: formatName
            };

            function formatName(unformattedName) {
                if (!cachedNames[unformattedName]) {
                    cachedNames[unformattedName] = unformattedName
                        .split(",")
                        .map(function(itm, idx) {
                            return idx > 0
                                ? itm.trim().substr(0, 1) + "."
                                : itm.trim();
                        })
                        .reverse()
                        .join(" ");
                }

                return cachedNames[unformattedName];
            }

            /**
         * @ngdoc function
         * @name _localize
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _localize(multilingualItem) {
                return multilingualItem.Contents[0].Value;
            }

            /**
         * @ngdoc function
         * @name _debugSetNow
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _debugSetNow(now) {
                _debugNow = now;
                LocalStorageService.set("DEBUG_now", _debugNow);
            }

            /**
         * @ngdoc function
         * @name _getNow
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _getNow() {
                if (_debugNow !== null) {
                    return new Date(
                        _debugNow.getFullYear(),
                        _debugNow.getMonth(),
                        _debugNow.getDate(),
                        _debugNow.getHours(),
                        _debugNow.getMinutes(),
                        _debugNow.getSeconds()
                    );
                }

                return new Date();
            }

            function _getToday(offset) {
                var now = _getNow();

                return new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate() + (offset || 0)
                );
            }

            /**
         * @ngdoc function
         * @name _isTooOld
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _isTooOld(stamp) {
                if (stamp === undefined || stamp === null) return true;
                return stamp < _getTodayStamp();
            }

            /**
         * @ngdoc function
         * @name _getTodayStamp
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _getTodayStamp(date) {
                var now = _getNow();
                if (date !== undefined) now = new Date(date);
                return _getStamp(
                    new Date(now.getFullYear(), now.getMonth(), now.getDate())
                );
            }

            /**
         * @ngdoc function
         * @name _getHours
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _getHours(stamp) {
                if (stamp === undefined) {
                    return _getNow().getHours();
                } else {
                    return new Date(stamp * 1000).getHours();
                }
            }

            /**
         * @ngdoc function
         * @name _getStamp
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _getStamp(date) {
                if (date === undefined) date = _getNow();
                return Math.round(date.getTime() / 1000);
            }

            /**
         * @ngdoc function
         * @name _dateToString
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _dateToString(date) {
                if (!date) {
                    throw "we require a date to be of type date or a string";
                }
                var d = new Date(date);
                return (
                    d.getFullYear() +
                    "-" +
                    _withLeadingZero(d.getMonth() + 1) +
                    "-" +
                    _withLeadingZero(d.getDate())
                );
            }

            /**
         * @ngdoc function
         * @name _withLeadingZero
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _withLeadingZero(val) {
                if (val < 10) return "0" + val;
                return "" + val;
            }

            /**
         * @ngdoc function
         * @name _compareKeys
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _compareKeys(first, second) {
                if (
                    first === undefined ||
                    first === null ||
                    second === undefined ||
                    second === null
                )
                    return false;

                return first.Type == second.Type && first.ID == second.ID;
            }

            /**
         * @ngdoc function
         * @name _invoke
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _invoke(callback, arg1, arg2, arg3, arg4, arg5) {
                if (callback === undefined || callback === null) return;

                if (typeof callback !== "function")
                    throw 'argument is not a function: "' +
                        callback +
                        '". please provide a function as callback method.';

                callback(arg1, arg2, arg3, arg4, arg5);
            }

            /**
         * @ngdoc function
         * @name _asyncInvoke
         * @methodOf TabletBaseService
         * @description todo
        **/
            function _asyncInvoke(callback, arg, arg2, arg3, arg4, arg5) {
                $timeout(function() {
                    callback(arg, arg2, arg3, arg4, arg5);
                }, 0);
            }
        }
    ]);
})();
