(function () {
    'use strict';

    angular
        .module('laekkerAI.tablet')
        .controller('StockManagerController', [
            'KitchenStockService',
            'TabletNotificationService',
            function (stockService, notificationService) {
                var $ctrl = this;

                $ctrl.change = 0;
                $ctrl.newQuantity = 0;
                $ctrl.changeType = 3;
                $ctrl.changeTypes = [
                    { value: 2, text: 'Bruch' },
                    { value: 3, text: 'Schwund' },
                    { value: 1, text: 'Einkauf' }
                ];

                $ctrl.$onInit = function () {
                    if($ctrl.resolve !== undefined) {
                        angular.extend($ctrl, $ctrl.resolve);
                    }

                    $ctrl.changed();
                };

                $ctrl.changed = function () {
                    $ctrl.newQuantity = $ctrl.currentStock + getRealQuantity();
                };

                function getRealQuantity()
                {
                    var abs = Math.abs($ctrl.change);
                    $ctrl.change = abs;
                    if($ctrl.changeType !== 1) {
                        if(abs > $ctrl.currentStock) {
                            abs = $ctrl.currentStock;
                            $ctrl.change = abs;
                        }
                        abs *= -1;
                    }

                    return abs;
                }

                $ctrl.updateStock = function () {
                    stockService.updateStock($ctrl.key, getRealQuantity(), $ctrl.changeType, true,
                    function (err) {
                        if(err) {
                            return notificationService.error('Bei der Aktualisierung des Lagerbestandes ist ein Problem aufgetreten.');
                        }
                        
                        notificationService.success('Erfolg. Die Bestandskorrektur wird verarbeitet und sich in den nächsten Minuten auf den Lagerbestand auswirken');
                        $ctrl.stockChanged($ctrl.newQuantity);
                        $ctrl.close();
                    });
                };

                $ctrl.focusAll = function () {
                    jQuery('#stockManagerChangeInputField').select();
                };
            }]);
}());